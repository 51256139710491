<template>

  <div class="flex flex-column gap-2 flex-wrap">

    <div class="flex flex-row justify-content-between align-items-center">
      <h1 class="title">Collab Managers</h1>
    </div>

    <div class="col-12 flex flex-row align-items-center justify-content-between gap-2">
      <div class="flex flex-row align-items-center gap-2 w-full">

            <span class="p-input-icon-left w-full lg:w-20rem">
              <i class="pi pi-search"/>
              <InputText v-model="searchData.query" class="border-300 p-inputtext-sm"
                         placeholder="Search collab manager"/>
            </span>

        <Button v-tooltip.bottom="'More filters'"
                class="p-button-icon-only p-button-sm p-button-outlined p-button-plain hidden-desktop"
                icon="pi pi-filter" @click="visibleFilterSidebar = true"/>

      </div>

      <div class="flex flex-row gap-2 align-items-center hidden-tablet">

        <div class="flex flex-row gap-2 align-items-center">
          <p class="m-0 white-space-nowrap">Order by</p>
          <Dropdown v-model="searchData.order"
                    :options="collabManagersOrderByList"
                    class="w-10rem border-0 p-inputtext-sm"
                    option-label="label"
                    placeholder="Order by"/>
        </div>

        <span class="p-buttonset flex flex-row flex-nowrap">
              <Button :class="{'surface-200': selectedVisualization === 'grid'}"
                      class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm"
                      icon="pi pi-table"
                      @click="switchVisualization('grid')"/>
              <Button :class="{'surface-200': selectedVisualization === 'row'}"
                      class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm"
                      icon="pi pi-list"
                      @click="switchVisualization('row')"/>
            </span>
      </div>


    </div>

    <Sidebar v-model:visible="visibleFilterSidebar" position="right">
      <h2 class="m-0">Filters</h2>

      <h4 class="text-600 m-0 mt-3 mb-2">Visualization</h4>

      <span class="p-buttonset flex flex-row flex-nowrap">
          <Button :class="{'surface-200': selectedVisualization === 'grid'}"
                  class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm w-full"
                  icon="pi pi-table"
                  label="Card"
                  @click="switchVisualization('grid')"/>
          <Button :class="{'surface-200': selectedVisualization === 'row'}"
                  class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm w-full"
                  icon="pi pi-list"
                  label="List"
                  @click="switchVisualization('row')"/>
        </span>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Order by</h4>

      <Dropdown v-model="searchData.order"
                :options="collabManagersOrderByList"
                class="w-full p-inputtext-sm"
                option-label="label"
                placeholder="Order by"/>
    </Sidebar>

    <div ref="scrollComponent" class="flex flex-row flex-wrap align-items-stretch">
      <template v-if="selectedVisualization === 'grid'">
        <ManagerCard v-for="user of users" :key="user.id" :user="user"/>
      </template>

      <template v-else>
        <ManagerRowCard v-for="user of users" :key="user.id" :user="user"/>
      </template>
    </div>

    <Loader v-if="loading"/>

    <ErrorSection v-else-if="!loading && error"
                  icon="pi-times" text="Failed to retrieve collab managers"/>

    <EmptySection v-else-if="users.length === 0" icon="pi pi-inbox" text="No collab managers found"/>

  </div>

</template>

<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import store from "@/store";
import ManagerCard from "@/components/cards/ManagerCard.vue";
import ManagerRowCard from "@/components/cards/ManagerRowCard.vue";
import {collabManagersOrderByList} from "@/utils/dropdownItems";
import Loader from "@/components/sections/Loader.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import EmptySection from "@/components/sections/EmptySection.vue";

const visibleFilterSidebar = ref(false);
const searchData = ref({
  query: '',
  orderBy: {label: "Most Recent", type: "createdAt", order: "desc"}
})

const selectedVisualization = ref("grid");
const switchVisualization = (type) => selectedVisualization.value = type;

const page = ref(0)
const users = computed(() => store.state.user.users);
const loading = computed(() => store.state.user.loading);
const error = computed(() => store.state.user.error);
const totalPages = computed(() => store.state.user.totalPages)
const scrollComponent = ref(null)

watch(
    () => searchData,
    async (newSearchData) => {
      await store.dispatch('user/fetchUsers', {...newSearchData.value, page: 0, size: 20});
    },
    {deep: true, immediate: true}
)

onMounted(async () => window.addEventListener("scroll", handleScroll))

onUnmounted(() => window.removeEventListener("scroll", handleScroll))

const handleScroll = async () => {
  let element = scrollComponent.value
  if (element.getBoundingClientRect().bottom < window.innerHeight) {
    if (totalPages.value >= page.value + 1) {
      page.value++;
      await store.dispatch('user/fetchUsers', {...searchData, page: page.value});
    }
  }
}
</script>

<style scoped>

</style>