import HomePage from "@/pages/HomePage.vue";
import RestrictedArea from "@/pages/RestrictedArea.vue";
import Projects from "@/pages/Projects.vue";
import CreateProject from "@/pages/forms/CreateProject.vue";
import Dashboard from "@/pages/dashboard/Dashboard.vue";
import MyOffers from "@/pages/dashboard/pages/MyOffers.vue";
import MyProjects from "@/pages/dashboard/pages/MyProjects.vue";
import MyOutreachList from "@/pages/dashboard/pages/MyOutreachList.vue";
import MyOutreachListDetail from "@/pages/dashboard/pages/MyOutreachListDetail.vue";
import CreateOffer from "@/pages/forms/CreateOffer.vue";
import CreateOutreachList from "@/pages/forms/CreateOutreachList.vue";
import Offers from "@/pages/Offers.vue";
import CollabManagers from "@/pages/CollabManagers.vue";
import CollabManagerDetail from "@/pages/detail/CollabManagerDetail.vue";
import OfferDetail from "@/pages/detail/OfferDetail.vue";
import ProjectDetail from "@/pages/detail/ProjectDetail.vue";
import Profile from "@/pages/user/Profile.vue";
import Settings from "@/pages/user/Settings.vue";
import Notifications from "@/pages/user/Notifications.vue";
import TokenService from "@/services/token.service";
import EditOutreachList from "@/pages/forms/EditOutreachList.vue";
import EditOffer from "@/pages/forms/EditOffer.vue";
import MyWatchList from "@/pages/dashboard/pages/MyWatchList.vue";
import MyCollaborations from "@/pages/dashboard/pages/MyCollaborations.vue";
import MyProject from "@/pages/dashboard/pages/MyProject/MyProject.vue";
import MyProjectInformation from "@/pages/dashboard/pages/MyProject/pages/MyProjectInformation.vue";
import MyProjectMembers from "@/pages/dashboard/pages/MyProject/pages/MyProjectMembers.vue";
import MyProjectWinners from "@/pages/dashboard/pages/MyProject/pages/MyProjectWinners.vue";
import MyProjectTemplates from "@/pages/dashboard/pages/MyProject/pages/MyProjectTemplates.vue";
import MyProjectLinks from "@/pages/dashboard/pages/MyProject/pages/MyProjectLinks.vue";
import MyProjectSettings from "@/pages/dashboard/pages/MyProject/pages/MyProjectSettings.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy.vue";

const {createRouter, createWebHistory} = require("vue-router");
const routes = [
    {path: '/:pathMatch(.*)', redirect: "/"},
    {path: '/privacy-policy', component: PrivacyPolicy},
    {
        path: '/', redirect: '/home'
    }, {
        path: '/home', name: 'home', component: HomePage
    }, {
        path: '/restricted', name: 'restricted', component: RestrictedArea
    }, {
        path: '/projects', name: 'projects', component: Projects, meta: {requiresAuth: false}
    }, {
        path: '/projects/:id',
        name: 'projects-detail',
        component: ProjectDetail,
        props: true,
        meta: {requiresAuth: false}
    }, {
        path: '/listings', name: 'listings', component: Offers, meta: {requiresAuth: false}
    }, {
        path: '/:offerType/:projectName/:id',
        name: 'offer-detail',
        component: OfferDetail,
        props: true,
        meta: {requiresAuth: false}
    }, {
        path: '/collab-managers', name: 'collab-managers', component: CollabManagers, meta: {requiresAuth: false}
    }, {
        path: '/collab-managers/:id',
        name: 'collab-manager-detail',
        component: CollabManagerDetail,
        props: true,
        meta: {requiresAuth: false}
    }, {
        path: '/new-project',
        name: 'new-project',
        component: CreateProject,
        props: route => Object.assign({}, route.query),
        meta: {requiresAuth: true},
    },
    {
        path: '/new-offer', name: 'new-offer', component: CreateOffer, meta: {requiresAuth: true}
    }, {
        path: '/edit-offer/:id', name: 'edit-offer', component: EditOffer, props: true, meta: {requiresAuth: true}
    }, {
        path: '/new-outreach-list', name: 'new-outreach-list', component: CreateOutreachList, meta: {requiresAuth: true}
    }, {
        path: '/edit-outreach-list/:id',
        name: 'edit-outreach-list',
        component: EditOutreachList,
        props: true,
        meta: {requiresAuth: true}
    }, {
        path: '/profile', name: 'profile', component: Profile, meta: {requiresAuth: true}
    }, {
        path: '/settings',
        name: 'settings',
        component: Settings,
        props: route => Object.assign({}, route.query),
        meta: {requiresAuth: true}
    }, {
        path: '/notifications', name: 'notifications', component: Notifications, meta: {requiresAuth: true}
    }, {
        path: '/dashboard/projects/:id',
        name: 'dashboard-project',
        component: MyProject,
        props: true,
        meta: {requiresAuth: true},
        children: [{
            path: 'information',
            name: 'dashboard-project-information',
            component: MyProjectInformation,
            props: true,
            meta: {requiresAuth: true},
        }, {
            path: 'members', name: 'dashboard-project-members', component: MyProjectMembers, props: true,
            meta: {requiresAuth: true},
        }, {
            path: 'winners',
            name: 'dashboard-project-winners',
            component: MyProjectWinners,
            props: true,
            meta: {requiresAuth: true},
        }, {
            path: 'templates',
            name: 'dashboard-project-templates',
            component: MyProjectTemplates,
            props: true,
            meta: {requiresAuth: true},
        }, {path: 'links', name: 'dashboard-project-links', component: MyProjectLinks, props: true}, {
            path: 'settings',
            name: 'dashboard-project-settings',
            component: MyProjectSettings,
            props: true,
            meta: {requiresAuth: true},
        },]
    }, {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {requiresAuth: true},
        children: [{path: '', redirect: '/dashboard/collaborations'}, {
            path: 'collaborations',
            name: 'dashboard-collaborations',
            component: MyCollaborations,
            props: route => Object.assign({}, route.query),
            meta: {requiresAuth: true},
        }, {
            path: 'projects',
            name: 'dashboard-projects',
            component: MyProjects,
            props: route => Object.assign({}, route.query),
            meta: {requiresAuth: true},
        }, {path: 'listings', name: 'dashboard-listings', component: MyOffers}, {
            path: 'outreach-list', name: 'dashboard-outreach-list', component: MyOutreachList
        }, {path: 'watch-list', name: 'dashboard-watch-list', component: MyWatchList}]
    }, {
        path: '/outreach-list/:id',
        name: 'outreach-list-detail',
        component: MyOutreachListDetail,
        props: true,
        meta: {requiresAuth: true}
    }];

const router = createRouter({
    history: createWebHistory(), routes, scrollBehavior() {
        document.getElementById('app').scrollIntoView({behavior: 'smooth'});
    }
});

router.beforeEach((to, from, next) => {
    const authRequired = to.meta.requiresAuth;
    const loggedIn = TokenService.getUser();

    if (authRequired && !loggedIn) {
        next('/restricted');
    } else {
        next();
    }
});

export default router;