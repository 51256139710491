<template>

  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | IND3X` : `IND3X` }}</template>
  </metainfo>

  <Toast position="top-left"/>

  <ConfirmDialog group="deleteConfirm">
    <template #message="slotProps">
      <div class="flex flex-row align-items-center gap-3" style="max-width: 500px;">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem" class="text-orange-500"/>
        <p class="m-0" v-html="slotProps.message.message"></p>
      </div>
    </template>
  </ConfirmDialog>

  <ConfirmDialog group="sendMessageConfirm">
    <template #message="slotProps">
      <div class="flex flex-column align-items-center gap-3" style="max-width: 500px;">
        <i :class="slotProps.message.icon" class="text-primary text-6xl"/>
        <p class="m-0 text-600" v-html="slotProps.message.message"></p>
      </div>
    </template>
  </ConfirmDialog>

  <NavBar/>

  <div class="flex flex-column align-items-center w-full" style="max-width: 100vw; min-height: 80vh;">
    <div class="w-full py-4 container px-1 md:px-4">
      <router-view/>
    </div>
  </div>

  <Footer/>

</template>

<script setup>
import EventBus from "@/common/EventBus";
import NavBar from "@/components/layout/NavBar.vue";
import Footer from "@/components/layout/Footer.vue";
import {onBeforeUnmount, onMounted, ref} from "vue";
import store from "@/store";
import {useMeta} from "vue-meta";

const intervalNotifications = ref(null)

useMeta({
  title: '',
  htmlAttrs: { lang: 'en', amp: true }
})

onMounted(async () => {
  await store.dispatch("auth/refreshConnection");
  await store.dispatch("notification/fetchNotifications", {});

  intervalNotifications.value = setInterval(() => {
    if (store.state.auth.status.loggedIn) store.dispatch("notification/fetchNotifications", {});
  }, 10000);
})

onBeforeUnmount(() => EventBus.remove("logout"))

</script>

<style>
</style>
