<template>

  <BackButton/>

  <Loader v-if="loading"/>


  <div v-if="!loading && offer" class="flex flex-row md:gap-4 p-2">

    <div class="flex flex-column surface-100 border-round-xl hidden-tablet" style="min-width: 300px;">

      <div v-if="offer.type === 'offering'"
           class="flex flex-row align-items-center justify-content-center gap-2 bg-cyan-400 border-round-top-xl p-1">
        <span class="text-sm text-cyan-50">Offering whitelist</span>
      </div>
      <div v-else
           class="flex flex-row align-items-center justify-content-center gap-2 bg-purple-400 border-round-top-xl p-1">
        <span class="text-sm text-purple-50">Requesting whitelist</span>
      </div>

      <ProjectDesktopSidebarContent :project="offer.project"/>

      <div class="flex flex-column gap-2 p-4">

        <RespondToOfferModal :offer-id="offer.id" :offer-type="offer.type" class="mx-3 mt-4" :project-id="offer.project.id"/>

        <small class="text-400 text-center">
          Expire in {{ $moment(offer.expirationDate).diff($moment(), 'days') }} days
        </small>

        <div class="flex flex-row align-items-center justify-content-center gap-1 text-600 mt-4">
          <small class="m-0">Posted by</small>
          <router-link :to="`/collab-managers/${offer.user.id}`">
            <small class="m-0 capitalize hover:text-primary">{{ offer.user.username }}</small>
          </router-link>
        </div>
      </div>
    </div>

    <div class="flex flex-column flex-grow-1">

      <div class="relative w-full">
        <div :style="`background: url(${offer.project.banner}) no-repeat center;`"
             class="banner-background absolute left-0 top-0 w-full h-full border-round-xl"></div>

        <div class="relative flex flex-column align-items-center gap-2 px-2 py-6">
          <img :src="offer.project.logo" alt="Project logo" class="border-round h-8rem w-auto"/>
          <div class="flex flex-row align-items-center gap-1">
            <p class="m-0 text-4xl font-semibold text-center text-200 cropped" style="max-width: 64vw;">
              {{ offer.project.name }}
            </p>
            <VerifiedIcon :verified="offer.project.verified"/>
          </div>
        </div>
      </div>

      <div v-if="offer.type === 'offering' && offer.giveawayRequirements.length > 0" class="flex flex-column gap-1 border-round-xl offering-blur-bg mt-3 p-3">
        <div class="flex flex-row gap-2 align-items-center text-lg text-cyan-700 font-semibold">
          <i class="pi pi-info-circle"/>
          <p class="m-0">Giveaway requirements</p>
        </div>
        <div v-for="requirement of offer.giveawayRequirements"
             :key="`requirement${requirement.id}`"
             class="flex flex-row flex-wrap gap-2 align-items-center">
          <p class="m-0 font-semibold text-sm capitalize text-cyan-50">
            {{ requirement.type.replace('-', '/').replace('_', ' ') }}</p>
          <a :href="requirement.link" target="_blank" class="text-sm text-cyan-100 cropped" style="max-width: 64vw;">
            {{ requirement.link }}
          </a>
        </div>
      </div>

      <div v-if="offer.type === 'requesting'"
           class="flex flex-column gap-1 border-round-xl requesting-blur-bg mt-3 p-3">
        <div class="flex flex-row gap-2 align-items-center text-lg text-purple-700 font-semibold">
          <i class="pi pi-info-circle"/>
          <p class="m-0">Accepted giveaway requirements</p>
        </div>
        <div class="flex flex-row justify-content-center">
          <div v-for="(requirement, rIdx) of offer.acceptableGiveawayRequirements"
               :key="`requirement${requirement.id}`"
               class="flex flex-row flex-wrap ml-2 gap-2 align-items-center">
            <p class="m-0 font-semibold text-sm capitalize text-purple-50">
              {{ requirement.replace('-', '/').replace('_', ' ') }}
            </p>
            <p class="m-0 text-purple-50"> {{ rIdx === offer.acceptableGiveawayRequirements.length - 1 ? "" : "-" }}</p>
          </div>
        </div>
      </div>

      <ProjectMobileContent :offer="offer" :project="offer.project"/>

      <h2 class="m-0 mt-5 mb-2">Information</h2>
      <p class="m-0" v-html="offer.project.description"></p>

      <div class="flex flex-row justify-content-center">
        <Button class="p-button-text p-button-sm my-4 p-button-icon"
                icon="pi pi-arrow-up-right"
                icon-pos="right"
                label="View all detail about project"
                @click="router.push(`/projects/${offer.project.id}`)"/>
      </div>

    </div>

  </div>

  <div v-if="!loading && !offer" class="flex flex-column h-30rem justify-content-center align-items-center gap-2">
    <p class="m-0 text-600">Listing not exist</p>
    <Button class="p-button-text p-button-sm" label="View all listings"
            @click="router.push('/listings')"/>
  </div>

  <ErrorSection v-if="!loading && error" icon="pi pi-times" text="Failed to fetch this offer"/>

</template>

<script setup>
import BackButton from "@/components/buttons/BackButton.vue";
import {computed, defineProps, watch} from "vue";
import store from "@/store";
import router from "@/router";
import RespondToOfferModal from "@/components/modals/RespondToOfferModal.vue";
import ProjectDesktopSidebarContent from "@/components/ProjectDesktopSidebarContent.vue";
import ProjectMobileContent from "@/components/ProjectMobileContent.vue";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import Loader from "@/components/sections/Loader.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import {useMeta} from "vue-meta";

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

watch(
    () => props.id,
    async (newId) => {
      if (newId) {
        await store.dispatch("offer/fetchOffer", newId);
        const description = `@${offer.value.project.twitter} | ${offer.value.type} spots | ${offer.value.project.type} - ${offer.value.project.minted ? 'Already' : 'No'} minted - ${offer.value.project.blockchain}  - Avg entries ${offer.value.project.avgEntries}`;
        useMeta({
          description: description,
          meta: [
            {
              property: 'og:title',
              name: 'twitter:title',
              content: offer.value.project.name
            },
            {
              property: 'og:description',
              name: 'twitter:description',
              content: description,
            },
            {
              property: 'og:image',
              name: 'twitter:image',
              content: `https://ind3x.app/api/projects/thumb/${offer.value.project.id}.png`
            },
          ]
        });
      }
    },
    {immediate: true}
)

const offer = computed(() => store.state.offer.offer);
const loading = computed(() => store.state.offer.loading);
const error = computed(() => store.state.offer.error);
</script>

<style scoped>
</style>