<template>
  <BackButton/>

  <Loader v-if="loading"/>

  <ErrorSection v-else-if="!loading && error" icon="pi pi-times" text="Failed to fetch this project"/>

  <div v-else-if="!loading && Object.keys(project).length > 0" class="flex flex-row md:gap-4 p-2">

    <div class="flex flex-column surface-100 border-round-xl hidden-tablet"
         style="min-width: 300px;">
      <ProjectDesktopSidebarContent :project="project"/>
    </div>

    <div class="flex flex-column flex-grow-1">

      <div class="relative w-full">
        <div :style="`background: url(${project.banner}) no-repeat center;`"
             class="banner-background absolute left-0 top-0 w-full h-full border-round-xl"></div>

        <div class="relative flex flex-column align-items-center gap-2 px-2 py-6">
          <img :src="project.logo" alt="Project logo" class="border-round h-8rem w-auto"/>
          <div class="flex flex-row align-items-center gap-1">
            <p class="m-0 text-4xl font-semibold text-center text-200 cropped" style="max-width: 64vw;">{{
                project.name
              }}</p>
            <VerifiedIcon :verified="project.verified"/>
          </div>
        </div>
      </div>

      <ProjectMobileContent :project="project"/>

      <h2 class="m-0 mt-5 mb-2">Information</h2>
      <p class="m-0 text-sm" v-html="project.description"></p>

      <h2 class="m-0 mt-5 mb-2">Listings</h2>

      <div class="grid w-full">
        <OfferCard v-for="offer of project.offers" :key="offer.id" :offer="offer"/>
        <EmptySection v-if="!project.offers || project.offers.length === 0" icon="pi-inbox" text="No online offers"/>
      </div>

      <h2 class="m-0 mt-5 mb-2">Members</h2>

      <div class="grid w-full">
        <ManagerCard v-for="member of project.members" :key="member.id"
                     :user="{role: member['projectMembers'].role,...member}"/>
      </div>

    </div>

  </div>

  <div v-else class="flex flex-column h-30rem justify-content-center align-items-center gap-2">
    <p class="m-0 text-600">Project not exist</p>
    <Button class="p-button-text p-button-sm" label="View all projects"
            @click="router.push('/projects')"/>
  </div>


</template>

<script setup>
import BackButton from "@/components/buttons/BackButton.vue";
import OfferCard from "@/components/cards/OfferCard.vue";
import ManagerCard from "@/components/cards/ManagerCard.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import {computed, defineProps, watch} from "vue";
import store from "@/store";
import ProjectDesktopSidebarContent from "@/components/ProjectDesktopSidebarContent.vue";
import ProjectMobileContent from "@/components/ProjectMobileContent.vue";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import router from "@/router";
import Loader from "@/components/sections/Loader.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import {useMeta} from "vue-meta";

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const project = computed(() => store.state.project.project);
const loading = computed(() => store.state.project.loading);
const error = computed(() => store.state.project.error);

watch(
    () => props.id,
    async (newId) => {
      if (newId) {
        await store.dispatch("project/fetchProject", newId);
        if (project.value) {
          const description = `@${project.value.twitter} | ${project.value.type} - ${project.value.minted ? 'Already' : 'No'} minted - ${project.value.blockchain}  - Avg entries ${project.value.avgEntries}`;
          useMeta({
            description: description,
            meta: [
              {
                property: 'og:title',
                name: 'twitter:title',
                content: project.value.name
              },
              {
                property: 'og:description',
                name: 'twitter:description',
                content: description,
              },
              {
                property: 'og:image',
                name: 'twitter:image',
                content: `https://v2.ind3x.app/api/projects/thumb/${project.value.id}.png`
              },
            ]
          });
        }
      }
    },
    {immediate: true}
)
</script>

<style scoped>
</style>