<template>
  <Button class="p-button-sm" icon="pi pi-plus"
          label="New collab"
          @click="visibleManualCollabModal = true"/>

  <Dialog :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :closable="false"
          :draggable="false"
          :header="`New ${collabType} collab`"
          :style="{ width: '500px', maxWidth: '98vw'}"
          :visible="visibleManualCollabModal" modal>

    <div class="grid">

      <div class="col-12">
        <label for="projectXHandle">Project X link</label>
        <InputText id="projectXHandle"
                   v-model="manualCollabForm.projectXHandle"
                   :class="{'p-invalid': submitted && manualCollabForm.projectXHandle.match(regex) === null}"
                   aria-describedby="discord-help"
                   class="p-inputtext-sm"
                   placeholder="https://twitter.com/ind3xapp"/>
        <small v-if="submitted && manualCollabForm.projectXHandle.match(regex) === null" class="p-error">
          Project X link is required.
        </small>
      </div>

      <div class="col-12">
        <label for="contactXHandle">Contact X link</label>
        <InputText id="contactXHandle"
                   v-model="manualCollabForm.contactXHandle"
                   :class="{'p-invalid': submitted && manualCollabForm.contactXHandle.match(regex) === null}"
                   aria-describedby="discord-help"
                   class="p-inputtext-sm"
                   placeholder="https://twitter.com/11Shozen"/>
        <small v-if="submitted && manualCollabForm.contactXHandle.match(regex) === null" class="p-error">
          Contact X link is required.
        </small>
      </div>

      <div class="col-12">
        <label for="contactXHandle">Discord username</label>
        <InputText id="contactXHandle"
                   v-model="manualCollabForm.contactDiscordUsername"
                   aria-describedby="discord-help"
                   class="p-inputtext-sm"
                   placeholder="Shozen"/>
      </div>

      <div class="col-12 flex flex-column">
        <label for="deadline">Deadline</label>
        <Calendar v-model="manualCollabForm.deadline"
                  class="p-inputtext-sm"
                  for="deadline"/>
      </div>

      <div class="col-12 flex flex-column">
        <label for="spots">Collab manager</label>
        <Dropdown v-model="manualCollabForm.collabManager"
                  :class="{'p-invalid': submitted && manualCollabForm.collabManager === null}"
                  :options="projectMembers"
                  class="p-inputtext-sm"
                  placeholder="Select team member">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center gap-2">
              <Avatar
                  :image="`https://cdn.discordapp.com/avatars/${slotProps.value.user.discordId}/${slotProps.value.user.discordAvatar}`"
                  shape="circle"/>
              <span class="capitalize">{{ slotProps.value.user.username }}</span>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center gap-2">
              <Avatar
                  :image="`https://cdn.discordapp.com/avatars/${slotProps.option.user.discordId}/${slotProps.option.user.discordAvatar}`"
                  class="w-2rem h-2rem" shape="circle"/>
              <span class="capitalize">{{ slotProps.option.user.username }}</span>
            </div>
          </template>
        </Dropdown>

        <small v-if="submitted && manualCollabForm.collabManager === null" class="p-error">
          Collab manager is required.
        </small>
      </div>

      <div class="col-12 flex flex-column">
        <label for="spots"> {{ collabType === 'inbound' ? 'Allocated' : 'Allowed' }} spots </label>
        <InputNumber id="spots"
                     v-model="manualCollabForm.spots"
                     class="p-inputtext-sm"
                     placeholder="0"/>
      </div>
    </div>

    <div class="flex flex-row justify-content-end gap-2 flex-wrap mt-2">
      <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="cancelCreation"/>
      <Button class="p-button-sm" label="Create collab" @click="createManualInboundCollab"/>
    </div>
  </Dialog>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";
import OfferResponseService from "@/services/offerResponse.service";
import {useToast} from "primevue/usetoast";
import ProjectService from "@/services/project.service";
import store from "@/store";

const toast = useToast();
const props = defineProps({
  projectId: {type: String, required: true},
  collabType: {type: String, required: true},
})
const manualCollabForm = ref({
  projectXHandle: '',
  contactXHandle: '',
  contactDiscordUsername: '',
  spots: null,
  collabManager: null,
  deadline: null,
})
const regex = ref(/(?:https?:)?\/\/(?:www\.|m\.)?(twitter\.com|x\.com)\/(\w{2,15})\/?(?:\?\S+)?(?:#\S+)?$/);
const projectMembers = ref(null);
const visibleManualCollabModal = ref(false);
const submitted = ref(false);

watch(
    () => props.projectId,
    (newProjectId) => {
      if (newProjectId) {
        ProjectService.getProjectMembers(newProjectId)
            .then(
                response => projectMembers.value = response,
                error => {
                  toast.add({severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000});
                }
            )
      }
    },
    {immediate: true}
)

const cancelCreation = () => {
  visibleManualCollabModal.value = false;
  submitted.value = false;
  manualCollabForm.value = {
    projectXHandle: '',
    contactXHandle: '',
    contactDiscordUsername: '',
    spots: null,
    collabManager: null,
    deadline: null,
  }
}

const createManualInboundCollab = async () => {
  submitted.value = true;

  if (manualCollabForm.value.projectXHandle.match(regex.value) === null ||
      manualCollabForm.value.contactXHandle.match(regex.value) === null ||
      manualCollabForm.value.collabManager === null) return;

  const projectXHandle = manualCollabForm.value.projectXHandle.match(regex.value)[2];
  const contactXHandle = manualCollabForm.value.contactXHandle.match(regex.value)[2];

  const {spots, collabManager} = manualCollabForm.value;

  await OfferResponseService.createManualResponse({
    projectXHandle: projectXHandle,
    contactXHandle: contactXHandle,
    contactDiscordUsername: manualCollabForm.value.contactDiscordUsername,
    spots: spots,
    collabManagerId: collabManager.user.id,
    deadline: manualCollabForm.value.deadline,
    projectId: props.projectId,
    type: props.collabType,
  })
      .then(async response => {
        await toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        await store.dispatch('offerResponse/fetchOfferResponses', props.projectId)
        visibleManualCollabModal.value = false;
      })
      .catch(error => {
        toast.add({severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000});
      })

  submitted.value = false;
  manualCollabForm.value = {
    projectXHandle: '',
    contactXHandle: '',
    contactDiscordUsername: '',
    spots: null,
    collabManager: null,
    deadline: null,
  }
}
</script>

<style scoped>

</style>