<template>
  <div class="col-12 flex gap-2 align-items-center justify-content-start md:justify-content-end mt-1 flex-wrap">
    <div class="flex flex-row gap-2 flex-nowrap">
      <Checkbox v-model="groupByStatus" :binary="true" @change="handleGroupByStatusChange"/>
      <label class="white-space-nowrap" for="order-select">Group by status | </label>
    </div>
    <div class="flex flex-row gap-2 align-items-center flex-nowrap">
      <label class="white-space-nowrap" for="order-select">Order by</label>
      <Dropdown id="order-select" v-model="selectedCollabOrder" :options="collabOrderList" class="p-inputtext-sm w-full"
                placeholder="Select order" @change="handleFilterCollabs">
        <template #value="slotProps">
          <div v-if="slotProps.value" class="flex align-items-center gap-2">
            <i :class="slotProps.value.icon"/>
            <span class="capitalize">{{ slotProps.value.name }}</span>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center gap-2">
            <i :class="slotProps.option.icon"/>
            <span class="capitalize">{{ slotProps.option.name }}</span>
          </div>
        </template>
      </Dropdown>
    </div>

    <AddManualCollab v-if="props.collabType === 'inbound' || props.collabType === 'outbound'"
                     :collab-type="props.collabType" :project-id="props.projectId"/>
  </div>

  <DataTable v-model:expandedRows="expandedRows" :loading="loading" :value="collaborations" class="p-datatable-sm mt-3"
             filterDisplay="row" tableStyle="min-width: 100%">
    <template #empty>
      <p class="text-center text-500"> No {{ collabType.replace('_', ' ') }} collabs </p>
    </template>
    <Column expander/>
    <Column>
      <template #body="{ data }">
        <i v-if="data.manual" v-tooltip.bottom="'Manual collab'" class="pi pi-info-circle text-sm"/>
      </template>
    </Column>
    <Column :showFilterMenu="false" field="status" header="Status">
      <template #body="{ data }">
        <template v-if="data.status === 'created'">
          <Tag icon="pi pi-clock" severity="info" style="margin-left: 0.65625rem;" value="Waiting"/>
        </template>
        <template v-else-if="data.status === 'declined'">
          <Tag icon="pi pi-times" severity="danger" style="margin-left: 0.65625rem;" value="Declined"/>
        </template>
        <template v-else-if="collabType === 'inbound' || data.manual">
          <Dropdown :model-value="responseStatusList.find(rs => rs.value === data.status)" :options="responseStatusList"
                    class="border-300 p-inputtext-sm w-12rem" @change="handleOfferResponseStatusChange($event, data)">
            <template #value="slotProps">
              <Tag :class="`bg-${slotProps.value.color}`" :icon="slotProps.value.icon" :value="slotProps.value.label"
                   class="capitalize"/>
            </template>
            <template #option="slotProps">
              <Tag :class="`bg-${slotProps.option.color}`" :icon="slotProps.option.icon" :value="slotProps.option.label"
                   class="capitalize"/>
            </template>
          </Dropdown>
        </template>
        <template v-else>
          <Tag :class="`bg-${responseStatusList.find(rs => rs.value === data.status).color}`"
               :icon="responseStatusList.find(rs => rs.value === data.status).icon"
               :value="responseStatusList.find(rs => rs.value === data.status).label"
               class="capitalize white-space-nowrap"
               style="margin-left: 0.65625rem;"/>
        </template>
      </template>
      <template #filter="{ }">
        <MultiSelect v-model="statusMultiSelect" :options="responseStatusList" class="border-300 p-inputtext-sm w-12rem"
                     option-label="label" option-value="value" placeholder="Status"
                     @change="loadCollaborations(offerResponses)">
          <template #option="slotProps">
            <Tag :class="`bg-${slotProps.option.color}`" :icon="slotProps.option.icon" :value="slotProps.option.label"
                 class="capitalize"/>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column :showFilterMenu="false" field="project" header="Project">
      <template #body="{ data }">
        <div v-if="data.manual" class="flex flex-row align-items-center gap-2">
          <Avatar :image="data.projectXPicture" shape="circle"/>
          <Button :label="`@${data.projectXHandle}`" class="p-button-sm p-button-link m-0 p-0"
                  @click="openExternalLink(`https://x.com/${data.projectXHandle}`)"/>
        </div>
        <div v-else-if="collabType === 'outbound' && data.offer.type === 'requesting'"
             class="flex flex-row gap-1 align-items-center">
          <Avatar :image="data.offer.project.logo" shape="circle"/>
          <Button :label="data.offer.project.name"
                  class="p-button-sm p-button-link capitalize m-0 p-0 white-space-nowrap"
                  style="max-width: 160px;" @click="router.push(`/projects/${data.offer.project.id}`)"/>
          <VerifiedIcon :verified="data.offer.project.verified"/>
        </div>
        <div v-else-if="collabType === 'outbound' && data.offer.type === 'offering'"
             class="flex flex-row gap-1 align-items-center">
          <Avatar :image="data.project.logo" shape="circle"/>
          <Button :label="data.project.name" class="p-button-sm p-button-link capitalize m-0 p-0 white-space-nowrap"
                  style="max-width: 160px;" @click="router.push(`/projects/${data.project.id}`)"/>
          <VerifiedIcon :verified="data.project.verified"/>
        </div>
        <div v-else-if="collabType === 'inbound' && data.offer.type === 'offering'"
             class="flex flex-row gap-1 align-items-center">
          <Avatar :image="data.offer.project.logo" shape="circle"/>
          <Button :label="data.offer.project.name"
                  class="p-button-sm p-button-link capitalize m-0 p-0 white-space-nowrap"
                  style="max-width: 160px;" @click="router.push(`/projects/${data.offer.project.id}`)"/>
          <VerifiedIcon :verified="data.offer.project.verified"/>
        </div>
        <div v-else-if="collabType === 'inbound' && data.offer.type === 'requesting'"
             class="flex flex-row gap-1 align-items-center">
          <Avatar :image="data.project.logo" shape="circle"/>
          <Button :label="data.project.name" class="p-button-sm p-button-link capitalize m-0 p-0 white-space-nowrap"
                  style="max-width: 160px;" @click="router.push(`/projects/${data.project.id}`)"/>
          <VerifiedIcon :verified="data.project.verified"/>
        </div>
      </template>
      <template #filter="{ }">
        <InputText v-model="projectFilterInput" class="p-inputtext-sm w-full" placeholder="Search"
                   style="min-width: 6rem;" type="text" @input="handleFilterProjectName"/>
      </template>
    </Column>
    <Column field="contact" header="Contact">
      <template #body="{ data }">
        <div v-if="data.manual" class="flex flex-row gap-2">
          <div class="flex flex-row align-items-center gap-1">
            <Avatar :image="data.contactXPicture" shape="circle"/>
            <Button :label="`@${data.contactXHandle}`" class="p-button-sm p-button-link m-0 p-0"
                    @click="openExternalLink(`https://x.com/${data.contactXHandle}`)"/>
          </div>
          <Button v-if="data.manual && data.contactDiscordUsername"
                  v-tooltip.bottom="'Copy Discord username'"
                  class="p-button-sm p-button-link m-0 p-0 p-button-icon-only"
                  icon="pi pi-discord"
                  @click="copyToClipBoard(data.contactDiscordUsername)"/>
        </div>
        <div v-else class="flex flex-row gap-2 align-items-center">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${data.creator.discordId}/${data.creator.avatar}`"
                  shape="circle"/>
          <Button :label="data.creator.username" class="p-button-sm p-button-link capitalize m-0 p-0"
                  @click="router.push(`/collab-managers/${data.creator.id}`)"/>
          <Button v-if="data.creator.discordUsername" v-tooltip.top="'Copy username'"
                  class="p-button-sm p-button-link p-0" @click="copyToClipBoard(data.creator.discordUsername)">
            <i class="pi pi-discord"/>
          </Button>
        </div>
      </template>
    </Column>
    <Column :header="`Allocated spots`">
      <template #body="{data}">
        <InputText v-if="props.collabType === 'outbound'" v-model="data.spots"
                   class="p-inputtext-sm w-4rem text-center p-1"
                   @change="updateAllocatedSpots(data, $event)"/>
        <p v-else-if="props.collabType === 'inbound'" class="m-0 text-sm text-center text-600">
          {{ data.spots || '-' }}</p>
      </template>
    </Column>
    <Column field="collabManager" header="Collab Manager">
      <template #body="{ data }">
        <div v-if="!data.manual" class="flex flex-row gap-2 align-items-center">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${data.offer.user.discordId}/${data.offer.user.avatar}`"
                  shape="circle"/>
          <Button :label="data.offer.user.username" class="p-button-sm p-button-link capitalize m-0 p-0"
                  @click="router.push(`/collab-managers/${data.offer.user.id}`)"/>
          <Button v-if="data.offer.user.discordUsername" v-tooltip.top="'Copy username'"
                  class="p-button-sm p-button-link p-0" @click="copyToClipBoard(data.offer.user.discordUsername)">
            <i class="pi pi-discord"/>
          </Button>
        </div>
        <div v-else class="flex flex-row gap-2 align-items-center">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${data.creator.discordId}/${data.creator.avatar}`"
                  shape="circle"/>
          <Button :label="data.creator.username" class="p-button-sm p-button-link capitalize m-0 p-0"
                  @click="router.push(`/collab-managers/${data.creator.id}`)"/>
          <Button v-tooltip.top="'Copy username'" class="p-button-sm p-button-link p-0"
                  @click="copyToClipBoard(data.creator.discordUsername)">
            <i class="pi pi-discord"/>
          </Button>
        </div>
      </template>
    </Column>
    <Column class="mr-2" field="deadline" header="Deadline">
      <template #body="{ data }">
        <p class="m-0 text-center text-500">
          {{ data.deadline ? moment(data.deadline).format('MM/DD/YY') : '-' }}
        </p>
      </template>
    </Column>
    <template #expansion="{ data }">
      <CollaborationDetail :collab-type="collabType" :data="data" :project-id="projectId"/>
    </template>
  </DataTable>
</template>

<script setup>
import moment from "moment";
import {computed, defineProps, onMounted, ref, watch} from "vue";
import {useConfirm} from "primevue/useconfirm";
import {openExternalLink} from "@/utils/openLink";
import {useToast} from "primevue/usetoast";
import {collabOrderList, responseStatusList} from "@/utils/dropdownItems";
import store from "@/store";
import router from "@/router";
import OfferResponseService from "@/services/offerResponse.service";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import {getCollabManagerName, getContactName, getProjectName} from "@/utils/collaborationSelector";
import CollaborationDetail from "@/components/CollaborationDetail.vue";
import AddManualCollab from "@/components/modals/AddManualCollab.vue";

const props = defineProps({
  projectId: {type: String, required: true},
  collabType: {type: String, required: true},
});

const toast = useToast();
const confirm = useConfirm();
const collaborations = ref([]);
const projectFilterInput = ref("");
const statusMultiSelect = ref([]);
const offerResponses = computed(() => store.state.offerResponse.offerResponses);
const loading = computed(() => store.state.offerResponse.loading);
const savedExpandedRows = computed(() => store.state.offerResponse.savedExpandedRows);
const selectedCollabOrder = ref();
const expandedRows = ref([]);
const groupByStatus = ref();

onMounted(() => {
  groupByStatus.value = localStorage.getItem('groupByStatus') ? localStorage.getItem('groupByStatus') === "true" : true;
})
const orderCollaborations = (newCollaborations) => {
  collaborations.value = [];
  collaborations.value = newCollaborations.sort((a, b) => {
    if (a.status === "created" && b.status === "created") {
      return a.id - b.id;
    }

    if (a.status === "created") {
      return 1;
    }
    if (b.status === "created") {
      return -1;
    }

    if (groupByStatus.value === true) {
      const orderA = responseStatusList.value.findIndex(item => item.value === a.status);
      const orderB = responseStatusList.value.findIndex(item => item.value === b.status);
      return orderA - orderB;
    }

    return 0;
  });
}

const orderComparison = (a, b) => {
  let valueA = "";
  let valueB = "";

  if (selectedCollabOrder.value.value === 'project') {
    valueA = getProjectName(a, props.collabType.value)
    valueB = getProjectName(b, props.collabType.value)
  } else if (selectedCollabOrder.value.value === 'contact') {
    valueA = getContactName(a)
    valueB = getContactName(b)
  } else if (selectedCollabOrder.value.value === 'collab_manager') {
    valueA = getCollabManagerName(a)
    valueB = getCollabManagerName(b)
  } else if (selectedCollabOrder.value.value === 'raffle_end') {
    if (selectedCollabOrder.value.order === 'desc') {
      valueA = new Date(a.raffles.reduce((min, current) => current.date < min.date ? current : min, a.raffles[0])?.endDate)
      valueB = new Date(b.raffles.reduce((min, current) => current.date < min.date ? current : min, b.raffles[0])?.endDate)
    } else {
      valueA = new Date(a.raffles.reduce((max, current) => current.date > max.date ? current : max, a.raffles[0])?.endDate)
      valueB = new Date(b.raffles.reduce((max, current) => current.date > max.date ? current : max, b.raffles[0])?.endDate)
    }
  }

  if (selectedCollabOrder.value.order === 'desc') {
    if (valueA > valueB) return -1;
    if (valueA < valueB) return 1;
    return 0;
  }

  if (selectedCollabOrder.value.order === 'asc') {
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  }
}

watch(() => expandedRows.value,
    (newExpandedRows) => {
      store.state.offerResponse.savedExpandedRows = newExpandedRows.map(r => r.id);
    },
    {deep: true});

const searchProjectNameCondition = (name) => {
  return name.toLowerCase().includes(projectFilterInput.value.toLowerCase())
}

const loadCollaborations = (responses) => {
  collaborations.value = [];
  collaborations.value = responses.filter(resp => {
    if (resp.status === "archived") {
      // Archived inbound collab
      if (props.collabType === 'archived_inbound' && resp.offer && resp.offer.type === 'requesting' && resp.offer.project.id === props.projectId && searchProjectNameCondition(resp.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'archived_inbound' && resp.offer && resp.offer.type === 'offering' && resp.project.id === props.projectId && searchProjectNameCondition(resp.offer.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'archived_inbound' && resp.type === 'inbound' && resp.projectXHandle.toLowerCase().includes(projectFilterInput.value.toLowerCase())) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      // Archived outbound collab
      if (props.collabType === 'archived_outbound' && resp.offer && resp.status !== 'created' && resp.offer.type === 'offering' && resp.offer.project.id === props.projectId && searchProjectNameCondition(resp.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'archived_outbound' && resp.offer && resp.status !== 'created' && resp.offer.type === 'requesting' && resp.project.id === props.projectId && searchProjectNameCondition(resp.offer.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'archived_outbound' && resp.type === 'outbound' && resp.projectXHandle.toLowerCase().includes(projectFilterInput.value.toLowerCase())) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
    } else {
      // Inbound collab
      if (props.collabType === 'inbound' && resp.offer && resp.offer.type === 'requesting' && resp.offer.project.id === props.projectId && searchProjectNameCondition(resp.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'inbound' && resp.offer && resp.offer.type === 'offering' && resp.project.id === props.projectId && searchProjectNameCondition(resp.offer.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'inbound' && resp.type === 'inbound' && resp.projectXHandle.toLowerCase().includes(projectFilterInput.value.toLowerCase())) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      // Outbound collab
      if (props.collabType === 'outbound' && resp.offer && resp.offer.type === 'offering' && resp.offer.project.id === props.projectId && searchProjectNameCondition(resp.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'outbound' && resp.offer && resp.offer.type === 'requesting' && resp.project.id === props.projectId && searchProjectNameCondition(resp.offer.project.name)) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
      if (props.collabType === 'outbound' && resp.type === 'outbound' && resp.projectXHandle.toLowerCase().includes(projectFilterInput.value.toLowerCase())) {
        if (statusMultiSelect.value.length > 0) {
          if (statusMultiSelect.value.includes(resp.status)) return resp
        } else {
          return resp;
        }
      }
    }
  });
  if (selectedCollabOrder.value) collaborations.value = collaborations.value.sort(orderComparison);
  expandedRows.value = collaborations.value.filter(c => savedExpandedRows.value.includes(c.id));
  if(groupByStatus.value) orderCollaborations(collaborations.value)
}

const updateAllocatedSpots = (data, evt) => {
  OfferResponseService.updateOfferResponse(data.id, {spots: evt.target.value}).then(
      () => {
        toast.add({severity: 'info', summary: 'Updated', detail: "Allocated spots updated", life: 3000});
      },
      error => {
        toast.add({severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000});
      }
  )
}

watch(
    () => offerResponses.value,
    (newResponses) => loadCollaborations(newResponses),
    {immediate: true}
)

watch(
    () => props.collabType,
    () => {
      if (props.collabType.includes('inbound')) {
        selectedCollabOrder.value = {
          name: "Raffle end date",
          icon: "pi pi-sort-numeric-up",
          value: "raffle_end",
          order: "desc"
        };
      }
      loadCollaborations(offerResponses.value)
    },
    {immediate: true}
)

const handleGroupByStatusChange = () => {
  localStorage.setItem('groupByStatus', groupByStatus.value);
  orderCollaborations(collaborations.value)
}

const handleFilterCollabs = () => {
  collaborations.value = collaborations.value.sort(orderComparison);
}

const handleFilterProjectName = () => loadCollaborations(offerResponses.value)

const handleOfferResponseStatusChange = (evt, data) => {
  const status = evt.value.value;
  updateCollabStatus(status, data);
}

const updateCollabStatus = (status, currentCollab) => {
  if (status === 'discussion' && !currentCollab.manual) {
    let message = `You've pressed the negotiation button for this offer from <b>${currentCollab.creator.username}</b>, please send them a message on Discord with their username: <b>${currentCollab.creator.discordUsername}</b>`;
    message += currentCollab.creator.twitterUsername ? `or on Twitter with their handle @${currentCollab.creator.twitterUsername} to negotiate the terms for this collab.` : ' to negotiate the terms for this collab.';

    confirm.require({
      group: 'sendMessageConfirm',
      position: 'center',
      header: "Offer in negotiation",
      message: message,
      icon: "pi pi-comments",
      acceptClass: 'p-button-sm',
      acceptLabel: 'Ok',
      rejectLabel: ' ',
    });
  }

  OfferResponseService.updateOfferResponse(currentCollab.id, {status: status})
      .then(async response => {
        await store.dispatch('offerResponse/fetchOfferResponses', props.projectId);
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      })
      .catch(error => {
        toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      });
}

const copyToClipBoard = async (text) => {
  await navigator.clipboard.writeText(text);
  toast.add({severity: 'info', summary: 'Copied', detail: "Discord username copied to clipboard", life: 3000});
}

</script>

<style scoped></style>
