<template>

  <div class="flex flex-row justify-content-between align-items-center p-2 mb-3 flex-wrap">
    <h1 class="title">Notifications</h1>
    <NotificationsMarkAllAsRead/>
  </div>

  <NotificationsVisualization :deleting="true"/>

</template>

<script setup>
import NotificationsVisualization from "@/components/ListVisualization/NotificationsVisualization.vue";
import NotificationsMarkAllAsRead from "@/components/buttons/NotificationsMarkAllAsRead.vue";
import {onMounted} from "vue";
import store from "@/store";

onMounted(()=> store.dispatch('notification/fetchNotifications', {}))

</script>

<style scoped>

</style>