<template>

  <div v-if="loggedIn"
      class="flex w-2rem h-2rem border-round-3xl p-4 align-items-center justify-content-center cursor-pointer hover:surface-100"
      @click="toggleNotificationPanel">
    <i class="pi pi-bell p-overlay-badge text-xl">
      <Badge v-if="unreadNotifications > 0" :value="unreadNotifications"></Badge>
    </i>
  </div>

  <OverlayPanel ref="notificationPanel">
    <div class="flex flex-column gap-2 p-2" style="min-width: 24rem;">

      <div class="flex flex-row justify-content-between align-items-center p-2">
        <p class="m-0 font-semibold text-lg">Notifications</p>
        <NotificationsMarkAllAsRead/>
      </div>

      <div class="flex flex-column overflow-x-auto" style="max-height: 20rem;">
        <NotificationsVisualization/>
      </div>

      <Button class="p-button-text p-button-sm w-full flex align-items-center justify-content-center gap-2"
              @click="openNotifications">
        <span class="font-semibold">View all</span>
        <i class="pi pi-arrow-up-right"/>
      </Button>
    </div>
  </OverlayPanel>
</template>

<script setup>
import {computed, ref} from "vue";
import store from "@/store";
import router from "@/router";
import NotificationsVisualization from "@/components/ListVisualization/NotificationsVisualization.vue";
import NotificationsMarkAllAsRead from "@/components/buttons/NotificationsMarkAllAsRead.vue";

const unreadNotifications = computed(() => store.state.notification.unreadNotifications);
const loggedIn = computed(() => store.state.auth.status.loggedIn);
const notificationPanel = ref();

const toggleNotificationPanel = async (event) => notificationPanel.value.toggle(event)

const openNotifications = async (event) => {
  notificationPanel.value.toggle(event);
  await store.dispatch('notification/fetchNotifications', {})
  await router.push('/notifications');
}
</script>

<style scoped>

</style>