<template>
  <Button class="p-button-text p-button-icon-only p-button-xs p-button-plain" icon="pi pi-pencil"
          @click="openUpdateMemberModal"/>

  <Dialog v-model:visible="updateMemberModalVisible" :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :draggable="false"
          :style="{ width: '500px', maxWidth: '98vw'}"
          header="Update member"
          modal>
    <div v-if="memberForm" class="flex flex-column gap-2 pt-2">

      <div class="flex flex-column gap-1">
        <label for="username">Role</label>
        <Dropdown v-model="memberForm.role"
                  :options="memberRoleList"
                  class="border-300 p-inputtext-sm w-full">
          <template #value="slotProps">
            <p :style="{color: slotProps.value.color}" class="m-0 capitalize">{{ slotProps.value.label }}</p>
          </template>
          <template #option="slotProps">
            <p :style="{color: slotProps.option.color}" class="m-0 capitalize">{{ slotProps.option.label }}</p>
          </template>
        </Dropdown>
      </div>

      <!--      <p class="text-600 m-0 font-semibold mt-2">Wallets</p>

            <div class="flex flex-column gap-1">
              <div class="flex flex-row gap-1 align-items-center">
                <img :src="ethereumIcon" alt="Ethereum icon" height="16"/>
                <label for="walletEthereum">Ethereum</label>
              </div>
              <InputText id="walletEthereum" v-model="memberForm.walletEthereum" class="p-inputtext-sm" placeholder="0x..."/>
            </div>

            <div class="flex flex-column gap-1">
              <div class="flex flex-row gap-1 align-items-center">
                <img :src="solanaIcon" alt="Solana icon" height="14"/>
                <label for="walletSolana">Solana</label>
              </div>
              <InputText id="walletSolana" v-model="memberForm.walletSolana" class="p-inputtext-sm" placeholder="B3Z..."/>
            </div>

            <div class="flex flex-column gap-1">
              <div class="flex flex-row gap-1 align-items-center">
                <img :src="bitcoinIcon" alt="Bitcoin icon" height="16"/>
                <label for="walletBitcoin">Bitcoin</label>
              </div>
              <InputText id="walletBitcoin" v-model="memberForm.walletBitcoin" class="p-inputtext-sm" placeholder="bc1p..."/>
            </div>-->

    </div>

    <template #footer>
      <Button class="p-button-sm p-button-plain p-button-text" label="Cancel"
              @click="updateMemberModalVisible = false"/>
      <Button class="p-button-sm" icon="pi pi-check" label="Update member" @click="updateMember"/>
    </template>
  </Dialog>
</template>

<script setup>
import {defineProps, ref} from "vue";
import store from "@/store";
import {useToast} from "primevue/usetoast";
import ProjectService from "@/services/project.service";
import {memberRoleList} from "@/utils/dropdownItems";

const props = defineProps({
  member: {type: Object, required: true},
  projectId: {type: String, required: true},
})

const toast = useToast();
const memberForm = ref(null);
const updateMemberModalVisible = ref(false);

const openUpdateMemberModal = () => {
  memberForm.value = Object.assign({}, props.member);
  memberForm.value.role = memberRoleList.value.find(m => m.value === memberForm.value.role)
  updateMemberModalVisible.value = true;
}

const updateMember = () => {
  memberForm.value.role = memberForm.value.role.value;
  updateMemberModalVisible.value = false;

  ProjectService.updateProjectMember(props.projectId, memberForm.value).then(
      async response => {
        toast.add({severity: 'success', summary: 'Member added', detail: response.message, life: 3000})
        await store.dispatch('project/fetchProjectMembers', props.projectId);
      },
      error => toast.add({
        severity: 'error',
        summary: 'Failed update member',
        detail: error.response.data.message,
        life: 3000
      })
  )
}

</script>

<style scoped>

</style>