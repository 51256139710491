import api from "@/services/api";

class NotificationService {
    getNotifications(page, size) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/notifications?page=${page}&size=${size}`)
            .then(response => response.data);
    }

    updateNotificationsStatus(notificationIds) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/notifications`, {notificationsIds: notificationIds})
            .then(response => response.data);
    }


    allNotificationRead() {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/notifications-read`)
            .then(response => response.data);
    }


    deleteNotification(notificationId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/notifications/${notificationId}`)
            .then(response => response.data);
    }
}

export default new NotificationService();