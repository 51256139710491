<template>

  <div class="flex flex-column gap-2 flex-wrap">

    <div class="flex flex-row justify-content-between align-items-center">
      <h1 class="title">Listings</h1>
      <router-link to="/new-offer">
        <Button class="p-button-sm surface-50 hover:surface-200 p-button-text" icon="pi pi-plus" label="New"/>
      </router-link>
    </div>

    <div class="col-12 flex flex-row align-items-center justify-content-between gap-2">
      <div class="flex flex-row align-items-center gap-2 w-full">

            <span class="p-input-icon-left w-full lg:w-20rem">
              <i class="pi pi-search"/>
              <InputText v-model="searchData.query" class="border-300 p-inputtext-sm" placeholder="Search listing"/>
            </span>

        <MultiSelect v-model="searchData.blockchains"
                     :options="blockchainList"
                     class="w-12rem p-inputtext-sm hidden-mobile"
                     option-label="name"
                     option-value="name"
                     placeholder="Blockchain">
          <template #option="slotProps">
            <div class="flex gap-1 align-items-center">
              <img :alt="slotProps.option.name" :src="slotProps.option.icon" class="h-1rem"/>
              <p class="m-0 capitalize">{{ slotProps.option.name }}</p>
            </div>
          </template>
          <template #footer>
            <div class="py-2 px-3">
              <b>{{ searchData.blockchains ? searchData.blockchains.length : 0 }}</b>
              item{{ (searchData.blockchains ? searchData.blockchains.length : 0) > 1 ? 's' : '' }} selected.
            </div>
          </template>
        </MultiSelect>

        <Dropdown v-model="searchData.type"
                  :options="offerTypeList"
                  class="w-10rem p-inputtext-sm hidden-mobile"
                  option-label="name"
                  option-value="value"
                  placeholder="Listing type"/>

        <Button v-tooltip.bottom="'More filters'"
                class="p-button-icon-only p-button-sm p-button-outlined p-button-plain"
                icon="pi pi-filter" @click="visibleFilterSidebar = true"/>

      </div>

      <div class="flex flex-row gap-2 align-items-center hidden-tablet">

        <span class="p-buttonset flex flex-row flex-nowrap">
              <Button :class="{'surface-200': selectedVisualization === 'grid'}"
                      class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm"
                      icon="pi pi-table"
                      @click="switchVisualization('grid')"/>
              <Button :class="{'surface-200': selectedVisualization === 'row'}"
                      class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm"
                      icon="pi pi-list"
                      @click="switchVisualization('row')"/>
            </span>
      </div>


    </div>

    <Sidebar v-model:visible="visibleFilterSidebar" position="right">
      <h2 class="m-0">Filters</h2>

      <h4 class="text-600 m-0 mt-3 mb-2">Visualization</h4>

      <span class="p-buttonset flex flex-row flex-nowrap">
          <Button :class="{'surface-200': selectedVisualization === 'grid'}"
                  class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm w-full"
                  icon="pi pi-table"
                  label="Card"
                  @click="switchVisualization('grid')"/>
          <Button :class="{'surface-200': selectedVisualization === 'row'}"
                  class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm w-full"
                  icon="pi pi-list"
                  label="List"
                  @click="switchVisualization('row')"/>
        </span>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Listing type</h4>

      <Dropdown v-model="searchData.type"
                :options="offerTypeList"
                class="w-full p-inputtext-sm"
                option-label="name"
                option-value="value"
                placeholder="Listing type"/>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Project type</h4>

      <div class="flex flex-column gap-1">
        <div v-for="projectType of projectTypeList"
             :key="projectType.value" class="flex flex-row gap-2 p-1 cursor-pointer align-items-center">
          <Checkbox v-model="searchData.projectTypes" :inputId="projectType.label" :value="projectType.value"/>
          <p class="m-0 text-500 capitalize">{{ projectType.label }}</p>
        </div>
      </div>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Blockchain</h4>

      <div class="flex flex-column gap-1">
        <div v-for="blockchain of blockchainList"
             :key="blockchain.name" class="flex flex-row gap-2 p-1 cursor-pointer align-items-center">
          <Checkbox v-model="searchData.blockchains" :inputId="blockchain.name" :value="blockchain.name"/>
          <img :alt="`${blockchain.name} icon`" :src="blockchain.icon" class="h-1rem"/>
          <p class="m-0 text-500 capitalize">{{ blockchain.name }}</p>
        </div>
      </div>

    </Sidebar>

    <OffersVisualization :error="error"
                         :loading="loading"
                         :offers="offers"
                         :search-data="searchData"
                         :selected-visualization="selectedVisualization"/>

  </div>

</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {blockchainList, offerTypeList, projectTypeList} from "@/utils/dropdownItems";
import store from "@/store";
import OffersVisualization from "@/components/ListVisualization/OffersVisualization.vue";

const visibleFilterSidebar = ref(false);
const searchData = ref({
  query: "",
  blockchains: [],
  projectTypes: [],
  type: "all",
  order: {label: "Most Recent", by: "createdAt", sort: "desc"},
});
const selectedVisualization = ref("grid");

const switchVisualization = (type) => selectedVisualization.value = type;

onMounted(async () => {
  await store.dispatch('offer/fetchOffers', {});
})

const offers = computed(() => store.state.offer.offers);
const loading = computed(() => store.state.offer.loading);
const error = computed(() => store.state.offer.error);

watch(
    () => searchData,
    async (newSearchData) => {
      await store.dispatch('offer/fetchOffers', {...newSearchData.value, page: 0, size: 20});
    },
    {deep: true}
)
</script>

<style scoped>

</style>