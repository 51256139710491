<template>

  <div ref="scrollComponent" class="flex flex-row flex-wrap align-items-stretch">

    <template v-if="selectedVisualization === 'grid'">
      <ProjectCard v-for="project of projects" :key="project.id" :project="project"/>
    </template>

    <template v-else>
      <ProjectRowCard v-for="project of projects" :key="project.id" :project="project"/>
    </template>

  </div>

  <Loader v-if="loading"/>

  <ErrorSection v-else-if="!loading && error" icon="pi-times"
                text="Failed to retrieve projects"/>

  <EmptySection v-else-if="projects.length === 0" icon="pi-folder" text="No projects available yet"/>

</template>

<script setup>
import {computed, defineProps, onMounted, onUnmounted, ref} from 'vue';
import EmptySection from "@/components/sections/EmptySection.vue";
import ProjectRowCard from "@/components/cards/ProjectRowCard.vue";
import ProjectCard from "@/components/cards/ProjectCard.vue";
import Loader from "@/components/sections/Loader.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import store from "@/store";

const props = defineProps({
  selectedVisualization: {type: String, required: true},
  loading: {type: Boolean, required: true, default: true},
  error: {type: String, required: true, default: ""},
  projects: {type: Array, required: true},
  searchData: {type: Object, required: false},
  autoLoad: {type: Boolean, required: false, default: true},
})

const page = ref(0)
const scrollComponent = ref(null)
const totalPages = computed(() => store.state.project.totalPages)

onMounted(async () => window.addEventListener("scroll", handleScroll))

onUnmounted(() => window.removeEventListener("scroll", handleScroll))

const handleScroll = async () => {
  if (props.autoLoad) {
    let element = scrollComponent.value
    if (element.getBoundingClientRect().bottom < window.innerHeight) {
      if (totalPages.value >= page.value + 1) {
        page.value++;
        await store.dispatch('project/fetchProjects', {...props.searchData, page: page.value});
      }
    }
  }
}

</script>

<style scoped>

</style>