<template>
  <div class="flex flex-column gap-2 flex-wrap px-1 md:px-4 align-items-center">

    <form class="w-full flex flex-row flex-wrap justify-content-between" style="max-width: 900px;"
          @submit.prevent="handleSubmitUpdateOutreachList">

      <BackButton/>

      <h1 class="m-0 md:my-3 text-700 w-full">Edit outreach list</h1>

      <!-- INFORMATION -->
      <h2 class="m-0text-700 mb-2 text-lg w-full">Information</h2>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="name">
          <RequiredDot/>
          Name
        </label>
        <InputText id="username"
                   v-model="EditOutreachListForm.name"
                   :class="{'p-invalid': submitted && EditOutreachListForm.name === ''}"
                   aria-describedby="name-help"
                   class="p-inputtext-sm"/>
        <small v-if="submitted && EditOutreachListForm.name === ''" id="name-help" class="p-error">
          Name is required.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="description"> Description</label>
        <Textarea id="description"
                  v-model="EditOutreachListForm.description"
                  aria-describedby="description-help"
                  rows="6"/>
        <small :class="{'text-red-500': EditOutreachListForm.description.length > 255}" class="text-right text-600">
          {{ EditOutreachListForm.description.length }} / 255
        </small>
        <small v-if="submitted && EditOutreachListForm.description.length > 255" id="description-help" class="p-error">
          Description must be lower than 255 characters.
        </small>
      </div>

      <!-- USERS -->
      <div class="flex flex-row justify-content-between align-items-center flex-wrap mt-4 mb-2 w-full">
        <h2 class="m-0 text-700 text-lg">Users</h2>
        <SearchUsersModal/>
      </div>

      <EmptySection v-if="EditOutreachListForm.members.length <= 0"
                    icon="pi pi-users" text="No members added"/>

      <ListMembersVisualization :members="EditOutreachListForm.members"/>

      <!-- ACTIONS -->
      <div class="col-12 md:px-2 p-0 flex flex-row gap-3 mt-4 justify-content-end">
        <Button :disabled="loading" class="p-button-sm p-button-text p-button-plain" label="Cancel"
                @click="$router.go(-1)"/>
        <Button :loading="loading" class="p-button-sm" icon="pi pi-check" label="Save outreach list" type="submit"/>
      </div>

    </form>

  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, watch} from "vue";
import BackButton from "@/components/buttons/BackButton.vue";
import RequiredDot from "@/components/RequiredDot.vue";
import store from "@/store";
import UserListService from "@/services/outreachList.service";
import SearchUsersModal from "@/components/modals/SearchUsersModal.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import ListMembersVisualization from "@/components/ListVisualization/ListMembersVisualization.vue";
import router from "@/router";
import {useToast} from "primevue/usetoast";

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const toast = useToast();
const submitted = ref(false);
const loading = ref(false);
const EditOutreachListForm = ref({
  name: "",
  description: "",
  members: [],
});

onMounted(() => store.dispatch("outreachList/resetListMembers"))

watch(
    () => store.state.outreachList.listMembers,
    (newMembers) => EditOutreachListForm.value.members = newMembers,
    {immediate: true}
)

watch(
    () => props.id,
    async (newId) => {
      if (newId) {
        const {
          id,
          name,
          description,
          listMembers: members
        } = await store.dispatch("outreachList/fetchOutreachList", newId);
        EditOutreachListForm.value = {id, name, description, members};
        EditOutreachListForm.value.members = EditOutreachListForm.value.members.map(m => ({
          id: m.user.discordId,
          avatar: m.user.discordAvatar, ...m.user
        }))
        await store.dispatch("outreachList/setListMembers", EditOutreachListForm.value.members)
      }
    },
    {immediate: true}
)

const handleSubmitUpdateOutreachList = async () => {
  submitted.value = true;

  if (EditOutreachListForm.value.name === "" || EditOutreachListForm.value.description.length >= 255) return;

  loading.value = true;

  await UserListService.updateUserList(EditOutreachListForm.value)
      .then(response => {
        toast.add({severity: 'success', summary: 'Deleted', detail: response.message, life: 3000});
        store.dispatch("outreachList/fetchOutreachLists");
        router.go(-1)
      })
      .catch(error => {
        console.error("UserListService.updateUserList", error);
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message,
          life: 3000
        });
      });

  loading.value = false;
}
</script>

<style scoped>

</style>