import AuthService from "@/services/auth.service";
import TokenService from "@/services/token.service";
import api from "@/services/api";

const user = TokenService.getUser();
const initialState = user ? {status: {loggedIn: true}, user} : {status: {loggedIn: false}, user: null};
export const auth = {
    namespaced: true, state: initialState, actions: {
        async refreshConnection({commit}) {
            const user = TokenService.getUser()

            if (user !== null) {
                await api.post("/auth/refreshToken", {
                    refreshToken: user.refreshToken
                }).then(
                    rs => {
                        const {accessToken} = rs.data;
                        commit('loginSuccess', {...user, accessToken})
                    },
                    () => {
                        AuthService.logout();
                        commit('logout');

                    })


            } else {
                AuthService.logout();
                commit('logout');
            }

        },
        login({commit, dispatch}) {
            return AuthService.login().then(response => {
                commit('loginSuccess', response.user);
                dispatch('notification/fetchNotifications', {})
                return Promise.resolve(response);
            }, error => {
                commit('loginFailure');
                return Promise.reject(error);
            });
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        refreshToken({commit}, accessToken) {
            commit('refreshToken', accessToken);
        },
        setConnectedUser({state}, user) {
            state.user = user;
        }
    }, mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        }, loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        }, logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        }, refreshToken(state, accessToken) {
            state.status.loggedIn = true;
            state.user = {...state.user, accessToken: accessToken};
        }
    }
}