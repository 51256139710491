<script setup>
import {defineProps} from "vue"
import {getBlockchainIcon} from "@/utils/blockchains";
import {useToast} from "primevue/usetoast";

const toast = useToast()
const props = defineProps({
  address: {type: String, required: true},
  icon: {type: String, required: true},
})


const copyToClipBoard = async () => {
  await navigator.clipboard.writeText(props.address);
  toast.add({severity: 'info', summary: 'Copied', detail: "Address copied to clipboard", life: 3000});
}

</script>

<template>
  <Button class="p-button-icon-only p-button-xs p-button-text" @click="copyToClipBoard">
    <i class="flex align-items-center">
      <img :src="getBlockchainIcon(icon)" alt="Ethereum icon" :height="icon === 'solana' ? 14 : 16"/>
    </i>
  </Button>
</template>

<style scoped>

</style>