import api from "@/services/api";

class OfferResponse {
    createOfferResponse(offer) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/offer-responses`, offer)
            .then(response => response.data);
    }
    createManualResponse(response) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/manual-response`, response)
            .then(response => response.data);
    }
    updateOfferResponse(offerResponseId, body) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/offer-responses/${offerResponseId}`, body)
            .then(response => response.data);
    }
    deleteOfferResponse(offerResponseId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/offer-responses/${offerResponseId}`)
            .then(response => response.data);
    }
    fetchAllResponsesByProject(projectId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/offer-responses/${projectId}`)
            .then(response => response.data);
    }
    getWaitingResponses(offerId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/offer-responses/${offerId}/waiting`)
            .then(response => response.data);
    }
    createCollabSpreadSheet(collabId) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/offer-responses/spreadsheet/${collabId}`)
            .then(response => response.data);
    }
    synchronizeCollabRafflesSpreadsheet(collabId, spreadsheetId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/offer-responses/${collabId}/spreadsheet/${spreadsheetId}/synchronize`)
            .then(response => response.data);
    }
}

export default new OfferResponse();