import OfferService from "@/services/offer.service";

const initialState = {
    offers: [],
    offer: null,
    loading: false,
    error: null,
    totalPages: 0,
};
export const offer = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchOffers({commit, state}, payload = {
            page: Number,
            size: Number,
            query: String,
            blockchains: Array,
            projectTypes: Array,
            type: String,
            order: Object
        }) {
            state.loading = true;
            const {
                page = 0,
                size = 20,
                query = "",
                blockchains = [],
                projectTypes = [],
                type = "all",
                order = {label: "most recent", by: "createdAt", sort: "desc"}
            } = payload;
            if (page === 0) state.offers = [];
            return OfferService.getOffers(page, size, order.by, order.sort, query, type, blockchains, projectTypes).then(
                response => {
                    state.totalPages = response.totalPages;
                    const results = [...state.offers, ...response.items];
                    commit('fetchOffersSuccess', results)
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        fetchOffer({commit, state}, offerId) {
            state.offer = null;
            state.loading = true;
            return OfferService.getOffer(offerId).then(
                response => {
                    commit('fetchOfferSuccess', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        fetchOffersSuccess(state, offers) {
            state.loading = false;
            state.offers = offers;
        },
        fetchOfferSuccess(state, offer) {
            state.loading = false;
            state.offer = offer;
        },
        fetchFailure(state, error) {
            state.offers = [];
            state.offer = null;
            state.loading = false;
            state.error = error?.response?.data?.message || error.message;
        },
    }
}