<template>
  <div>
    <Tag v-if="props.blockchain" class="white-space-nowrap blur-background" style="font-size: 0.7rem;">
      <div v-tooltip.top="{ value: `<span class='text-white p-0 capitalize'>${props.blockchain}</span>`, escape: true }"
           class="flex align-items-center gap-1">
        <img :src="blockchainIcon" alt="Blockchain" height="16" width="16">
      </div>
    </Tag>
  </div>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import {getBlockchainIcon} from "@/utils/blockchains";

const blockchainIcon = ref("");
const props = defineProps(['blockchain'])

watch(
    () => props,
    (props) => blockchainIcon.value = getBlockchainIcon(props.blockchain),
    {immediate: true}
)

</script>

<style scoped>
</style>