import OfferResponseService from "@/services/offerResponse.service";

const initialState = {
    offerResponses: [],
    loading: false,
    error: null,
    savedExpandedRows: [],
};
export const offerResponse = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchOfferResponses({commit, state}, projectId) {
            state.loading = true;
            return OfferResponseService.fetchAllResponsesByProject(projectId).then(
                response => {
                    commit('fetchOfferResponsesSuccess', response)
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        fetchWaitingOfferResponses({commit, state}, offerId) {
            state.offerResponseReceived = [];
            return OfferResponseService.getWaitingResponses(offerId).then(response => {
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
    },
    mutations: {
        fetchOfferResponsesSuccess(state, responses) {
            state.loading = false;
            state.offerResponses = responses;
        },
        fetchFailure(state, error) {
            state.offerResponses = [];
            state.loading = false;
            state.error = error?.response?.data?.message || error.message;
        },
    }
}