import api from "@/services/api";

class OfferService {
    getOffers(page, size, orderBy, orderSort, query, type, blockchains, projectTypes) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/offers?page=${page}&size=${size}&orderBy=${orderBy}&orderSort=${orderSort}&query=${query}&type=${type}&blockchains=${blockchains.toString()}&projectTypes=${projectTypes.toString()}`)
            .then(response => response.data);
    }

    getOffer(offerId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/offers/${offerId}`)
            .then(response => response.data);
    }

    createOffer(offer, userList) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/offers`, {offer, userList})
            .then(response => response.data);
    }

    updateOffer(offer) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/offers`, offer)
            .then(response => response.data);
    }

    deleteOffer(offerId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/offers/${offerId}`)
            .then(response => response.data);
    }
}

export default new OfferService();