<template>
  <div class="col-12 flex flex-column">
    <div v-for="(member, memberIndex) of members"
         :key="member.id"
         class="flex flex-row justify-content-between align-items-center w-full">
      <div class="flex flex-row align-items-center gap-2 py-2">
        <Avatar :image="`https://cdn.discordapp.com/avatars/${member.discordId}/${member.avatar}`"
                shape="circle"/>
        <p class="m-0 text-500 capitalize">{{ member.username }}</p>
      </div>
      <Button class="p-button-sm p-button-danger p-button-text"
              icon="pi pi-times"
              label="Remove"
              @click="removeUserFromOutreachList(memberIndex)"/>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from "vue"
import store from "@/store";

defineProps({
  members: {type: Array, required: true}
})

const removeUserFromOutreachList = (index) => store.dispatch("outreachList/removeUserToList", index);
</script>

<style scoped>

</style>