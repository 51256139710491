<template>
  <div class="grid p-2">

    <div class="col-12 flex flex-row justify-content-between align-items-center">
      <h2 class="m-0 mt-4 text-700 text-lg w-full flex flex-row align-items-center gap-1">
        <AlphabotIcon width="12"/>
        Alphabot token
        <Button class="p-button-sm p-button-text p-button-plain p-button-icon-only"
                icon="pi pi-question-circle"
                @click="toggleAlphabotHelpOverlay"/>

        <OverlayPanel ref="alphabotHelpOverlay" class="max-w-20rem">
          <div class="flex flex-column gap-2 p-2">
            <p class="m-0 text-sm font-semibold">Where to find the token?</p>
            <p class="m-0 text-sm text-500">
              The Alphabot token can be found in your team settings on
              <a href="https://www.alphabot.app/team" target="_blank" class="text-primary">the site</a>
              in the “Developer settings” section.
            </p>
            <Image :src="alphabotHelpPicture" alt="Alphabot screenshot" preview width="300"/>
          </div>
        </OverlayPanel>
      </h2>
      <div class="flex flex-row align-items-center gap-2">
        <Button v-if="!edition" class="p-button-sm p-button-text" icon="pi pi-pencil" label="Edit"
                @click="edition = true"/>
        <template v-else>
          <Button class="p-button-sm p-button-text p-button-success" icon="pi pi-save" label="Save"
                  @click="handleSaveSettings"/>
          <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="cancelSettingsEdition"/>
        </template>
      </div>
    </div>

    <div class="col-12 md:px-2 p-0 flex flex-column gap-1 ">
      <small class="text-500">Necessary to retrieve raffle winners and their end date automatically</small>
      <Password id="alphabotToken"
                v-model="alphabotTokenInput"
                :disabled="!edition"
                :feedback="false"
                aria-describedby="alphabotToken-help"
                class="p-inputtext-sm"
                placeholder="***************************"
                toggleMask/>
    </div>

    <div class="col-12 flex flex-column justify-content-between align-items-start gap-2">
      <h2 class="m-0 mt-4 text-700 text-lg w-full">Delete project</h2>
      <small class="text-500">Delete your project as well as all related collaborations</small>
      <ConfirmPopup/>
      <Button class="p-button-sm p-button-danger" icon="pi pi-trash" label="Delete"
              @click="confirmDeletion($event)"/>
    </div>

  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref, watch} from "vue";
import store from "@/store";
import ProjectService from "@/services/project.service";
import router from "@/router";
import AlphabotIcon from "@/components/icons/AlphabotIcon.vue";
import {useToast} from "primevue/usetoast";
import {useConfirm} from "primevue/useconfirm";
import alphabotHelpPicture from "@/assets/images/help/alphabot_token_help.png"

const confirm = useConfirm()
const toast = useToast()
const project = computed(() => store.state.user.userProject)
const alphabotTokenInput = ref(null)
const submitted = ref(false)
const edition = ref(false)
const alphabotHelpOverlay = ref()

watch(
    () => project,
    (newProject) => {
      if (newProject.value && Object.keys(newProject.value).length > 0) {
        alphabotTokenInput.value = newProject.value.alphabotToken;
      }
    },
    {immediate: true, deep: true}
)

onBeforeUnmount(() => cancelSettingsEdition());

const toggleAlphabotHelpOverlay = (event) => alphabotHelpOverlay.value.toggle(event);

const handleSaveSettings = async () => {
  submitted.value = true;

  await ProjectService.updateProject({alphabotToken: alphabotTokenInput.value}, project.value.id).then(
      async response => {
        await store.dispatch('user/fetchUserProject', project.value.id)
        await toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      },
      async error => {
        await toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      }
  )

  edition.value = false;
}

const cancelSettingsEdition = () => {
  submitted.value = false;
  alphabotTokenInput.value = project.value.alphabotToken;
  edition.value = false;
}

const confirmDeletion = (event) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Do you want to delete this project?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger p-button-sm',
    accept: () => {
      ProjectService.deleteProject(project.value.id).then(
          async () => {
            await router.push("/dashboard/projects")
            toast.add({severity: 'success', summary: 'Success', detail: "Project deleted", life: 3000});
          },
          error => {
            toast.add({
              severity: 'error',
              summary: 'Failed to delete project',
              detail: error.response.data.message,
              life: 3000
            });
          }
      )
    },
  });
}

</script>

<style scoped>

</style>