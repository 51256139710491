<template>
  <Button label="Template"
          class="p-button-xs hidden-mobile p-button-outlined p-button-secondary"
          icon="pi pi-book"
          @click="visibleResponseRequirementsModal = true"/>
  <Button v-tooltip.bottom="'Template'"
          class="p-button-xs hidden-desktop p-button-outlined p-button-icon-only p-button-secondary"
          icon="pi pi-book"
          @click="visibleResponseRequirementsModal = true"/>

  <Dialog v-model:visible="visibleResponseRequirementsModal"
          :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :style="{ width: '500px', maxWidth: '98vw'}"
          :draggable="false"
          header="Template"
          modal>
    <p v-if="responseTemplate" v-html="responseTemplate"></p>
    <p v-else class="text-center text-500">No template yet</p>
  </Dialog>
</template>

<script setup>
import {defineProps, ref} from "vue";

let visibleResponseRequirementsModal = ref(false)

defineProps({
  responseTemplate: {
    type: String,
    required: true,
  },
});
</script>

<style scoped>

</style>