<template>
  <Dialog v-model:visible="importTeamWalletModalVisible" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
          :style="{ width: '50rem', maxWidth: '500px' }"
          header="Import team wallets" modal>
    <div class="flex flex-column gap-2">

      <div class="field flex flex-column">
        <label>Blockchain</label>
        <Dropdown v-model="blockchainSelected"
                  :class="{'p-invalid': walletSubmitted && blockchainSelected === null}"
                  :options="blockchainList"
                  class="w-full p-inputtext-sm"
                  option-label="name"
                  placeholder="Blockchain"
                  @change="handleBlockchainChange">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex gap-2 align-items-center">
              <img :alt="slotProps.value.name" :src="slotProps.value.icon" class="h-1rem"/>
              <p class="m-0 capitalize">{{ slotProps.value.name }}</p>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex gap-2 align-items-center">
              <img :alt="slotProps.option.name" :src="slotProps.option.icon" class="h-1rem"/>
              <p class="m-0 capitalize">{{ slotProps.option.name }}</p>
            </div>
          </template>
        </Dropdown>
        <small v-if="walletSubmitted && blockchainSelected === null" id="blockchain-help" class="p-error">
          Blockchain is required.
        </small>
      </div>

      <div class="field flex flex-column overflow-y-auto h-11rem max-h-11rem">
        <label>Members</label>

        <p v-if="!blockchainSelected" class="m-0 text-center text-500 text-sm">Select blockchain before selected
          members</p>
        <p v-if="blockchainSelected && availableMembersFromBc.length === 0" class="m-0 text-center text-500 text-sm">
          No team member has an {{ blockchainSelected.name }} wallet
        </p>

        <div class="flex flex-column gap-1">
          <div v-for="member of availableMembersFromBc"
               :key="`user${member.userId}`" class="flex flex-row gap-3 align-items-center p-1">
            <Checkbox v-model="membersSelected" :value="member"/>
            <div class="flex flex-row gap-1 align-items-center">
              <Avatar :image="`https://cdn.discordapp.com/avatars/${member.user.discordId}/${member.user.avatar}`"
                      shape="circle"/>
              <p class="m-0 text-600 capitalize">{{ member.user.username }}</p>
            </div>
          </div>
        </div>

        <small v-if="walletSubmitted && membersSelected.length === 0" id="members-help" class="p-error">
          Select at least one member.
        </small>
      </div>

    </div>

    <template #footer>
      <Button class="p-button-sm p-button-plain p-button-text" label="Cancel"
              @click="importTeamWalletModalVisible = false"/>
      <Button :loading="loading" class="p-button-sm" icon="pi pi-file-import" label="Import"
              @click="handleImportMembersWallet"/>
    </template>
  </Dialog>

</template>

<script setup>
import {computed, defineProps, ref, watch} from "vue";
import {blockchainList} from "@/utils/dropdownItems";
import {useToast} from "primevue/usetoast";
import store from "@/store";
import ProjectService from "@/services/project.service";

const members = computed(() => store.state.project.projectMembers);
const toast = useToast();
const importTeamWalletModalVisible = ref(false);
const walletSubmitted = ref(false);
const loading = ref(false);
const membersSelected = ref([]);
const availableMembersFromBc = ref([]);
const blockchainSelected = ref(null);

const props = defineProps({
  projectId: {type: String, required: true},
  spreadSheetId: {type: String, required: true},
  visible: {type: Boolean, required: true},
})

watch(() => props,
    async (newProps) => {
      importTeamWalletModalVisible.value = newProps.visible;
      if (newProps.visible) await store.dispatch('project/fetchProjectMembersWithWallets', newProps.projectId);
    }, {immediate: true, deep: true})

const handleBlockchainChange = () => {
  walletSubmitted.value = false;
  membersSelected.value = [];
  availableMembersFromBc.value = members.value.filter(m => {
    const availableWallets = m.user.wallets.filter(w => w.blockchain === blockchainSelected.value.name)
    return availableWallets.length > 0;
  })
}

const handleImportMembersWallet = () => {
  walletSubmitted.value = true;
  loading.value = true;

  if (blockchainSelected.value === null || membersSelected.value.length === 0) {
    loading.value = false;
    return;
  }

  ProjectService.importMembersWallet(
      props.projectId,
      props.spreadSheetId,
      {
        members: membersSelected.value.map(m => m.userId),
        blockchain: blockchainSelected.value.name
      }
  ).then(
      async response => {
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        importTeamWalletModalVisible.value = false;
        location.reload();
      },
      error => {
        importTeamWalletModalVisible.value = false;
        toast.add({
          severity: 'error',
          summary: error.response.data.message,
          detail: error.response.data.error,
          life: 3000
        });
      }
  );
}

</script>

<style scoped>

</style>