<template>

  <div class="flex flex-row justify-content-between align-items-center">
    <BackButton/>
    <AddToOutreachList v-if="!loading && loggedIn && fetchedUser && fetchedUser.id !== user.id"
                       :user="fetchedUser"/>
  </div>

  <Loader v-if="loading"/>

  <template v-if="!loading && fetchedUser">

    <div class="flex flex-column gap-3 mt-3">

      <div class="relative w-full">
        <div :style="`background: url(${fetchedUser.banner}) no-repeat center;`"
             class="banner-background absolute left-0 top-0 w-full h-full border-round-xl"></div>
        <div class="relative flex flex-column align-items-center gap-2 p-2">
          <CardSocialButtons :discord="fetchedUser.discordUsername" :twitter="fetchedUser.twitterUsername"
                             class="w-full"/>
          <img :src="`https://cdn.discordapp.com/avatars/${fetchedUser.discordId}/${fetchedUser.avatar}`"
               alt="Profile picture"
               class="border-round-xl h-8rem w-auto"/>
          <div class="flex flex-column align-items-center w-full blur-background p-2 border-round-lg">
            <p class="m-0 text-4xl font-semibold text-center text-200 capitalize cropped" style="max-width: 64vw;">
              {{ fetchedUser.username }}
            </p>
            <p class="m-0 text-center md:px-8 text-300 max-w-full" style="overflow-wrap: break-word;">
              {{ fetchedUser.description }}
            </p>
          </div>
        </div>
      </div>

      <p class="m-0 font-semibold text-2xl mt-4">Projects</p>

      <div class="grid">
        <ProjectCard v-for="project of fetchedUser.projects" :key="project.id" :project="project"/>
      </div>

      <EmptySection v-if="fetchedUser.projects.length <=0" icon="pi-folder" text="No project available"/>

      <p class="m-0 font-semibold text-2xl mt-4">Listings</p>

      <div class="grid">
        <OfferCard v-for="offer of fetchedUser.offers" :key="offer.id" :offer="offer"/>

        <EmptySection v-if="!fetchedUser.offers || fetchedUser.offers.length <=0" icon="pi-tag"
                      text="No listing available"/>
      </div>

    </div>

  </template>

  <div v-if="!loading && !fetchedUser && !error"
       class="flex flex-column h-30rem justify-content-center align-items-center gap-2">
    <p class="m-0 text-600">Collab manager not exist</p>
    <Button class="p-button-text p-button-sm" label="View all collab managers"
            @click="router.push('/collab-managers')"/>
  </div>

  <ErrorSection v-if="!loading && error" icon="pi pi-times" text="Failed to fetch this collab manager"/>

</template>

<script setup>
import {computed, defineProps, watch} from "vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import OfferCard from "@/components/cards/OfferCard.vue";
import ProjectCard from "@/components/cards/ProjectCard.vue";
import CardSocialButtons from "@/components/buttons/CardSocialButtons.vue";
import AddToOutreachList from "@/pages/modals/AddToOutreachList.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import store from "@/store";
import Loader from "@/components/sections/Loader.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import router from "@/router";
import {useMeta} from "vue-meta";

const props = defineProps({
  id: {type: Number, required: true}
})

const user = computed(() => store.state.auth.user)

const loggedIn = computed(() => store.state.auth.status.loggedIn)

watch(
    () => props.id,
    async (newId) => {
      if (newId) {
        await store.dispatch("user/fetchUser", newId);
        const description = `#${fetchedUser.value.discordUsername} • @${fetchedUser.value.twitterUsername} • ${fetchedUser.value.description}`;
        useMeta({
          description: description,
          meta: [
            {
              property: 'og:title',
              name: 'twitter:title',
              content: fetchedUser.value.username
            },
            {
              property: 'og:description',
              name: 'twitter:description',
              content: description,
            },
            {
              property: 'og:image',
              name: 'twitter:image',
              content: `https://cdn.discordapp.com/avatars/${fetchedUser.value.discordId}/${fetchedUser.value.avatar}`
            },
          ]
        });
      }
    },
    {immediate: true}
)

const fetchedUser = computed(() => store.state.user.user);
const loading = computed(() => store.state.user.loading);
const error = computed(() => store.state.user.error);
</script>

<style scoped>
</style>