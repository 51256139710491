<template>

  <Loader v-if="loading"/>

  <ErrorSection v-else-if="error" icon="pi-times" text="Failed to retrieve projects"/>

  <EmptySection v-else-if="projects.length === 0" icon="pi-folder" text="No projects available yet"/>

  <div v-else class="flex flex-row flex-wrap align-items-stretch">
    <UserProjectCard v-for="project of projects" :key="project.id" :project="project"/>
  </div>

</template>

<script setup>
import {computed, onMounted} from "vue";
import store from "@/store";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import Loader from "@/components/sections/Loader.vue";
import UserProjectCard from "@/components/cards/UserProjectCard.vue";

const projects = computed(() => store.state.user.userProjects);
const loading = computed(() => store.state.user.loading);
const error = computed(() => store.state.user.error)

onMounted(() => store.dispatch("user/fetchUserProjects"));
</script>

<style scoped></style>