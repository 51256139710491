import api from "@/services/api";

class OutreachListService {
    getUserLists() {
        return api
            .get(`${process.env.VUE_APP_API_URL}/outreach-list`)
            .then(response => response.data);
    }
    getUserList(id) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/outreach-list/${id}`)
            .then(response => response.data);
    }

    createUserList(list) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/outreach-list`, list)
            .then(response => response.data);
    }

    updateUserList(project) {
        return api
            .put(`${process.env.VUE_APP_API_URL}/outreach-list`, project)
            .then(response => response.data);
    }

    addUserToLists(body) {
        return api
            .put(`${process.env.VUE_APP_API_URL}/outreach-list/add-user`, body)
            .then(response => response.data);
    }

    deleteUserList(userListId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/outreach-list/${userListId}`)
            .then(response => response.data);
    }
}

export default new OutreachListService();