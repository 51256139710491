<template>
  <div v-if="user" class="flex flex-column gap-3">

    <div
        class="flex flex-column px-2 h-10rem gap-2 justify-content-center align-items-center border-round-xl surface-100">
      <h1 class="m-0">Settings</h1>
      <p class="m-0 text-600 text-center">
        Manages accounts connected
      </p>
    </div>

    <div class="flex flex-column mt-2">
      <h2 class="m-0">Connected accounts</h2>
      <p class="m-0 text-600">
        These are the accounts you'll use to sign in to Ind3X. You must have at least one account linked.
      </p>

      <div class="flex flex-column gap-3 pt-4">

        <div class="flex flex-row gap-4 align-items-center border-round-lg p-3 surface-100 flex-wrap">
          <i class="pi pi-discord text-4xl text-purple-500"/>
          <div class="flex flex-column gap-1 flex-grow-1">
            <p class="m-0 text-sm text-700">Discord</p>
            <p class="m-0 text-primary font-semibold"> {{ currentUser.discordUsername }} </p>
          </div>
        </div>

        <div class="flex flex-row gap-4 align-items-center border-round-lg p-3 surface-100 flex-wrap">
          <XIcon heigh="24" style="position: relative; height: 40px;" width="24"/>
          <div class="flex flex-column gap-1 flex-grow-1">
            <p class="m-0 text-sm text-700">X</p>
            <p v-if="currentUser.twitterUsername == null" class="m-0 text-primary font-semibold">Not connected</p>
            <a v-else :href="`https://twitter.com/${currentUser.twitterUsername}`"
               class="m-0 text-primary font-semibold"
               target="_blank">
              @{{ currentUser.twitterUsername }}
            </a>
          </div>
          <button v-if="currentUser.twitterUsername == null"
                  class="gsi-material-button"
                  @click="connectTwitter">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
              <div class="gsi-material-button-icon">
                <svg style="display: block;" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 271">
                  <path fill="black" d="m236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z"/>
                </svg>
              </div>
              <span class="gsi-material-button-contents">Link your X account</span>
              <span style="display: none;">Link your X account</span>
            </div>
          </button>
          <Button v-else class="p-button-sm p-button-outlined p-button-danger" label="Disconnect"
                  @click="disconnectTwitter"/>
        </div>

        <div class="flex flex-row gap-4 align-items-center border-round-lg p-3 surface-100 flex-wrap">
          <i class="pi pi-google text-4xl text-red-500"/>
          <div class="flex flex-column gap-1 flex-grow-1">
            <p class="m-0 text-sm text-700">Google</p>
            <p v-if="currentUser.googleEmail == null" class="m-0 text-primary font-semibold">Not connected</p>
            <p class="m-0 text-primary font-semibold">{{ currentUser.googleEmail }}</p>
          </div>
          <Button v-if="currentUser.googleEmail" class="p-button-sm p-button-outlined p-button-danger"
                  label="Disconnect"
                  @click="disconnectGoogle"/>
          <button v-else
                  class="gsi-material-button"
                  @click="connectGoogle">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
              <div class="gsi-material-button-icon">
                <svg style="display: block;" viewBox="0 0 48 48"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        fill="#EA4335"></path>
                  <path d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        fill="#4285F4"></path>
                  <path d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        fill="#FBBC05"></path>
                  <path d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        fill="#34A853"></path>
                  <path d="M0 0h48v48H0z" fill="none"></path>
                </svg>
              </div>
              <span class="gsi-material-button-contents">Sign in with Google</span>
              <span style="display: none;">Sign in with Google</span>
            </div>
          </button>
        </div>

      </div>
    </div>

    <div class="flex flex-column mt-2">
      <div class="flex flex-row justify-content-between">
        <h2 class="m-0">Wallets</h2>
        <AddWalletModal :user-wallets="userWallets.map(w => w.blockchain)"/>
      </div>
      <p class="m-0 text-600">
        Add your wallets so that collab managers can simply add them to collaborations
      </p>

      <div class="flex flex-column gap-3 pt-4">

        <Loader v-if="loading"/>

        <ErrorSection v-else-if="error" icon="pi-wallet" text="Error while fetching wallets"/>

        <EmptySection v-else-if="userWallets.length === 0" icon="pi-wallet" text="No wallet registered"/>

        <div v-else class="flex flex-column gap-3 pt-4">

          <div v-for="(wallet, wIdx) of userWallets"
               :key="`wallet${wIdx}`"
               class="flex flex-row gap-4 align-items-center border-round-lg p-3 surface-100 flex-wrap">
            <img :height="24" :src="getBlockchainIcon(wallet.blockchain)" alt="Blockchain icon"/>
            <div class="flex flex-column gap-1 flex-grow-1">
              <p class="m-0 text-sm text-700 capitalize">{{ wallet.blockchain }}</p>
              <p class="m-0 text-primary font-semibold">{{ wallet.address }}</p>
            </div>
            <Button class="p-button-xs p-button-text p-button-icon-only p-button-plain"
                    icon="pi pi-minus"
                    @click="deleteWallet(wallet.id)"/>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script setup>
import UserService from "@/services/user.service";
import {computed, defineProps, ref, watch} from "vue";
import {useToast} from "primevue/usetoast";
import store from "@/store";
import XIcon from "@/components/icons/XIcon.vue";
import AddWalletModal from "@/components/modals/AddWalletModal.vue";
import Loader from "@/components/sections/Loader.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import {getBlockchainIcon} from "@/utils/blockchains";

const props = defineProps({
  user: {type: String},
  type: {type: String},
})

const toast = useToast()
const twitterSignUpUrl = computed(() => `${process.env.VUE_APP_API_URL}/user/twitter`)
const googleSignUpUrl = computed(() => `${process.env.VUE_APP_API_URL}/user/google`)
const currentUser = computed(() => store.state.auth.user);
const userWallets = computed(() => store.state.user.userWallets);
const loading = computed(() => store.state.user.loading);
const error = computed(() => store.state.user.error);
const user = ref(null);

watch(
    () => props.type,
    (newType) => {
      if (newType) {
        const user = JSON.parse(props.user);
        if (user && (newType === 'twitter' || newType === 'google')) {
          UserService.updateUser(user).then(
              (response) => {
                toast.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: response.message,
                  life: 3000
                });
                store.dispatch("auth/login");
              },
              (error) => {
                toast.add({
                  severity: 'error',
                  summary: 'Failed',
                  detail: error.response.data.message || error.message,
                  life: 3000
                });
              },
          );
        }
      }
    },
    {immediate: true}
)

watch(
    () => currentUser,
    async (newCurrentUser) => {
      if (newCurrentUser) {
        user.value = newCurrentUser.value;
        await store.dispatch('user/fetchUserWallets')
      }
    },
    {immediate: true, deep: true}
)

const connectTwitter = () => {
  window.open(twitterSignUpUrl.value, 'noreferrer')
}

const disconnectTwitter = () => {
  UserService.disconnectTwitter()
      .then(
          response => {
            toast.add({
              severity: 'info',
              summary: 'Disconnected',
              detail: response.data.message,
              life: 3000
            });
            store.dispatch("auth/login");
          },
          error => {
            toast.add({
              severity: 'error',
              summary: 'Failed',
              detail: error.message,
              life: 3000
            });
          }
      )
}

const connectGoogle = () => {
  window.open(googleSignUpUrl.value, 'noreferrer')
}

const disconnectGoogle = () => {
  UserService.disconnectGoogle()
      .then(
          response => {
            toast.add({
              severity: 'info',
              summary: 'Disconnected',
              detail: response.data.message,
              life: 3000
            });
            store.dispatch("auth/login");
          },
          error => {
            toast.add({
              severity: 'error',
              summary: 'Failed',
              detail: error.message,
              life: 3000
            });
          }
      )
}

const deleteWallet = (walletId) => {
  UserService.deleteUserWallet(walletId).then(
      async response => {
        await store.dispatch('user/fetchUserWallets')
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      },
      error => {
        toast.add({
          severity: 'error',
          summary: error.response.data.message,
          detail: error.response.data.error,
          life: 3000
        });
      }
  )
}

</script>

<style scoped>.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>