<template>

  <small class="text-sm text-500 mt-2">
    List of collab managers that you can notify when you create a new listing
  </small>

  <DataTable v-model:selection="selectedList"
             :loading="loading"
             :value="userLists"
             class="p-datatable-sm mt-3"
             selectionMode="single"
             tableStyle="min-width: 100%">
    <template #empty>
      <p class="text-center text-500"> No list found. </p>
    </template>
    <Column field="name" header="Name">
      <template #body="{ data }">
        <span class="font-semibold capitalize">{{ data.name }}</span>
      </template>
    </Column>
    <Column field="description" header="Description">
      <template #body="{ data }">
        <span class="text-right font-lights">{{ data.description || '-' }}</span>
      </template>
    </Column>
    <Column field="members" header="Members">
      <template #body="{ data }">
        <span class="text-right font-lights white-space-nowrap">{{ data.listMembers.length }} members</span>
      </template>
    </Column>
    <Column>
      <template #body="{ data }">
        <div class="flex justify-content-end">
          <Button aria-controls="overlay_menu" aria-haspopup="true"
                  class="p-button-sm p-button-text p-button-plain"
                  icon="pi pi-ellipsis-v" type="button" @click="toggleOfferRowMenu($event, data.id)"/>
          <Menu id="overlay_menu" ref="outreachListRowMenu" :model="outreachListRowMenuItems" :popup="true"/>
        </div>
      </template>
    </Column>
    <!--    <Column>
          <template #body="{data}">
            <div class="flex flex-row justify-content-end gap-2">
              <Button v-tooltip.bottom="'Modify'"
                      class="p-button-sm p-button-outlined p-button-icon-only"
                      icon="pi pi-pencil"
                      @click.stop="() => router.push({name: 'edit-outreach-list', params: {id: data.id}})"/>
              <Button v-tooltip.bottom="'Delete'"
                      class="p-button-sm p-button-outlined p-button-icon-only p-button-danger"
                      icon="pi pi-trash"
                      @click.stop="deleteList(data.id)"/>
            </div>
          </template>
        </Column>-->
  </DataTable>

</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import store from "@/store";
import {useToast} from "primevue/usetoast";
import OutreachListService from "@/services/outreachList.service";
import router from "@/router";

const toast = useToast();

const selectedList = ref();

const currentOutreachListId = ref();
const outreachListRowMenu = ref();
const outreachListRowMenuItems = ref([
  {
    label: 'Edit outreach list',
    icon: 'pi pi-pencil',
    command: () => router.push({name: 'edit-outreach-list', params: {id: currentOutreachListId.value}})
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => {
      OutreachListService.deleteUserList(currentOutreachListId.value)
          .then(response => {
            toast.add({severity: 'success', summary: 'Deleted', detail: response.message, life: 3000});
            store.dispatch("outreachList/fetchOutreachLists");
          })
          .catch(error => {
            toast.add({
              severity: 'error',
              summary: 'Error',
              detail: error.message,
              life: 3000
            });
          })
    }
  },
]);

const toggleOfferRowMenu = (event, id) => {
  currentOutreachListId.value = id;
  outreachListRowMenu.value.toggle(event);
};

watch(
    () => selectedList,
    (newSelectedList) => {
      if (newSelectedList) router.push('/outreach-list/' + newSelectedList.value.id);
    },
    {deep: true}
);

onMounted(async () => await store.dispatch('outreachList/fetchOutreachLists'));

const userLists = computed(() => store.state.outreachList.userLists);
const loading = computed(() => store.state.outreachList.loading);
</script>

<style scoped>
</style>