import api from "@/services/api";

class RaffleService {
    getRaffleFromCollab(responseId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/raffle/${responseId}`)
            .then(response => response.data);
    }
    getRaffleWinners(raffleId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/raffle/${raffleId}/winners`)
            .then(response => response.data);
    }
    createRaffle(body, responseId) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/raffle/${responseId}`, body)
            .then(response => response.data);
    }
    updateRaffle(raffleId, body) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/raffle/${raffleId}`, body)
            .then(response => response.data);
    }
    deleteRaffle(raffleId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/raffle/${raffleId}`)
            .then(response => response.data);
    }
    createRaffleSpreadSheet(body) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/raffle/spreadsheet/${body.id}`, body)
            .then(response => response.data);
    }
}

export default new RaffleService();