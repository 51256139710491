<template>

  <div class="col-12 sm:col-6 md:col-6 lg:col-4">
    <div
        class="flex flex-column surface-100 border-round-xl cursor-pointer hover:surface-200 relative c-scale hover:shadow-3"
        @click.stop="$router.push(`/${offer.type}/${offer.project.name.replaceAll(' ', '-').toLowerCase()}/${offer.id}`)">
      <div
          :style="`background: url('${offer.project.banner}') no-repeat center center;`"
          class="flex flex-column justify-content-between border-round-xl card-background top-0 left-0 absolute w-full">
      </div>
      <div class="flex flex-row align-items-center justify-content-between relative px-3 h-3rem">
        <BlockchainTag :blockchain="offer.project.blockchain"/>
        <CardTag :value="getProjectTypeLabel(offer.project.type)"/>
      </div>
      <div class="relative flex justify-content-center mt-5">
        <img :src="offer.project.logo" alt="Project logo" class="border-round h-5rem w-5rem"/>
      </div>
      <div class="flex flex-row align-items-center justify-content-center gap-1">
        <p class="m-0 text-xl text-700 font-semibold text-center my-2 card-title">{{ offer.project.name }}</p>
        <VerifiedIcon :verified="offer.project.verified"/>
      </div>
      <div v-if="offer?.user"
           class="flex flex-row align-items-center justify-content-center gap-2 w-full pb-2 text-600"
           style="font-size: 0.8rem;">
        <span>Posted by</span>
        <div class="flex flex-row align-items-center gap-2">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${offer.user.discordId}/${offer.user.avatar}`" shape="circle"/>
          <span class="capitalize">{{ offer.user.username }}</span>
        </div>
      </div>
      <div v-if="offer.type === 'offering'"
           class="flex flex-row align-items-center justify-content-center gap-2 bg-cyan-400 border-round-bottom-xl mt-1">
        <span class="text-sm text-cyan-50">Offering whitelist</span>
      </div>
      <div v-else
           class="flex flex-row align-items-center justify-content-center gap-2 bg-purple-400 border-round-bottom-xl mt-1">
        <span class="text-sm text-purple-50">Requesting whitelist</span>
      </div>
    </div>

  </div>
</template>

<script setup>
import {defineProps} from "vue";
import BlockchainTag from "@/components/tags/BlockchainTag.vue";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import CardTag from "@/components/tags/CardTag.vue";
import {projectTypeList} from "@/utils/dropdownItems";

defineProps({
  offer: {
    type: Object,
    required: true,
  }
});

const getProjectTypeLabel = (type) => projectTypeList.value.filter(t => t.value === type)[0].label
</script>

<style scoped>
</style>