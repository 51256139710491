<template>
  <div class="col-12">
    <div
        class="flex flex-row surface-100 p-2 border-round-xl cursor-pointer justify-content-between align-items-center hover:shadow-2 flex-wrap-reverse md:flex-nowrap gap-2 c-scale hover:shadow-3"
        @click="$router.push(`/projects/${project.id}`)">

      <div class="p-0 flex flex-row gap-2 align-items-center flex-grow-1">
        <img :src="project.logo" alt="Project picture" class="border-round h-5rem w-auto"/>

        <div class="flex flex-column gap-2">
          <div class="flex flex-row align-items-center gap-1">
            <p class="m-0 text-xl text-600 font-semibold">{{ project.name }}</p>
            <VerifiedIcon :verified="project.verified"/>
            <i v-if="project.expiredAt"
               v-tooltip="`Unverified project. It will no longer be visible on ${$moment(project.expiredAt).format('lll')}.`"
               class="pi pi-exclamation-triangle text-orange-500 px-2"/>
          </div>
          <div  class="flex flex-row gap-2 justify-content-start w-full flex-wrap">
            <BlockchainTag v-if="project.blockchain" :blockchain="project.blockchain"/>
            <CardTag :value="getProjectTypeLabel(project.type)"/>
            <CardTag v-if="project.averageEntries > 0" :value="project.averageEntries" label="entries"/>
            <ListingTypeCard v-for="(offer, offerIdx) of project.offers" :key="`${project.name}Offer${offerIdx}`"
                             :type="offer.type"/>
          </div>
        </div>
      </div>

      <div class="flex flex-grow-0 sm:flex-grow-1">
        <CardSocialButtons :discord="project.discord"
                           :twitter="project.twitter"
                           :opensea="project.opensea"
                           :website="project.website"
                           :is-row="true"
                           class="w-full hidden-mobile"/>
      </div>

    </div>
  </div>
</template>

<script setup>
import CardSocialButtons from "@/components/buttons/CardSocialButtons.vue";
import BlockchainTag from "@/components/tags/BlockchainTag.vue";
import CardTag from "@/components/tags/CardTag.vue";
import {defineProps} from 'vue';
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import ListingTypeCard from "@/components/tags/ListingTypeCard.vue";
import {projectTypeList} from "@/utils/dropdownItems";

defineProps({
  project: {
    type: Object,
    required: true
  }
});

const getProjectTypeLabel = (type) =>   projectTypeList.value.filter(t => t.value === type)[0].label
</script>

<style scoped>

</style>