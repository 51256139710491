<template>

  <footer v-if="navbarDisplayed" class="flex justify-content-center px-1 md:px-4 py-3">
    <div class="surface-section p-2 container w-full">
      <div class="pt-6 flex flex-column sm:flex-row sm:align-items-start justify-content-between">

        <div class="flex flex-row align-items-end gap-2">
          <div class="flex flex-column gap-2 align-items-start">
            <img alt="IND3X logo" height="36" src="../../assets/images/logo.svg">
            <small class="m-0 line-height-3 text-600">© 2023 IND3X, Inc. All rights reserved</small>
          </div>
          <router-link to="/privacy-policy">
            <Button class="link p-button-xs p-button-text p-button-plain" label="Privacy Policy" style="font-size: 0.7rem "/>
          </router-link>
        </div>

        <div class="mt-3 sm:mt-0 flex flex-row gap-2 align-items-center">
          <Button
              class="p-button-sm p-button p-button-text p-button-rounded p-button-icon-only p-button-plain"
              style="height: 2.5rem; width: 2.5rem;"
              @click.stop="openExternalLink('https://x.com/ind3xapp')">
            <XIcon/>
          </Button>
          <Button
              class="p-button-sm p-button p-button-text p-button-rounded p-button-icon-only p-button-plain"
              style="height: 2.5rem; width: 2.5rem;"
              icon="pi pi-discord"
              @click.stop="openExternalLink('https://discord.gg/DMzy3r9jR6')"/>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
import XIcon from "@/components/icons/XIcon.vue";
import {openExternalLink} from "@/utils/openLink";

export default {
  name: "NavBar",
  methods: {openExternalLink},
  components: {XIcon},
  data() {
    return {
      hiddenPages: ["/connect", "/signup/", "/signup", "/signup/additional"],
      navbarDisplayed: true,
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.navbarDisplayed = !this.hiddenPages.includes(route["path"]);
      },
      deep: true,
      immediate: true,
    }
  },
}
</script>

<style scoped>

</style>