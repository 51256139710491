<template>
  <Tag :class="`font-light capitalize ml-2`"
       :style="{backgroundColor: color}"
       :value="role.replaceAll('_', ' ')"
       class="white-space-nowrap"
       style="backdrop-filter: blur(3px); font-size: 0.7rem;"/>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';

const props = defineProps(['role'])
const color = ref("")

watch(
    () => props.role,
    (role) => {
      if (role === "owner") {
        color.value = "rgba(53,196,220,0.5)";
      }
      if (role === "admin") {
        color.value = "rgba(250,142,66,0.5)";
      }
      if (role === "collab_manager") {
        color.value = "rgba(185,117,249,0.5)";
      }
    },
    {immediate: true}
)
</script>

<style scoped>

</style>