<template>
  <DataTable v-model:expandedRows="expandedRows"
             :loading="loading"
             :value="userOffers"
             class="p-datatable-sm mt-3 max-w-screen"
             tableStyle="min-width: 100%"
             @rowExpand="onRowExpand">
    <template #empty>
      <p class="text-center text-500"> No listing found. </p>
    </template>
    <Column expander style="width: 2rem"/>
    <Column field="name" header="Offer">
      <template #body="{ data }">
        <Button :label="`Listing ${data.id}`"
                class="p-button-sm p-button-link capitalize m-0 p-0"
                @click="router.push(`/${data.type}/${data.project.name.replaceAll(' ', '-').toLowerCase()}/${data.id}`)"/>
      </template>
    </Column>
    <Column field="name" header="Project">
      <template #body="{ data }">
        <div class="flex flex-row align-items-center gap-2">
          <Avatar :image="data.project.logo"
                  shape="circle"/>
          <Button :label="data.project.name"
                  class="p-button-sm p-button-link capitalize m-0 p-0"
                  @click="router.push(`/projects/${data.project.id}`)"/>
          <VerifiedIcon :verified="data.project.verified"/>
        </div>
      </template>
    </Column>
    <Column field="collabManager" header="Author">
      <template #body="{ data }">
        <div class="flex flex-row gap-2 align-items-center">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${data.user.discordId}/${data.user.discordAvatar}`"
                  shape="circle"/>
          <Button :label="data.user.username"
                  class="p-button-sm p-button-link capitalize m-0 p-0"
                  @click="router.push(`/collab-managers/${data.user.id}`)"/>
        </div>
      </template>
    </Column>
    <Column field="type" header="Type">
      <template #body="{ data }">
        <Tag v-if="data.type === 'offering'" :value="data.type"
             class="capitalize bg-cyan-400 text-cyan-50"/>
        <Tag v-else :value="data.type" class="capitalize bg-purple-400 text-purple-50"/>
      </template>
    </Column>
    <Column field="expire" header="Expire">
      <template #body="{ data }">
        <p v-if="$moment(data.expirationDate).diff($moment(), 'days') === 0" class="m-0 text-red-500">Expire today</p>
        <p v-else-if="$moment(data.expirationDate).diff($moment(), 'days') > 0"
           :class="{'text-orange-500': $moment(data.expirationDate).diff($moment(), 'days') < 3 }"
           class="m-0">
          {{ $moment(data.expirationDate).diff($moment(), 'days') }} days
        </p>
        <p v-else class="m-0 text-red-500">Expired</p>
      </template>
    </Column>
    <Column>
      <template #body="{ data }">
        <div class="flex justify-content-end align-items-center">
          <Badge v-if="data.offerResponses.filter(or => or.status === 'created').length > 0"
                 :value="data.offerResponses.filter(or => or.status === 'created').length" severity="warning"/>
          <Button aria-controls="overlay_menu" aria-haspopup="true"
                  class="p-button-sm p-button-text p-button-plain"
                  icon="pi pi-ellipsis-v" type="button" @click="toggleOfferRowMenu($event, data.id)"/>
          <Menu id="overlay_menu" ref="offerRowMenu" :model="offerRowMenuItems" :popup="true"/>
        </div>
      </template>
    </Column>
    <template #expansion="{data}">
      <DataTable :value="data.waitingResponses" class="p-datatable-sm w-full">
        <template #empty>
          <p class="text-center w-full text-500"> No pending responses </p>
        </template>
        <Column field="project" header="Project">
          <template #body="{ data }">
            <div class="flex flex-row gap-1 align-items-center">
              <Avatar :image="data.project.logo" shape="circle" style="min-width: 2rem;"/>
              <Button :label="data.project.name"
                      class="p-button-sm p-button-link capitalize m-0 p-0"
                      @click="router.push(`/projects/${data.offer.project.id}`)"/>
              <VerifiedIcon :verified="data.project.verified"/>
              <Button
                  v-tooltip.top="'Go to X'"
                  class="p-button-sm p-button-link p-0"
                  @click="openExternalLink(`https://x.com/${data.project.twitter}`)">
                <XIcon class="relative"/>
              </Button>
            </div>
          </template>
        </Column>
        <Column field="contact" header="Contact">
          <template #body="{ data }">
            <div class="flex flex-row gap-1 align-items-center">
              <Avatar :image="`https://cdn.discordapp.com/avatars/${data.creator.discordId}/${data.creator.avatar}`"
                      shape="circle"
                      style="min-width: 2rem;"/>
              <Button :label="data.creator.username"
                      class="p-button-sm p-button-link capitalize m-0 p-0"
                      @click="router.push(`/collab-managers/${data.creator.id}`)"/>
              <Button v-tooltip.top="'Copy username'" class="p-button-sm p-button-link p-1"
                      @click="copyToClipBoard(data.creator.discordUsername)">
                <i class="pi pi-discord"/>
              </Button>
              <Button v-if="data.creator.twitterUsername"
                      v-tooltip.top="'Go to X'"
                      class="p-button-sm p-button-link p-1"
                      @click="openExternalLink(`https://x.com/${data.creator.twitterUsername}`)">
                <XIcon/>
              </Button>
            </div>
          </template>
        </Column>
        <Column field="information" header="Information">
          <template #body="{ data }">
            <div class="flex flex-row gap-1">
              <AddResponseRequirements :response-id="data.id" :response-requirements="data.requirements"/>
              <AddResponseTemplate :response-id="data.id" :response-template="data.template"/>
            </div>
          </template>
        </Column>
        <Column field="spots" header="Requested spots">
          <template #body="{ data }">
            <p class="m-0 text-sm">{{ data.spots }} spots</p>
          </template>
        </Column>
        <Column header="Proposal">
          <template #body="{ data }">
            <p v-if="data.proposeThreads" class="m-0 text-sm">Threads</p>
            <p v-if="data.proposeRaids" class="m-0 text-sm">Raids</p>
            <p v-if="data.proposeAma" class="m-0 text-sm">Ama</p>
            <p v-if="data.proposeOther" class="m-0 text-sm">Other: {{ data.proposeOtherInput }}</p>
          </template>
        </Column>
        <Column header=" ">
          <template #body="{data}">
            <Button class="p-button-sm p-button-success p-button-text" icon="pi pi-check"
                    @click="acceptOffer(data)"/>
            <Button class="p-button-sm p-button-danger p-button-text" icon="pi pi-times"
                    @click="declineOffer(data)"/>
          </template>
        </Column>
      </DataTable>
    </template>
  </DataTable>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import store from "@/store";
import router from "@/router";
import OfferService from "@/services/offer.service";
import {useToast} from "primevue/usetoast";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import {useConfirm} from "primevue/useconfirm";
import OfferResponseService from "@/services/offerResponse.service";
import XIcon from "@/components/icons/XIcon.vue";
import AddResponseRequirements from "@/components/modals/Collab/AddCollabRequirements.vue";
import AddResponseTemplate from "@/components/modals/Collab/AddCollabTemplate.vue";
import {openExternalLink} from "@/utils/openLink";

const toast = useToast()
const confirm = useConfirm()

const expandedRows = ref([]);
const currentOfferId = ref();
const offerRowMenu = ref();
const offerRowMenuItems = ref([
  {
    label: 'Edit listing',
    icon: 'pi pi-pencil',
    command: () => router.push({name: 'edit-offer', params: {id: currentOfferId.value}})
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: () => {
      confirm.require({
        group: 'deleteConfirm',
        position: 'bottom',
        header: 'Deletion confirmation',
        message: 'Are you sure you want to delete this listing ? All current and past collaborations will be deleted.',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-sm p-button-danger',
        acceptLabel: 'Delete',
        rejectClass: 'p-button-sm p-button-plain p-button-text',
        rejectLabel: 'Cancel',
        accept: () => {
          OfferService.deleteOffer(currentOfferId.value)
              .then(
                  response => {
                    toast.add({severity: 'success', summary: 'Deleted', detail: response.message, life: 3000});
                    store.dispatch('user/fetchUserOffers');
                  },
                  error => {
                    toast.add({
                      severity: 'error',
                      summary: 'Failed',
                      detail: error.response.data.message || error.message,
                      life: 3000
                    });
                  }
              )
        },
      });
      event.stopPropagation();
    }
  },
]);

const toggleOfferRowMenu = (event, id) => {
  currentOfferId.value = id;
  offerRowMenu.value.toggle(event);
};

onMounted(() => store.dispatch('user/fetchUserOffers'));

const userOffers = computed(() => store.state.user.userOffers);
const loading = computed(() => store.state.user.loading);

const onRowExpand = async (event) => {
  currentOfferId.value = event.data.id;
  const waitingResponses = await store.dispatch('offerResponse/fetchWaitingOfferResponses', event.data.id);
  await store.commit('user/fetchWaitingUserOfferResponseSuccess', {
    id: event.data.id,
    waitingResponses: waitingResponses
  })
}

const acceptOffer = (response) => {
  OfferResponseService.updateOfferResponse(response.id, {status: 'pending'})
      .then(async () => {
        const waitingResponses = await store.dispatch('offerResponse/fetchWaitingOfferResponses', currentOfferId.value);
        await store.commit('user/fetchWaitingUserOfferResponseSuccess', {
          id: currentOfferId.value,
          waitingResponses: waitingResponses
        })

        let message = `You've now accepted an offer from <span class="font-semibold">${response.creator.username}</span>, please send them a message on Discord with their username: <span class="font-semibold">${response.creator.discordUsername}</span>`;
        message += response.creator.twitterUsername ? ` or on Twitter with their handle <span class="font-semibold">@${response.creator.twitterUsername}</span> to finalize the deal.` : ' to finalize the deal.';

        confirm.require({
          group: 'sendMessageConfirm',
          position: 'center',
          header: 'Offer accepted',
          message: message,
          icon: 'pi pi-check',
          acceptClass: 'p-button-sm',
          acceptLabel: 'Ok',
          rejectLabel: ' ',
        });
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message || error.message,
          life: 3000
        });
      });
}

const declineOffer = (response) => {
  OfferResponseService.updateOfferResponse(response.id, {status: 'declined'})
      .then(async () => {
        const waitingResponses = await store.dispatch('offerResponse/fetchWaitingOfferResponses', currentOfferId.value);
        await store.commit('user/fetchWaitingUserOfferResponseSuccess', {
          id: currentOfferId.value,
          waitingResponses: waitingResponses
        })
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message || error.message,
          life: 3000
        });
      });
}

const copyToClipBoard = async (text) => {
  await navigator.clipboard.writeText(text);
  toast.add({severity: 'info', summary: 'Copied', detail: "Address copied to clipboard", life: 3000});
}
</script>

<style scoped>
</style>