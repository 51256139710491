import api from "@/services/api";

class ProjectService {
    getProjects(page, size, query, orderBy, orderSort, minted, ama, avgEntries, blockchains, verified, types) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/projects?page=${page}&size=${size}&orderBy=${orderBy}&orderSort=${orderSort}&query=${query}&avgEntries=${avgEntries}&minted=${minted}&ama=${ama}&blockchains=${blockchains.toString()}&verified=${verified}&types=${types.toString()}`)
            .then(response => response.data);
    }
    getProject(projectId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/projects/${projectId}`)
            .then(response => response.data);
    }
    getProjectMembers(projectId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/projects/members/${projectId}`)
            .then(response => response.data);
    }
    getProjectMembersWithWallets(projectId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/projects/members-wallets/${projectId}`)
            .then(response => response.data);
    }
    createProject(project) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/projects`, project)
            .then(response => response.data);
    }
    createProjectSpreadsheet(projectId) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/projects/${projectId}/spreadsheet`)
            .then(response => response.data);
    }
    getProjectSpreadsheet(projectId, spreadsheetId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/projects/${projectId}/spreadsheet/${spreadsheetId}`)
            .then(response => response.data);
    }
    updateProjectSpreadsheet(projectId, spreadsheetId, body) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/projects/${projectId}/spreadsheet/${spreadsheetId}`, body)
            .then(response => response.data);
    }
    synchronizeProjectSpreadsheet(projectId, spreadsheetId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/projects/${projectId}/spreadsheet/${spreadsheetId}/synchronize`)
            .then(response => response.data);
    }
    importMembersWallet(projectId, spreadsheetId, membersWallet) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/projects/${projectId}/spreadsheet/${spreadsheetId}/members-wallets`, membersWallet)
            .then(response => response.data);
    }
    addProjectMember(projectId, member) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/projects/members/${projectId}`, member)
            .then(response => response.data);
    }
    updateProjectMember(projectId, member) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/projects/members/${projectId}`, member)
            .then(response => response.data);
    }
    deleteProjectMember(projectId, memberId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/projects/members/${projectId}/${memberId}`)
            .then(response => response.data);
    }
    addProjectMembersOld(projectId, members) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/projects/${projectId}/members`, members)
            .then(response => response.data);
    }
    updateProject(body, projectId) {
        return api
            .patch(`${process.env.VUE_APP_API_URL}/projects/${projectId}`, body)
            .then(response => response.data);
    }
    deleteProject(projectId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/projects/${projectId}`)
            .then(response => response.data);
    }
}

export default new ProjectService();