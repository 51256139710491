<template>
  <div class="flex flex-column gap-2 flex-wrap px-1 md:px-4 align-items-center">

    <form class="w-full flex flex-row flex-wrap justify-content-between" style="max-width: 900px;"
          @submit.prevent="handleSubmitNewOutreachList">

      <BackButton/>

      <h1 class="m-0 md:my-3 text-700 w-full">Create new outreach list</h1>

      <!-- INFORMATION -->
      <h2 class="m-0text-700 mb-2 text-lg w-full">Information</h2>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="name">
          <RequiredDot/>
          Name
        </label>
        <InputText id="username"
                   v-model="newOutreachListForm.name"
                   :class="{'p-invalid': submitted && newOutreachListForm.name === ''}"
                   aria-describedby="name-help"
                   class="p-inputtext-sm"/>
        <small v-if="submitted && newOutreachListForm.name === ''" id="name-help" class="p-error">
          Name is required.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="description"> Description</label>
        <Textarea id="description"
                  v-model="newOutreachListForm.description"
                  aria-describedby="description-help"
                  rows="6"/>
        <small :class="{'text-red-500': submitted && newOutreachListForm.description.length > 255}"
               class="text-right text-600">
          {{ newOutreachListForm.description.length }} / 255
        </small>
        <small v-if="submitted && newOutreachListForm.description.length > 255" id="description-help" class="p-error">
          Description must be lower than 255 characters.
        </small>
      </div>

      <!-- USERS -->
      <div class="flex flex-row justify-content-between align-items-center flex-wrap mt-4 mb-2 w-full">
        <h2 class="m-0 text-700 text-lg">Users</h2>
        <SearchUsersModal/>
      </div>

      <EmptySection v-if="newOutreachListForm.members.length <= 0"
                    icon="pi pi-users" text="No members added"/>

      <ListMembersVisualization :members="newOutreachListForm.members"/>

      <!-- ACTIONS -->
      <div class="col-12 md:px-2 p-0 flex flex-row gap-3 mt-4 justify-content-end">
        <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="$router.go(-1)"/>
        <Button :loading="loading" class="p-button-sm" icon="pi pi-check" label="Save outreach list" type="submit"/>
      </div>

    </form>

  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import BackButton from "@/components/buttons/BackButton.vue";
import RequiredDot from "@/components/RequiredDot.vue";
import UserListService from "@/services/outreachList.service";
import {useToast} from "primevue/usetoast";
import store from "@/store";
import router from "@/router";
import SearchUsersModal from "@/components/modals/SearchUsersModal.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import ListMembersVisualization from "@/components/ListVisualization/ListMembersVisualization.vue";

const toast = useToast();
const submitted = ref(false);
const loading = ref(false);
const newOutreachListForm = ref({
  name: "",
  description: "",
  members: [],
});

onMounted(() => store.dispatch("outreachList/resetListMembers"))

watch(
    () => store.state.outreachList.listMembers,
    (newMembers) => newOutreachListForm.value.members = newMembers,
    {immediate: true}
)

const handleSubmitNewOutreachList = async () => {
  submitted.value = true;

  if (newOutreachListForm.value.name === "" || newOutreachListForm.value.description.length >= 255) return;

  loading.value = true;

  await UserListService.createUserList(newOutreachListForm.value)
      .then(response => {
        toast.add({severity: 'success', summary: 'Deleted', detail: response.message, life: 3000});
        store.dispatch("outreachList/fetchOutreachLists");
        store.dispatch("outreachList/resetListMembers");
        router.push('/dashboard/outreach-list')
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message,
          life: 3000
        });
      });

  loading.value = false;
}
</script>

<style scoped>

</style>