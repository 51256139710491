import ProjectService from "@/services/project.service";
import UserService from "@/services/user.service";

const initialState = {
    projects: [],
    projectMembers: [],
    usersFound: [],
    project: null,
    loading: false,
    error: null,
    totalPages: 0,
};
export const project = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchProjects({commit, state}, payload = {
            page: Number,
            size: Number,
            query: String,
            blockchains: Array,
            minted: Boolean,
            ama: String,
            order: Object,
            verified: Boolean,
            avgEntries: String,
            types: Array,
        }) {
            state.loading = true;
            state.error = "";
            const {
                page = 0,
                size = 20,
                query = "",
                types = [],
                blockchains = [],
                minted = null,
                ama = null,
                avgEntries = null,
                verified = false,
                order = {label: "most recent", by: "createdAt", sort: "desc"}
            } = payload;
            if (page === 0) state.projects = [];
            return ProjectService.getProjects(page, size, query, order.by, order.sort, minted, ama, avgEntries, blockchains, verified, types).then(
                response => {
                    state.totalPages = response.totalPages;
                    const results = [...state.projects, ...response.items];
                    commit('fetchProjectsSuccess', results)
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        fetchProject({commit, state}, projectId) {
            state.project = null;
            state.loading = true;
            state.error = "";
            return ProjectService.getProject(projectId).then(
                response => {
                    commit('fetchProjectSuccess', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        fetchProjectMembers({commit, state}, projectId) {
            state.project = null;
            state.loading = true;
            state.error = "";
            return ProjectService.getProjectMembers(projectId).then(
                response => {
                    commit('fetchProjectMembersSuccess', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        fetchProjectMembersWithWallets({commit, state}, projectId) {
            state.project = null;
            state.loading = true;
            state.error = "";
            return ProjectService.getProjectMembersWithWallets(projectId).then(
                response => {
                    commit('fetchProjectMembersSuccess', response);
                    return Promise.resolve(response);
                },
                error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                }
            );
        },
        fetchSearchUsers({commit}, query) {
            UserService.searchUsers(query)
                .then(
                    response => {
                        commit('fetchSearchUsersSuccess', response.data);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    });
        },
        async addProjectMember({commit, state}, {projectId, member}) {
            return await ProjectService.addProjectMember(projectId, member)
                .then(
                    response => {
                        state.loading = false;
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    }
                )
        },
        async removeProjectMember({commit}, {projectId, memberId}) {
            return await ProjectService.deleteProjectMember(projectId, memberId)
                .then(
                    response => {
                        commit('removeMemberSuccess', response.memberId);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    }
                )
        },
    },
    mutations: {
        fetchProjectsSuccess(state, projects) {
            state.projects = projects;
            state.loading = false;
        },
        fetchProjectSuccess(state, project) {
            state.project = project;
            state.loading = false;
        },
        fetchProjectMembersSuccess(state, members) {
            state.projectMembers = members;
            state.loading = false;
        },
        removeMemberSuccess(state, memberId) {
            state.projectMembers = state.projectMembers.filter(m => m.userId !== memberId)
            state.loading = false;
        },
        fetchFailure(state, error) {
            state.projects = [];
            state.project = null;
            state.loading = false;
            state.error = error.response?.data?.message || error.message;
        },
        fetchSearchUsersSuccess(state, users) {
            state.loading = false;
            state.usersFound = users;
        },
    }
}