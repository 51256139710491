import api from "@/services/api";
import TokenService from "@/services/token.service";

class AuthService {
    login() {
        return api
            .get(`${process.env.VUE_APP_API_URL}/auth/signin`)
            .then(response => {
                if (response.data.user['accessToken']) TokenService.setUser(response.data.user);
                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();
    }
}

export default new AuthService();