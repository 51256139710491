<template>

  <Loader v-if="loading"/>

  <EmptySection v-else-if="!loading && projects.length === 0" icon="pi pi-folder" text="No project found."/>

  <div v-else-if="selectedProject" class="grid relative flex max-w-screen">

    <div class="col-2 hidden-tablet flex flex-column gap-2">
      <div v-for="project of projects" :key="`project${project.id}`"
           :class="{'bg-primary': project.id === selectedProject.id}"
           class="border-round-lg px-2 py-2 surface-100 flex flex-row align-items-center justify-content-between border-2 border-transparent hover:border-primary cursor-pointer"
           @click="handleSelectProject(project)">
        <div class="flex flex-row align-items-center gap-2 w-full relative" style="max-width: 90%;">
          <Avatar :image="project.logo" shape="circle" style="min-width: 2rem;"/>
          <p class="m-0 cropped text-sm" style="width: 100%;">{{ project.name }}</p>
          <div v-if="project.endedCollabsCount > 0">
            <Badge :value="project.endedCollabsCount" severity="warning"></Badge>
          </div>
        </div>
        <i class="pi pi-angle-right"/>
      </div>
    </div>

    <div class="col-12 hidden-desktop">
      <Dropdown v-model="selectedProject" :options="projects" class="w-full"
                @change="handleSelectProject(selectedProject)">
        <template #value="slotProps">
          <div v-if="slotProps" class="flex flex-row align-items-center gap-2">
            <Avatar :image="slotProps.value.logo" shape="circle"/>
            <p class="m-0">{{ slotProps.value.name }}</p>
            <div v-if="slotProps.value.endedCollabsCount > 0">
              <Badge :value="slotProps.value.endedCollabsCount" severity="warning"></Badge>
            </div>
          </div>
        </template>
        <template #option="slotProps">
          <div class="flex flex-row align-items-center gap-2">
            <Avatar :image="slotProps.option.logo" shape="circle"/>
            <p class="m-0">{{ slotProps.option.name }}</p>
            <div v-if="slotProps.option.endedCollabsCount > 0">
              <Badge :value="slotProps.option.endedCollabsCount" severity="warning"></Badge>
            </div>
          </div>
        </template>
      </Dropdown>
    </div>

    <div class="col-12 lg:col-10 flex flex-column">
      <div class="flex flex-row">
        <TabMenu v-model:activeIndex="activeTab"
                 :model="projectResponseTabs"
                 :scrollable="true"
                 class="w-full"
                 style="max-width: 99vw;"/>
        <div class="flex flex-row gap-2 align-items-center">
          <Button v-if="selectedProject.spreadsheet"
                  class="p-button-text p-button-icon p-button-sm p-button-success hover:surface-300"
                  icon="pi pi-file-excel"
                  @click.stop="openExternalLink(selectedProject.spreadsheet)"/>
        </div>
      </div>

      <CollaborationVisualization :collab-type="projectResponseTabs[activeTab].value" :project-id="selectedProject.id"/>

    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import store from "@/store";
import Loader from "@/components/sections/Loader.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import {openExternalLink} from "@/utils/openLink";
import CollaborationVisualization from "@/components/ListVisualization/CollaborationVisualization.vue";

const projectResponseTabs = ref([
  {label: 'Inbound collabs', value: "inbound"},
  {label: 'Outbound collabs', value: "outbound"},
  {label: 'Archived inbound collabs', value: "archived_inbound"},
  {label: 'Archived outbound collabs', value: "archived_outbound"},
]);
const selectedProject = ref(null);
const activeTab = ref(0);
const projects = computed(() => store.state.user.userProjects);
const loading = computed(() => store.state.user.loading);

const props = defineProps({
  projectId: {type: String},
  type: {type: String},
  responseId: {type: String},
})

onMounted(async () => {
  await store.dispatch('user/fetchUserProjects');

  if (projects.value.length > 0) {
    selectedProject.value = projects.value[0];
    await store.dispatch('offerResponse/fetchOfferResponses', selectedProject.value.id);
    activeTab.value = props.type === 'sent' ? 1 : 0;
    const row = document.getElementById(props.responseId);
    if (row) row.scrollIntoView();
  }
})

watch(
    () => props,
    async (newProps) => {
      if (newProps) {
        // TODO : reactive url
        selectedProject.value = await projects.value.find(p => p.id === newProps.projectId);

        if (selectedProject.value) {
          await store.dispatch('offerResponse/fetchOfferResponses', selectedProject.value.id);

          if (newProps.type === "inbound") {
            activeTab.value = 0;
          } else if (newProps.type === "outbound") {
            activeTab.value = 1;
          } else if (newProps.type === "archived_inbound") {
            activeTab.value = 2;
          } else if (newProps.type === "archived_outbound") {
            activeTab.value = 3;
          }

          const row = document.getElementById(props.responseId);
          if (row) row.scrollIntoView();
        }
      }
    },
    {immediate: true}
)

// const toggleProjectMenu = (event) => projectMenu.value.toggle(event);

const handleSelectProject = async (project) => {
  selectedProject.value = project;
  await store.dispatch('offerResponse/fetchOfferResponses', selectedProject.value.id);
};
</script>

<style lang="scss" scoped>
</style>