<template>

  <!--DESKTOP-->
  <div class="flex justify-content-center">
    <nav
        class="flex flex-row align-items-center justify-content-between p-2 px-4 py-4 w-full flex-wrap hidden-mobile container">

      <div class="flex flex-row flex-wrap align-items-center gap-2">
        <router-link to="/">
          <img alt="IND3X logo icon" height="40" src="../../assets/images/logo.svg"/>
        </router-link>

        <router-link v-for="item of publicPathItems" :key="item.label" :to="item.path">
          <Button :class="getActiveClasses(item.path)"
                  :label="item.label"
                  class="p-button-text p-button-plain border-round p-button-sm border-2 capitalize"/>
        </router-link>
      </div>

      <div class="flex flex-row flex-wrap align-items-center gap-3">

        <template v-if="currentUser">

          <router-link to="/dashboard">
            <Button class="p-button-outlined border-round p-button-sm border-2"
                    label="Dashboard"/>
          </router-link>

          <NotificationPanel/>

          <Avatar v-if="currentUser"
                  :image="`https://cdn.discordapp.com/avatars/${currentUser.discordId}/${currentUser.avatar}`"
                  aria-controls="overlay_menu"
                  aria-haspopup="true"
                  class="cursor-pointer h-2rem w-2rem"
                  shape="circle"
                  size="large"
                  @click="toggle"/>

          <OverlayPanel ref="menu">
            <div class="flex flex-column gap-2 p-2" style="min-width: 12rem;">
              <router-link to="/profile">
                <Button class="p-button-text p-picklist-buttons text-left p-button-plain w-full" icon="pi pi-user"
                        label="Profile"/>
              </router-link>
              <router-link to="/settings">
                <Button class="p-button-text p-picklist-buttons text-left p-button-plain w-full" icon="pi pi-cog"
                        label="Settings"/>
              </router-link>
              <Divider class="m-0"/>
              <Button class="p-button-text p-picklist-buttons text-left p-button-danger w-full" icon="pi pi-sign-out"
                      label="Disconnect" @click="logOut"/>
            </div>
          </OverlayPanel>

        </template>

        <a v-else
           :href="discordLoginUrl"
           class="p-button p-button-text hover:bg-primary font-semibold border-round p-button-sm flex gap-2">
          <p class="m-0 w-full">Connect</p>
          <i class="pi pi-arrow-up-right"/>
        </a>

      </div>
    </nav>
  </div>

  <!--MOBILE-->
  <div class="flex flex-rw align-items-center justify-content-between p-1 md:py-2 md:px-3 w-full hidden-desktop">

    <img alt="IND3X logo" height="40" src="@/assets/images/logo.svg" @click="goToPage('/')"/>

    <div class="flex flex-row gap-2 align-items-center">
      <NotificationPanel/>
      <Button class="p-button-icon-only p-button-plain p-button-text"
              icon="pi pi-bars"
              @click="visibleMobileMenu = true"/>
    </div>

    <Dialog v-model:visible="visibleMobileMenu" :style="{ width: '100vw', height: '10vh' }"
            class="border-round p-dialog-maximized"
            modal>
      <template #header>
        <img alt="Logo" height="40" src="@/assets/images/logo.svg"/>
      </template>
      <div class="flex flex-column p-2 gap-2">

        <router-link v-for="item of publicPathItems" :key="item.label" :to="item.path">
          <Button :class="getActiveClasses(item.path)"
                  :label="item.label"
                  class="p-button-text p-button-plain border-round border-2 capitalize w-full text-left"
                  @click="goToPage(item.path)"/>
        </router-link>

        <template v-if="currentUser">

          <Divider class="m-1"/>

          <Button class="p-button-text p-button-plain border-round w-full text-left border-2"
                  label="Dashboard"
                  @click="goToPage('/dashboard')"/>

          <Button class="p-button-text p-button-plain border-round w-full text-left"
                  label="Profile"
                  @click="goToPage('/profile')"/>

          <Button class="p-button-text p-button-plain border-round w-full text-left"
                  label="Settings"
                  @click="goToPage('/settings')"/>

          <Divider class="m-1"/>

          <Button class="p-button-text p-button-danger w-full text-left" icon="pi pi-sign-out" label="Disconnect"
                  @click="logOut"/>

        </template>

        <template v-else>

          <Divider class="p-0 m-0"/>

          <a :href="discordLoginUrl"
             class="p-button border-round p-button-sm flex gap-2">
            <p class="m-0 w-full">Connect</p>
            <i class="pi pi-arrow-up-right"/>
          </a>
        </template>

      </div>
      <template #footer>
        <div class="flex flex-row gap-2 justify-content-end">
          <a class="cursor-pointer text-500 transition-colors transition-duration-150 hover:text-700"
             href="https://x.com/ind3xapp" target="_blank">
            <XIcon/>
          </a>
          <a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700"
             href="https://discord.gg/DMzy3r9jR6" target="_blank">
            <i class="pi pi-discord text-xl"></i>
          </a>
        </div>
      </template>
    </Dialog>

  </div>

</template>

<script>
import {openExternalLink} from "@/utils/openLink";
import NotificationPanel from "@/components/NotificationPanel.vue";
import store from "@/store";
import router from "../../router";
import XIcon from "@/components/icons/XIcon.vue";


export default {
  name: "NavBar",
  components: {XIcon, NotificationPanel},
  data() {
    return {
      visibleMobileMenu: false,
      pendingResponses: 0,
      discordLoginUrl: process.env.VUE_APP_DISCORD_LOGIN_URL,
      publicPathItems: [
        {label: "listings", path: "/listings"},
        {label: "projects", path: "/projects"},
        {label: "managers", path: "/collab-managers"},
      ]
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    unreadNotifications() {
      return store.state.notification.unreadNotifications
    }
  },
  watch: {
    unreadNotifications: {
      handler(unreadNotifications) {
        this.unreadNotifications = unreadNotifications;
      },
      deep: true, immediate: true
    }
  },
  methods: {
    getActiveClasses(path) {
      if (!this.$route.path.includes('dashboard') && this.$route.path.includes(path)) return 'border-primary text-primary'
    },
    router() {
      return router
    },
    openExternalLink,
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    },
    goToPage(path) {
      this.$router.push(path);
      this.visibleMobileMenu = false;
    },
  }
}
</script>

<style>
.router-link-active * {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-weight: 700 !important;
}
</style>