<template>
  <svg fill="currentColor" height="16" style="position: absolute;" viewBox="0 0 24 24" width="16"
       x="0px"
       xmlns="http://www.w3.org/2000/svg" y="0px">
    <path d="M14.23,10.16L22.98,0H20.9l-7.59,8.82L7.25,0H0.26l9.17,13.34L0.26,24h2.07l8.02-9.32l6.4,9.32h6.99
	L14.23,10.16L14.23,10.16z M11.4,13.46l-0.93-1.33L3.08,1.56h3.18l5.96,8.53l0.93,1.33l7.75,11.09h-3.18L11.4,13.46L11.4,13.46z"/>
  </svg>
</template>

<script setup>
</script>

<style scoped>
</style>