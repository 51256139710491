<template>
  <div class="flex flex-column gap-2 flex-wrap">

    <div class="flex flex-row flex-wrap justify-content-between align-items-center pr-1">

      <h1 class="title">Dashboard</h1>

      <Button aria-controls="overlay_menu" aria-haspopup="true"
              class="p-button-sm surface-50 hover:surface-200 p-button-text"
              icon="pi pi-plus" label="New" @click="toggle"/>
      <Menu id="overlay_menu" ref="optionMenu" :model="optionMenuItems" :popup="true"/>
    </div>

    <TabMenu v-model:activeIndex="activeTab" :model="dashboardTabs"
             :scrollable="true"             class="mb-2 max-w-screen">
      <template #item="{ item}">
        <router-link v-if="item.to" v-slot="routerProps" :to="item.to" custom>
          <div class="p-menuitem-link flex flex-row align-items-center flex-nowrap h-fit"
               @click="router.push(routerProps.href)">
            <span v-if="item.label==='Collaborations' && endedCollabs" v-badge.warning="endedCollabs">
              {{ item.label }}
            </span>
            <span v-else-if="item.label==='Listings' && waitingCollabCounter" v-badge.warning="waitingCollabCounter">
              {{ item.label }}
            </span>
            <span v-else class="white-space-nowrap">{{ item.label }}</span>
          </div>
        </router-link>
      </template>
    </TabMenu>

    <router-view/>

  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import store from "@/store";
import router from "@/router";

const optionMenu = ref();
const optionMenuItems = ref([
  {label: 'Project', to: '/new-project'},
  {label: 'Listing', to: '/new-offer'},
  {label: 'Outreach List', to: '/new-outreach-list'},
]);
const activeTab = ref(0);
const dashboardTabs = ref([
  {
    label: 'Collaborations',
    to: '/dashboard/collaborations'
  },
  {
    label: 'Projects',
    to: '/dashboard/projects'
  },
  {
    label: 'Listings',
    to: '/dashboard/listings'
  },
  {
    label: 'Outreach Lists',
    to: '/dashboard/outreach-list',
  },
  {
    label: 'Watch Lists',
    to: '/dashboard/watch-list',
  },
]);
const waitingCollabCounter = computed(() => store.state.user.waitingCollabs);
const projects = computed(() => store.state.user.userProjects);
const endedCollabs = ref(null);

onMounted(() => store.dispatch('user/fetchUserWaitingCollabs'));

watch(
    () => projects.value,
    (newProjects) => {
      let totalEndedCollabs = 0;
      newProjects.forEach(p => totalEndedCollabs += p.endedCollabsCount ? parseInt(p.endedCollabsCount) : 0)
      endedCollabs.value = totalEndedCollabs;
    },
    {immediate: true}
)

const toggle = (event) => {
  optionMenu.value.toggle(event);
};
</script>

<style>

</style>