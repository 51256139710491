import {auth} from "./auth.module";
import {user} from "@/store/user.module";
import {project} from "@/store/project.module";
import {outreachList} from "@/store/outreachList.module";
import {offer} from "@/store/offer.module";
import {notification} from "@/store/notification.module";
import {offerResponse} from "@/store/offerResponses.module";

const {createStore} = require("vuex");

// SOCKET : const websocketPlugin = createWebSocketPlugin(socketService);

const store = createStore({
    modules: {
        auth,
        user,
        project,
        outreachList,
        offer,
        offerResponse,
        notification
    },
    plugins: [
        /*websocketPlugin*/
    ]
});

export default store;