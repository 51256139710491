<template>

  <BackButton label="Back to my projects" to="/dashboard/projects"/>

  <div v-if="project" class="flex flex-row justify-content-between align-items-center">
    <h1 class="cropped">{{ project.name }}</h1>
    <div class="flex flex-row align-items-center gap-2">
      <Button class="p-button-sm p-button-text" icon="pi pi-eye"
              label="View"
              @click="router.push(`/projects/${props.id}`)"/>
    </div>
  </div>

  <TabMenu v-model:activeIndex="activeTab" :model="dashboardProjectTabs"
           :scrollable="true" class="mb-2 max-w-screen">
    <template #item="{ item}">
      <router-link  v-slot="routerProps" :to="item.to" custom>
        <div class="p-menuitem-link flex flex-row align-items-center flex-nowrap h-fit"
             @click="router.push(routerProps.href)">
          <span>{{ item.label }}</span>
        </div>
      </router-link>
    </template>
  </TabMenu>

  <router-view/>

</template>

<script setup>
import BackButton from "@/components/buttons/BackButton.vue";
import {computed, defineProps, onMounted, ref, watch} from "vue";
import router from "@/router";
import store from "@/store";

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
});


const activeTab = ref(0);
const dashboardProjectTabs = ref([
  {
    label: 'Information',
    to: `/dashboard/projects/${props.id}/information`
  },
  {
    label: 'Members',
    to: `/dashboard/projects/${props.id}/members`
  },
  {
    label: 'Winners',
    to: `/dashboard/projects/${props.id}/winners`
  },
  {
    label: 'Templates',
    to: `/dashboard/projects/${props.id}/templates`
  },
  {
    label: 'Links',
    to: `/dashboard/projects/${props.id}/links`
  },
  {
    label: 'Settings',
    to: `/dashboard/projects/${props.id}/settings`
  },
]);
const isProjectOwner = ref(false);
const user = computed(() => store.state.auth.user);
const project = computed(() => store.state.user.userProject);
const projectMembers = computed(() => store.state.project.projectMembers);

onMounted(() => {
  store.dispatch('user/fetchUserProject', props.id)
  store.dispatch('project/fetchProjectMembers', props.id)
});

watch(() => [projectMembers.value, user.value],
    ([newMembers, newUser]) => {
      if (newMembers && newUser) {
        isProjectOwner.value = newMembers.find(m => m.user.id === newUser.id && m.role === "owner");
      }
    }, {immediate: true, deep: true});


</script>

<style scoped></style>