import {ref} from "vue";
import {
    aptosIcon,
    bitcoinIcon, cardanoIcon,
    ethereumIcon,
    multiverseXIcon,
    polygonIcon,
    solanaIcon,
    suiIcon,
    venomIcon
} from "@/utils/blockchains";

export const projectTypeList = ref([
    {label: "NFT Collection", value: "nft"},
    {label: "Alpha group", value: "alpha"},
    {label: "Builder's Hub", value: "hub"},
    {label: "Gaming community", value: "game"},
])

export const collabManagersOrderByList = ref([
    {label: "Most Recent", by: "createdAt", sort: "desc"},
    {label: "Name A-Z", by: "username", sort: "asc"},
    {label: "Name Z-A", by: "username", sort: "desc"},
]);

export const projectsOrderByList = ref([
    {label: "Most Recent", by: "createdAt", sort: "desc"},
    {label: "Most Popular", by: "popular", sort: "asc"},
    {label: "Name A-Z", by: "name", sort: "asc"},
    {label: "Name Z-A", by: "name", sort: "desc"},
]);

export const amaTypeList = ref([
    {label: "No", value: "no", icon: "pi-minus"},
    {label: "X", value: "twitter", icon: "pi-twitter"},
    {label: "Discord", value: "discord", icon: "pi-discord"},
    {label: "Both", value: "both", icon: "pi-tags"},
]);

export const mintedStateList = ref([
    {name: "-", value: "null"},
    {name: "Yes", value: true},
    {name: "No", value: false},
])

export const offerTypeList = ref([
    {name: "All", value: "all"},
    {name: "Receive WL", value: "requesting"},
    {name: "Offer WL", value: "offering"},
])

export const hostAmaStateList = ref([
    {name: "-", value: "null"},
    {name: "Yes", value: true},
    {name: "No", value: false},
])

export const responseStatusList = ref([
    {label:'raffle finished', value:'raffle_ended', icon: 'pi pi-calendar-times', color: 'teal-500'},
    {label:'raffle running', value:'raffle_running', icon: 'pi pi-play', color: 'purple-500'},
    {label:'discussion', value:'discussion', icon: 'pi pi-comments', color: 'bluegray-500'},
    {label:'pending', value:'pending', icon: 'pi pi-clock', color: 'orange-500'},
    {label:'wallets sent', value:'wallet_sent', icon: 'pi pi-wallet', color: 'indigo-500'},
    {label:'finalized', value:'finalized', icon: 'pi pi-thumbs-up', color: 'blue-500'},
    {label:'archived', value:'archived', icon: 'pi pi-folder', color: 'gray-500'},
    {label:'declined', value:'declined', icon: 'pi pi-times', color: 'red-500'},
    // {label:'rejected', value:'rejected', icon: 'pi pi-times', color: 'red-700'},
    {label:'revisit', value:'revisit', icon: 'pi pi-backward', color: 'red-700'},
    {label:'reached out', value:'reached_out', icon: 'pi pi-eye', color: 'blue-700'},
    {label:'no reached out', value:'not_reached_out', icon: 'pi pi-eye-slash', color: 'orange-700'},
])


export const raffleStatusList = ref([
    {label:'running', value:'running', icon: 'pi pi-play', color: 'purple-500'},
    {label:'ended', value:'ended', icon: 'pi pi-calendar-times', color: 'teal-500'},
])

export const collabOrderList = ref([
    {name: "Project name", icon:"pi pi-sort-alpha-down", value: "project", order: "asc"},
    {name: "Project name", icon:"pi pi-sort-alpha-up", value: "project", order: "desc"},
    {name: "Contact name", icon:"pi pi-sort-alpha-down", value: "contact", order: "asc"},
    {name: "Contact name", icon:"pi pi-sort-alpha-up", value: "contact", order: "desc"},
    {name: "Collab manager", icon:"pi pi-sort-alpha-down", value: "collab_manager", order: "asc"},
    {name: "Collab manager", icon:"pi pi-sort-alpha-up", value: "collab_manager", order: "desc"},
    {name: "Raffle end date", icon:"pi pi-sort-numeric-down", value: "raffle_end", order: "asc"},
    {name: "Raffle end date", icon:"pi pi-sort-numeric-up", value: "raffle_end", order: "desc"},
])

export const blockchainList = ref([
    {name: "ethereum", token: "ETH", icon: ethereumIcon},
    {name: "bitcoin", token: "BTC", icon: bitcoinIcon},
    {name: "solana", token: "SOL", icon: solanaIcon},
    {name: "polygon", token: "MATIC", icon: polygonIcon},
    {name: "sui", token: "EGLD", icon: suiIcon},
    {name: "venom", token: "SUI", icon: venomIcon},
    {name: "multiverseX", token: "VENOM", icon: multiverseXIcon},
    {name: "cardano", token: "ADA", icon: cardanoIcon},
    {name: "aptos", token: "APT", icon: aptosIcon},
]);
export const memberRoleList = ref([
    {label: "Admin", value: "admin", color: "rgb(250,142,66)"},
    {label: "Collab Manager", value: "collab_manager", color: "rgb(185,117,249)"},
    {label: "Owner", value: "owner", color: "rgb(53,196,220)"},
]);

export const walletType = ref([
    {key: "dtc", label: "direct to contract", icon: "pi pi-key"},
    {key: "role", label: "role based", icon: "pi pi-shield"},
    {key: "application_code", label: "application code", icon: "pi pi-lock-open"},
]);

export const receivingRequirementTypes = ref([
    {key: "follow", label: "follow"},
    {key: "like", label: "like"},
    {key: "retweet", label: "retweet"},
    {key: "join_discord", label: "join discord"},
]);

export const offeringRequirementTypes = ref([
    {key: "follow", label: "follow"},
    {key: "like-rt", label: "like/RT"},
    {key: "like", label: "like"},
    {key: "retweet", label: "retweet"},
    {key: "join_discord", label: "join discord"},
]);

export const expirationTimes = ref([
    {label: '1 day', value: 1, unit: 'days'},
    {label: '2 days', value: 2, unit: 'days'},
    {label: '3 days', value: 3, unit: 'days'},
    {label: '4 days', value: 4, unit: 'days'},
    {label: '5 days', value: 5, unit: 'days'},
    {label: '6 days', value: 6, unit: 'days'},
    {label: '7 days', value: 7, unit: 'days'},
    {label: '8 days', value: 8, unit: 'days'},
    {label: '9 days', value: 9, unit: 'days'},
    {label: '10 days', value: 10, unit: 'days'},
    {label: '11 days', value: 11, unit: 'days'},
    {label: '12 days', value: 12, unit: 'days'},
    {label: '13 days', value: 13, unit: 'days'},
    {label: '14 days', value: 14, unit: 'days'},
]);
