<template>
  <div class="flex align-items-center justify-content-center" style="min-height: 300px;">
    <ProgressSpinner aria-label="Loading" class="h-4rem"/>
  </div>
</template>

<script setup>
</script>

<style scoped>

</style>