import UserService from "@/services/user.service";

const initialState = {
    users: [],
    user: null,
    userProject: null,
    userProjects: [],
    userOffers: [],
    waitingCollabs: 0,
    userOfferResponseSent: [],
    userOfferResponseReceived: [],
    offerResponseReceived: [],
    error: null,
    loading: false,
    totalPages: 0,
    userWallets: [],
};

export const user = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchUsers({commit, state}, payload = {
            page: Number, size: Number, order: Object, query: String
        }) {
            state.loading = true;
            state.error = null;
            const {
                page = 0, size = 20, order = {label: "Most recent", by: "createdAt", sort: "desc"}, query = ""
            } = payload;
            if (page === 0) state.users = [];
            return UserService.getUsers(page, size, order.by, order.sort, query).then(response => {
                state.totalPages = response.totalPages;
                const results = [...state.users, ...response.items];
                commit('fetchUsersSuccess', results)
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
        fetchUser({commit, state}, userId) {
            state.user = null;
            state.loading = true;
            state.error = null;
            if (userId) {
                return UserService.getUser(userId).then(response => {
                    commit('fetchUserSuccess', response);
                    return Promise.resolve(response);
                }, error => {
                    commit('fetchFailure', error);
                    return Promise.reject(error);
                });
            }
        },
        fetchUserProjects({commit, state}) {
            state.loading = true;
            state.error = null;
            return UserService.getUserProjects().then(response => {
                commit('fetchUserProjectsSuccess', response);
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
        fetchUserProject({commit, state}, projectId) {
            state.loading = true;
            state.error = null;
            state.userProject = null;

            return UserService.getUserProject(projectId).then(response => {
                commit('fetchUserProjectSuccess', response);
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
        fetchUserOffers({commit, state}) {
            state.loading = true;
            return UserService.getUserOffers().then(response => {
                commit('fetchUserOffersSuccess', response);
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
        fetchUserWaitingCollabs({commit, state}) {
            state.loading = true;
            return UserService.getUserWaitingCollabsNumber().then(response => {
                commit('fetchUserWaitingCollabsSuccess', response.waitingCollabs);
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
        fetchUserWallets({commit, state}) {
            state.userWallets = [];
            state.loading = true;
            state.error = null;
            return UserService.getUserWallets().then(response => {
                commit('fetchUserWalletsSuccess', response.data);
                return Promise.resolve(response);
            }, error => {
                commit('fetchFailure', error);
                return Promise.reject(error);
            });
        },
    },
    mutations: {
        fetchUsersSuccess(state, users) {
            state.loading = false;
            state.users = users;
        },
        fetchUserSuccess(state, user) {
            state.loading = false;
            state.user = user;
        },
        fetchUserProjectSuccess(state, project) {
            state.loading = false;
            state.userProject = project;
        },
        fetchUserProjectsSuccess(state, projects) {
            state.loading = false;
            state.userProjects = projects;
        },
        fetchUserOffersSuccess(state, offers) {
            state.loading = false;
            state.userOffers = offers;
        },
        fetchUserWaitingCollabsSuccess(state, offers) {
            state.loading = false;
            state.waitingCollabs = offers;
        },
        fetchUserWalletsSuccess(state, wallets) {
            state.loading = false;
            state.userWallets = wallets;
        },
        fetchFailure(state, error) {
            state.loading = false;
            state.error = error.response?.data?.message || error.message;
        },
        fetchWaitingUserOfferResponseSuccess(state, {id, waitingResponses}) {
            state.loading = false;
            state.userOffers.forEach(offer => {
                if (offer.id === id) {
                    offer.waitingResponses = waitingResponses
                }
            })
        },
    }
}