<template>
  <Button :label="label"
          class="p-button-sm c-scale"
          icon="pi pi-send"
          @click="visibleRespondToCollabModal = true"/>

  <Dialog v-model:visible="visibleRespondToCollabModal" :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :draggable="false"
          :header="label"
          :style="{ width: '50vw' }"
          modal>
    <div class="grid mt-3">
      <div class="col-5 flex align-items-center">
        <p class="m-0">Project</p>
      </div>
      <div class="col-2 flex align-items-center">
        <p class="m-0">Spots</p>
      </div>
      <div class="col-3 flex align-items-center">
        <p class="m-0">Proposal</p>
      </div>
      <div class="col-2 flex justify-content-center">
        <Button class="p-button-sm p-button-text p-button-icon-only"
                icon="pi pi-plus"
                @click="projectRows.push({project: null, spots: null})"/>
      </div>

      <div
          class="col-12 p-0 flex flex-column h-14rem max-h-14rem w-full overflow-x-auto border-1 border-round border-300">
        <div v-for="(rRow, rIdx) of projectRows"
             :key="`row${rIdx}`"
             class="flex flex-row w-full">
          <div class="col-5">
            <Dropdown :class="{'p-invalid': submitted && rRow.project === null}"
                      :model-value="rRow"
                      :options="availableProjects"
                      class="w-full p-inputtext-sm"
                      placeholder="Select project"
                      @change="handleSelectProjectForRow($event, rIdx)">
              <template #empty>
                <div class="flex flex-column justify-content-center align-items-center gap-1">
                  <p class="m-0 text-600">No project found</p>
                  <router-link to="/new-project">
                    <Button class="p-button-link p-button-sm" label="Create new project"/>
                  </router-link>
                </div>
              </template>
              <template #value="slotProps">
                <div v-if="slotProps.value.project !== null" class="flex align-items-center gap-2">
                  <Avatar :image="slotProps.value.project.logo" shape="circle" style="height: 1.4rem; width: 1.4rem;"/>
                  <span>{{ slotProps.value.project.name }}</span>
                </div>
                <span v-else>{{ slotProps.placeholder }}</span>
              </template>
              <template #option="slotProps">
                <div v-if="slotProps.option" class="flex align-items-center gap-2">
                  <Avatar :image="slotProps.option.logo" shape="circle" style="height: 1.4rem; width: 1.4rem;"/>
                  <span>{{ slotProps.option.name }}</span>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="col-2">
            <InputNumber v-model="rRow.spots"
                         :class="{'p-invalid': submitted && rRow.spots === null}"
                         :min="1"
                         class="p-inputtext-sm"
                         placeholder="1"/>
          </div>
          <div class="col-3 flex justify-content-center">
            <Button class="p-button-sm p-button-outlined" label="Proposition" @click="proposalModalVisible = true"/>
            <Dialog v-model:visible="proposalModalVisible" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
                    :closable="false" :style="{ width: '30rem' }" header="Proposal for project"
                    modal>
              <p class="mt-0 text-600">Select the elements you propose for the collaboration</p>
              <div class="flex flex-column gap-2">
                <div class="flex align-items-center">
                  <Checkbox v-model="rRow.threads" binary inputId="threads" name="threads"/>
                  <label class="ml-2" for="threads"> Threads </label>
                </div>
                <div class="flex align-items-center">
                  <Checkbox v-model="rRow.raids" binary inputId="raids" name="raids"/>
                  <label class="ml-2" for="raids"> Raids </label>
                </div>
                <div class="flex align-items-center">
                  <Checkbox v-model="rRow.ama" binary inputId="ama" name="ama"/>
                  <label class="ml-2" for="ama"> AMA </label>
                </div>
                <div class="flex align-items-center">
                  <Checkbox v-model="rRow.other" binary inputId="other" name="other"/>
                  <label class="mx-2" for="other"> Other </label>
                  <InputText v-model="rRow.otherInput" placeholder="Enter your other proposal"/>
                </div>
              </div>
              <template #footer>
                <Button autofocus class="p-button-sm" icon="pi pi-check" label="Ok" @click="proposalModalVisible = false"/>
              </template>
            </Dialog>
          </div>
          <div class="col-2 flex justify-content-center">
            <Button class="p-button-sm p-button-text p-button-danger p-button-icon-only"
                    icon="pi pi-trash"
                    @click="handleDeleteProjectRow(rIdx)"/>
          </div>
        </div>
      </div>

    </div>

    <div class="flex flex-row justify-content-end align-items-center gap-2 w-full mt-3">
      <Button class="p-button-text p-button-plain p-button-sm" label="Cancel"
              @click="visibleRespondToCollabModal = false"/>
      <Button class="p-button-sm" icon="pi pi-send" label="Send" @click="handleSubmitOfferResponse"/>
    </div>
  </Dialog>
</template>

<script setup>
import {computed, defineProps, onMounted, ref} from "vue";
import store from "@/store";
import OfferResponseService from "@/services/offerResponse.service";
import {useToast} from "primevue/usetoast";

const toast = useToast()
const proposalModalVisible = ref(false);
const submitted = ref(false);
let visibleRespondToCollabModal = ref(false)
let projectRows = ref([]);
let availableProjects = ref([])
const userProjects = computed(() => store.state.user.userProjects);

const props = defineProps({
  offerId: {
    type: Number,
    required: true,
  },
  offerType: {
    type: String,
    required: true,
  },
  projectId: {
    type: String,
    required: true,
  }
})

const label = props.offerType === 'offering' ? 'Ask for spots' : 'Propose spots'

onMounted(async () => {
  await store.dispatch('user/fetchUserProjects')
  availableProjects.value = userProjects.value.filter(p => p.id !== props.projectId);
  projectRows.value = [{
    project: null,
    spots: null,
    threads: false,
    raids: false,
    ama: false,
    other: false,
    otherInput: ""
  }];
});

const handleSelectProjectForRow = (evt, rowIndex) => {
  projectRows.value[rowIndex] = {project: evt.value, spots: null};
  availableProjects.value = userProjects.value.filter(up => !projectRows.value.find(row => row.project && up.id === row.project.id));
}

const handleDeleteProjectRow = (rowIndex) => {
  projectRows.value.splice(rowIndex, 1);
  availableProjects.value = userProjects.value.filter(up => !projectRows.value.find(row => row.project && up.id === row.project.id));
}

const handleSubmitOfferResponse = () => {
  submitted.value = true;

  let isValid = true;
  for (const pr of projectRows.value) {
    if (pr.project === null || pr.spots === null) isValid = false;
  }
  if (!isValid) return;

  OfferResponseService.createOfferResponse({
    offerId: props.offerId,
    projects: projectRows.value.map(p => ({
      id: p.project.id,
      spots: p.spots,
      threads: p.threads,
      raids: p.raids,
      ama: p.ama,
      other: p.other,
      otherInput: p.otherInput
    })),
    projectId: props.projectId,
  })
      .then(
          response => {
            toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
            visibleRespondToCollabModal.value = false;
          },
          error => {
            toast.add({
              severity: 'error',
              summary: 'Failed',
              detail: error.response?.data.message || error.message,
              life: 3000
            });
          });
}
</script>

<style scoped>

</style>