<template>
  <div class="flex flex-column gap-2 flex-wrap px-1 md:px-4 align-items-center">

    <form class="w-full flex flex-row flex-wrap justify-content-start" style="max-width: 900px;"
          @submit.prevent="handleSubmitNewProject">

      <BackButton to="/projects"/>

      <h1 class="m-0 md:my-3 text-700 w-full">Add new project</h1>
      <!-- OWNERSHIP -->
      <h2 class="m-0 text-700 mb-2 text-lg">Ownership verification (optional)</h2>

      <div class="col-12 flex flex-column gap-2 align-items-start">
        <p class="m-0 text-500">
          If you want to verify ownership of the project, log in with the project’s X account.
        </p>
        <a :href="projectTwitterLoginUrl"
           class="p-button p-button-sm p-button-outlined flex align-items-center gap-2 text-900 hover:surface-100">
          <XIcon style="position: relative;"/>
          <span class="font-semibold">Verify owner of project</span>
        </a>
        <small v-if="twitterError" class="p-error">{{ twitterError }}</small>
        <div v-if="newProjectForm.twitterId"
             class="flex flex-row border-1 border-round border-300 p-2 align-items-center gap-2">
          <Avatar :image="newProjectForm.logo" shape="circle"/>
          <span class="text-600">@{{ displayedTwitterScreenName }}</span>
          <Button class="p-button-icon-only p-button-text p-button-plain p-button-rounded p-button-sm"
                  icon="pi pi-times"
                  @click="removeTwitterAccount"
          />
        </div>
      </div>

      <!-- INFORMATION -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project information</h2>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="type">
          <RequiredDot/>
          Project type
        </label>
        <Dropdown v-model="newProjectForm.type"
                  :class="{'p-invalid': submitted && newProjectForm.type === ''}"
                  :options="projectTypeList"
                  class="w-full p-inputtext-sm"
                  option-label="label"
                  option-value="value"
                  placeholder="Type"
                  @change="handleProjectTypeChange"/>
        <small v-if="submitted && newProjectForm.type === ''" id="type-help" class="p-error">
          Project type is required.
        </small>
      </div>

      <div v-if="!(newProjectForm.type === 'nft' || newProjectForm.type === '')"
           class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2 justify-content-center">
        <div class="flex flex-row gap-3 align-items-center">
          <Checkbox id="haveNft" v-model="newProjectForm.haveNft" binary/>
          <label for="haveNft">Have NFT collection</label>
        </div>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="X">
          <RequiredDot/>
          X link
        </label>
        <InputText id="X"
                   v-model="newProjectForm.twitter"
                   :class="{'p-invalid': submitted && (newProjectForm.twitter === '' || twitterFormatError)}"
                   :disabled="newProjectForm.verified"
                   aria-describedby="twitter-help"
                   class="p-inputtext-sm"
                   placeholder="https://twitter.com/ind3xapp"/>
        <small v-if="submitted && newProjectForm.twitter === ''" id="twitter-help" class="p-error">
          Twitter link is required.
        </small>
        <small v-if="submitted && newProjectForm.twitter !== '' && twitterFormatError" id="twitter-help"
               class="p-error">
          The format of the url is not correct.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="name">
          <RequiredDot/>
          Name
        </label>
        <InputText id="username"
                   v-model="newProjectForm.name"
                   :disabled="newProjectForm.verified"
                   :class="{'p-invalid': submitted && newProjectForm.name === ''}"
                   aria-describedby="name-help"
                   class="p-inputtext-sm"
                   placeholder="Ind3x"/>
        <small v-if="submitted && newProjectForm.name === ''" id="name-help" class="p-error">
          Name is required.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="description">
          <RequiredDot/>
          Description
        </label>
        <div class="h-13rem">
          <Editor id="description"
                  v-model="newProjectForm.description"
                  :class="{'p-invalid': submitted && newProjectForm.description === ''}"
                  class="h-10rem">
            <template v-slot:toolbar>
              <div class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"/>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"/>
                <button v-tooltip.bottom="'Underline'" class="ql-underline"/>
                <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"/>
                <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"/>
                <button class="ql-link" data-pc-section="link" type="button"/>
                <select v-tooltip.bottom="'Color'" class="ql-color ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
                <select v-tooltip.bottom="'Background'" class="ql-background ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
              </div>
            </template>
          </Editor>
        </div>
        <small :class="{'text-red-500': newProjectForm.description.length > 5000}" class="text-right text-600">
          {{ newProjectForm.description.length }} / 5000
        </small>
        <small v-if="submitted && newProjectForm.description === ''" id="description-help" class="p-error">
          Description is required.
        </small>
        <small v-if="submitted && newProjectForm.description.length > 5000" id="description-help" class="p-error">
          Description must be lower than 5000 characters.
        </small>
      </div>

      <div class="col-12 md:col-3 md:px-2 p-0 flex flex-column gap-2 align-items-start mt-2">
        <label class="white-space-nowrap" for="logo">
          <RequiredDot/>
          Logo
        </label>
        <small class="text-500">Recommended size: 500 x 500</small>
        <div :class="{' border-red-500': submitted && newProjectForm.logo === ''}"
             class="border-1 border-round border-dashed border-400 flex align-items-center justify-content-center"
             style="aspect-ratio: 1 / 1; width: 165px; height: 165px;">
          <div :style="`background: url(${newProjectForm.logo}) no-repeat center;`"
               class="dragBox text-center border-round">
            <input id="uploadFile" accept="image/*"
                   type="file"
                   @change="onUploadPicture($event, 'logo')" @dragover="drag()" @drop="drop()"/>
            <small class="dragBoxText text-200 border-round">
              Click or <br> Drag and Drop here <br> to upload
            </small>
          </div>
        </div>
        <small v-if="submitted && newProjectForm.logo === ''" id="logo-help"
               class="p-error">
          Project logo is required.
        </small>
      </div>

      <div class="col-12 md:col-9 md:px-2 p-0 flex flex-column gap-2 mt-2">
        <label for="banner">
          <RequiredDot/>
          Banner
        </label>
        <small class="text-500">Recommended size: 1500 x 500</small>
        <div :class="{' border-red-500': submitted && newProjectForm.banner === ''}"
             class="border-1 border-round border-dashed border-400 flex align-items-center justify-content-center"
             style="max-width: 495px; width: 100%; height: 165px;">
          <div :style="`background: url(${newProjectForm.banner}) no-repeat center;`"
               class="dragBox text-center border-round">
            <input id="uploadFile" accept="image/*"
                   type="file"
                   @change="onUploadPicture($event, 'banner')" @dragover="drag()" @drop="drop()"/>
            <small class="dragBoxText text-200 border-round">
              Click or <br> Drag and Drop here <br> to upload
            </small>
          </div>
        </div>
        <small v-if="submitted && newProjectForm.banner === ''" id="banner-help" class="p-error">
          Banner picture is required.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="X" class="flex flex-row align-items-center gap-1">
          <AlphabotIcon width="14"/>
          Alphabot token (optional)
        </label>
        <small class="text-500">Necessary to retrieve raffle winners and their end date automatically</small>
        <InputText id="X"
                   v-model="newProjectForm.alphabotToken"
                   aria-describedby="alphabotToken-help"
                   class="p-inputtext-sm"
                   placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxx"/>
      </div>

      <!-- LINKS -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Links</h2>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="discord">Discord invite link</label>
        <InputText id="discord"
                   v-model="newProjectForm.discord"
                   :class="{'p-invalid': submitted && newProjectForm.discord !== '' && !newProjectForm.discord.startsWith('https://')}"
                   aria-describedby="discord-help"
                   class="p-inputtext-sm"
                   placeholder="https://discord.com/invite/DMzy3r9jR6"/>
        <small v-if="submitted && newProjectForm.discord !== '' && !newProjectForm.discord.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="website">Website</label>
        <InputText id="website"
                   v-model="newProjectForm.website"
                   :class="{'p-invalid': submitted && newProjectForm.website !== '' && !newProjectForm.website.startsWith('https://')}"
                   aria-describedby="website-help"
                   class="p-inputtext-sm"
                   placeholder="https://ind3x.app"/>
        <small v-if="submitted && newProjectForm.website !== '' && !newProjectForm.website.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="opensea">Opensea</label>
        <InputText id="opensea"
                   v-model="newProjectForm.opensea"
                   :class="{'p-invalid': submitted && newProjectForm.opensea !== '' && !newProjectForm.opensea.startsWith('https://')}"
                   aria-describedby="opensea-help"
                   class="p-inputtext-sm"
                   placeholder="https://opensea.io/collection/ind3x"/>
        <small v-if="submitted && newProjectForm.opensea !== '' && !newProjectForm.opensea.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="spreadsheet">Google spreadsheet link</label>
        <InputText id="spreadsheet"
                   v-model="newProjectForm.spreadsheet"
                   :class="{'p-invalid': submitted && newProjectForm.spreadsheet !== '' && !newProjectForm.spreadsheet.startsWith('https://')}"
                   aria-describedby="spreadsheet-help"
                   class="p-inputtext-sm"
                   placeholder="https://docs.google.com/spreadsheets"/>
        <small
            v-if="submitted && newProjectForm.spreadsheet !== '' && !newProjectForm.spreadsheet.startsWith('https://')"
            class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <!-- MINT -->
      <template v-if="newProjectForm.haveNft">
        <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Mint information</h2>

        <div class="col-12 md:px-2 p-0 flex flex-row gap-2 mt-2 align-items-center">
          <InputSwitch v-model="newProjectForm.minted" style="transform: scale(0.7);" @change="resetMintInformation"/>
          <span>Project already minted</span>
        </div>

        <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
          <label for="blockchain">
            <RequiredDot v-if="newProjectForm.haveNft"/>
            Blockchain
          </label>
          <Dropdown v-model="newProjectForm.blockchain"
                    :class="{'p-invalid': submitted && newProjectForm.haveNft && newProjectForm.blockchain === ''}"
                    :options="blockchainList"
                    class="w-full p-inputtext-sm"
                    option-label="name"
                    placeholder="Blockchain">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex gap-2 align-items-center">
                <img :alt="slotProps.value.name" :src="slotProps.value.icon" class="h-1rem"/>
                <p class="m-0 capitalize">{{ slotProps.value.name }}</p>
              </div>
              <span v-else>
                  {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex gap-2 align-items-center">
                <img :alt="slotProps.option.name" :src="slotProps.option.icon" class="h-1rem"/>
                <p class="m-0 capitalize">{{ slotProps.option.name }}</p>
              </div>
            </template>
          </Dropdown>
          <small v-if="submitted && newProjectForm.haveNft && newProjectForm.blockchain === ''" id="blockchain-help"
                 class="p-error">
            Blockchain is required.
          </small>
        </div>

        <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
          <label for="supply">Supply</label>
          <InputNumber id="supply"
                       v-model="newProjectForm.supply"
                       aria-describedby="supply-help"
                       class="p-inputtext-sm"
                       placeholder="5 555"/>
        </div>

        <template v-if="!newProjectForm.minted">
          <div class="col-8 md:col-4 lg:col-2 md:px-2 p-0 flex flex-column gap-1 mt-2">
            <label for="mintDate">Mint date</label>
            <Calendar id="mintDate"
                      v-model="projectMintDate.date"
                      :disabled="newProjectForm.minted"
                      :manualInput="true"
                      class="p-inputtext-sm"
                      placeholder="03/25/2023"
            />
          </div>

          <div class="col-4 md:col-2 lg:col-2 md:px-2 p-0 flex flex-column gap-1 mt-2 sm:pl-2">
            <label for="mintTime">Time</label>
            <InputMask v-model="projectMintDate.time"
                       :class="{'p-invalid': submitted && mintTimeFormatError}"
                       :disabled="newProjectForm.minted"
                       class="p-inputtext-sm"
                       mask="99:99 aa"
                       placeholder="03:15 pm"
                       @change="updateTimeFormat"/>
            <small v-if="submitted && mintTimeFormatError" class="p-error">
              Invalid time.
            </small>
          </div>

          <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
            <label for="mintPrice">Mint price</label>
            <InputNumber id="mintPrice"
                         v-model="newProjectForm.publicMintPrice"
                         :disabled="newProjectForm.minted"
                         :minFractionDigits="1"
                         aria-describedby="description-help"
                         class="p-inputtext-sm"
                         placeholder="0.03"/>
          </div>

          <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
            <label for="whitelistMintPrice">Whitelist mint price</label>
            <InputNumber id="whitelistMintPrice"
                         v-model="newProjectForm.whitelistMintPrice"
                         :disabled="newProjectForm.minted"
                         :minFractionDigits="1"
                         aria-describedby="description-help"
                         class="p-inputtext-sm"
                         placeholder="0.01"/>
          </div>
        </template>
      </template>

      <!-- OPERATING -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project operating</h2>

      <div class="col-12 md:px-2 p-0 flex flex-row gap-2 mt-2 align-items-center">
        <InputSwitch v-model="newProjectForm.twitterGiveaway" style="transform: scale(0.7);"/>
        <span>Twitter giveaways</span>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="ama">
          <RequiredDot/>
          Host AMA ?
        </label>
        <Dropdown id="ama"
                  v-model="newProjectForm.ama"
                  :class="{'p-invalid': submitted && newProjectForm.ama === null}"
                  :options="amaTypeList"
                  class="p-inputtext-sm"
                  placeholder="AMA">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex gap-2 align-items-center h-full">
              <span v-if="!slotProps.value.label">{{ slotProps.placeholder }}</span>
              <XIcon v-if="slotProps.value.icon.includes('twitter')" style="position: relative;"/>
              <i v-else
                 :class="slotProps.value.icon"
                 class="pi"/>
              <p class="m-0 capitalize">{{ slotProps.value.label }}</p>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex gap-2 align-items-center">
              <XIcon v-if="slotProps.option.icon.includes('twitter')" style="position: relative;"/>
              <i v-else
                 :class="slotProps.option.icon"
                 class="pi"/>
              <p class="m-0 capitalize">{{ slotProps.option.label }}</p>
            </div>
          </template>
        </Dropdown>
        <small v-if="submitted && newProjectForm.ama === null" id="description-help" class="p-error">
          Host AMA is required.
        </small>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="description">Giveaway entries</label>
        <InputNumber id="entries"
                     v-model="newProjectForm.averageEntries"
                     aria-describedby="entries-help"
                     class="p-inputtext-sm"
                     placeholder="100"/>
      </div>

      <!-- PROJECT MEMBER -->
      <div class="flex flex-row justify-content-between align-items-center flex-wrap mt-4 mb-2 w-full">
        <h2 class="m-0 text-700 text-lg">Project members</h2>
        <SearchProjectMembersModal/>
      </div>

      <EmptySection v-if="newProjectForm.members.length <= 0"
                    icon="pi pi-users" text="No members added"/>

      <ProjectMemberFormCard v-for="(member, memberIndex) of newProjectForm.members"
                             :key="member.id"
                             :index="memberIndex"
                             :member="member"
                             :remove-project-member="removeProjectMember"
                             :submitted="submitted"
                             :update-member-role="updateProjectMemberRole"/>

      <!-- ERROR -->
      <div v-if="error != null" class="col-12 flex justify-content-center">
        <Message :closable="false" severity="error">{{ error }}</Message>
      </div>

      <!-- ACTIONS -->
      <div class="col-12 p-0 flex justify-content-end px-2 mt-6 gap-2">
        <Button :disabled="loading" class="p-button-sm p-button-text p-button-plain" label="Cancel"
                @click="router.go(-1)"/>
        <Button :loading="loading" class="p-button-sm" icon="pi pi-check" label="Create project" type="submit"/>
      </div>

      <AddExistingTeamMembers :members="members" :project-id="projectId"
                              :visible-add-existing-team-members="membersModalVisible"/>

    </form>

  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import {getImageDataAsBase64} from "@/utils/picture";
import ProjectService from "@/services/project.service";
import BackButton from "@/components/buttons/BackButton.vue";
import RequiredDot from "@/components/RequiredDot.vue";
import {amaTypeList, blockchainList, projectTypeList} from "@/utils/dropdownItems";
import EmptySection from "@/components/sections/EmptySection.vue";
import store from "@/store";
import router from "@/router";
import SearchProjectMembersModal from "@/components/modals/SearchProjectMembersModal.vue";
import ProjectMemberFormCard from "@/components/cards/ProjectMemberFormCard.vue";
import {useToast} from "primevue/usetoast";
import XIcon from "@/components/icons/XIcon.vue";
import AddExistingTeamMembers from "@/components/modals/AddExistingTeamMembers.vue";
import api from "@/services/api";
import moment from "moment";
import AlphabotIcon from "@/components/icons/AlphabotIcon.vue";

const props = defineProps({
  message: {type: Object},
  id: {type: String},
  name: {type: String},
  profile_banner_url: {type: String},
  profile_image_url: {type: String},
  screen_name: {type: String},
})
const toast = useToast();
const membersModalVisible = ref(false);
const members = ref(null);
const projectId = ref(null);
const currentUser = ref(store.state.auth.user);
const projectTwitterLoginUrl = computed(() => process.env.VUE_APP_API_URL + "/projects/twitter");
const newProjectForm = ref({
  id: "",
  type: "",
  name: "",
  description: "",
  twitter: "",
  twitterId: null,
  discord: "",
  spreadsheet: "",
  website: "",
  instagram: "",
  opensea: "",
  supply: null,
  minted: false,
  mintDate: null,
  publicMintPrice: null,
  whitelistMintPrice: null,
  twitterGiveaway: false,
  averageEntries: null,
  ama: {label: "No", value: "no", icon: "pi-minus"},
  blockchain: "",
  logo: "",
  banner: "",
  haveNft: false,
  members: [],
  verified: false,
  alphabotToken: "",
})
const projectMintDate = ref({
  date: null,
  time: null,
})
const twitterFormatError = ref(false);
const mintTimeFormatError = ref(false);
const submitted = ref(false);
const error = ref(null);
const loading = ref(false);
const displayedTwitterScreenName = ref("");

const drag = () => document.getElementById('uploadFile').parentNode.className = 'dragStart dragBox';
const drop = () => document.getElementById('uploadFile').parentNode.className = 'dragBox';

onMounted(() => {
  store.dispatch("outreachList/resetListMembers")
  store.dispatch('outreachList/setListMembers', [{
    id: currentUser.value.id,
    username: currentUser.value.username,
    discordId: currentUser.value.discordId,
    avatar: currentUser.value.avatar,
    role: "owner"
  }])
});

watch(
    () => props.id,
    async (newId) => {
      if (newId != null) {
        displayedTwitterScreenName.value = props.screen_name;
        newProjectForm.value.name = props.name;
        newProjectForm.value.twitter = `https://x.com/${props.screen_name}`;
        newProjectForm.value.twitterId = newId;
        newProjectForm.value.verified = true;

        const bannerImg = await getImageDataAsBase64(props.profile_banner_url);
        let matches = bannerImg.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
        if (matches && matches.length === 3) {
          newProjectForm.value.banner = bannerImg;
        }

        const profileImg = await getImageDataAsBase64(props.profile_image_url);
        matches = profileImg.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
        if (matches && matches.length === 3) {
          newProjectForm.value.logo = profileImg;
        }
      }
    },
    {immediate: true}
)

const twitterError = ref("");
watch(
    () => props.message,
    (newMessage) => {
      twitterError.value = newMessage ? newMessage : "";
    },
    {immediate: true}
)

watch(
    () => store.state.outreachList.listMembers,
    (newMembers) => newProjectForm.value.members = newMembers,
    {immediate: true}
)

watch(
    () => newProjectForm.value.twitter,
    async (newTwitter) => {
      if (newTwitter) {
        let projectTwitter = null;
        const matchXLink = newTwitter.match(/(?:https?:)?\/\/(?:www\.|m\.)?(twitter\.com|x\.com)\/(\w{2,15})\/?(?:\?\S+)?(?:#\S+)?$/);
        if (matchXLink !== null) projectTwitter = matchXLink[2];

        if (projectTwitter !== null) {
          api.get(`${process.env.VUE_APP_API_URL}/projects-images/${projectTwitter}`)
              .then(
                  response => {
                    newProjectForm.value.logo = response.data.profile;
                    newProjectForm.value.banner = response.data.banner;
                  },
                  error => {
                    toast.add({
                      severity: 'warn',
                      summary: 'Retrieve user X pictures',
                      detail: error.response.data.message,
                      life: 3000
                    });
                  }
              )
        }
      }
    },
    {immediate: true}
)

const handleProjectTypeChange = () => newProjectForm.value.haveNft = newProjectForm.value.type === "nft";

const removeTwitterAccount = () => {
  newProjectForm.value.name = "";
  newProjectForm.value.twitter = "";
  newProjectForm.value.twitterId = null;
  newProjectForm.value.logo = "";
  newProjectForm.value.banner = "";
  displayedTwitterScreenName.value = "";
}

const resetMintInformation = () => {
  newProjectForm.value.mintDate = null;
  newProjectForm.value.publicMintPrice = null;
  newProjectForm.value.whitelistMintPrice = null;
}

const updateProjectMemberRole = (evt, index) => {
  newProjectForm.value.members[index].role = evt.value;
}

const removeProjectMember = (index) => {
  newProjectForm.value.members.splice(index, 1);
}

const onUploadPicture = (e, type) => {
  const files = e.target.files || e.dataTransfer.files;
  let reader = new FileReader();

  if (files[0].size / 1024 / 1024 > 2) {
    toast.add({
      severity: 'warn',
      summary: 'Image too big',
      detail: "The size of the image must not exceed 2 MB",
      life: 3000
    });
    return;
  }

  reader.readAsDataURL(files[0]);
  reader.onload = (e) => {

    const image = new Image();
    image.src = e.target.result;
    image.onload = () => {
      newProjectForm.value[type] = e.target.result;
    };
  }
}

const handleSubmitNewProject = () => {
  submitted.value = true;
  loading.value = true;
  error.value = null;
  twitterFormatError.value = false;
  mintTimeFormatError.value = false;

  try {
    const mintTime = moment(projectMintDate.value.time, "hh:mm a").toDate();

    const matchXLink = newProjectForm.value.twitter.match(/(?:https?:)?\/\/(?:www\.|m\.)?(twitter\.com|x\.com)\/(\w{2,15})\/?(?:\?\S+)?(?:#\S+)?$/);
    if (matchXLink === null) {
      loading.value = false;
      twitterFormatError.value = true;
    } else {
      newProjectForm.value.id = matchXLink[2];
    }

    if (projectMintDate.value.date != null) {
      if (isNaN(mintTime) || (projectMintDate.value.time.includes('am') && projectMintDate.value.time.includes('pm'))) {
        mintTimeFormatError.value = true;
        loading.value = false;
        return;
      }
      newProjectForm.value.mintDate = new Date(projectMintDate.value.date.setHours(mintTime.getHours(), mintTime.getMinutes(), "00"))
    }

    if (newProjectForm.value.name === "" ||
        newProjectForm.value.description === "" ||
        newProjectForm.value.logo === "" ||
        newProjectForm.value.banner === "" ||
        newProjectForm.value.type === "" ||
        newProjectForm.value.ama === null) {
      loading.value = false;
      return;
    }

    if (newProjectForm.value.haveNft && newProjectForm.value.blockchain === "") {
      loading.value = false;
      return;
    }

    let projectMembersIsIncomplete = false;
    newProjectForm.value.members.forEach(member => {
      if (!member.role) {
        projectMembersIsIncomplete = true;
      }
    });
    if (projectMembersIsIncomplete) {
      loading.value = false;
      return;
    }

    ProjectService.createProject({
      ...newProjectForm.value,
      blockchain: newProjectForm.value.blockchain.name,
      ama: newProjectForm.value.ama.value,
      twitter: newProjectForm.value.id,
    })
        .then((response) => {
          if (response.members.length > 0) {
            members.value = response.members;
            projectId.value = response.projectId;
            membersModalVisible.value = true;
          } else {
            store.dispatch('user/fetchUserProjects');
            router.push("/dashboard");
            toast.add({
              severity: 'success',
              summary: 'Project created',
              detail: "Project successfully created",
              life: 3000
            });
          }
        })
        .catch(err => {
          loading.value = false;
          console.error("Create project", err)
          error.value = err.response.data.message;
        });
  } catch (e) {
    alert(e)
  }
}

const updateTimeFormat = (evt) => {
  const regex = /(\d{2}):(\d{2})/;
  const match = evt.target.value.match(regex);
  const hour = parseInt(match[1])

  if (hour > 12) {
    projectMintDate.value.time = `${hour - 12 > 10 ? '' : '0'}${hour - 12}:${match[2]} pm`;
  }
}
</script>

<style scoped>
::v-deep(.ql-picker-options) {
  z-index: 9 !important;
}
</style>