import OutreachListService from "@/services/outreachList.service";
import UserService from "@/services/user.service";

const initialState = {
    userLists: [],
    userList: {},
    searchedUsers: [],
    listMembers: [],
    loading: false,
    error: null,
};
export const outreachList = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchOutreachLists({commit, state}) {
            state.loading = true;
            return OutreachListService.getUserLists()
                .then(
                    response => {
                        commit('fetchOutreachListsSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    }
                );
        },
        fetchOutreachList({commit, state}, outreachListId) {
            state.loading = true;
            return OutreachListService.getUserList(outreachListId)
                .then(
                    response => {
                        commit('fetchOutreachListSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    }
                );
        },
        fetchSearchUsers({commit}, query) {
            UserService.searchUsers(query)
                .then(
                    response => {
                        commit('fetchSearchUsersSuccess', response.data);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    });
        },
        setListMembers({state}, members) {
            state.listMembers = members;
        },
        addUserToList({state}, user) {
            state.listMembers.push(user);
        },
        removeUserToList({state}, index) {
            state.listMembers.splice(index, 1);
        },
        setRoleToUser({state}, {role, index}) {
            state.listMembers[index].role = role;
        },
        resetListMembers({state}) {
            state.listMembers = [];
        },
    },
    mutations: {
        fetchOutreachListsSuccess(state, userLists) {
            state.loading = false;
            state.userLists = userLists;
        },
        fetchOutreachListSuccess(state, userLists) {
            state.loading = false;
            state.userList = userLists;
        },
        fetchSearchUsersSuccess(state, users) {
            state.loading = false;
            state.searchedUsers = users;
        },
        fetchFailure(state, error) {
            state.userLists = [];
            state.userList = null;
            state.loading = false;
            state.error = error.response?.data?.message || error.message;
        },
    }
}