<template>
  <div class="col-12 sm:col-6 md:col-6 lg:col-4">

    <div class="relative w-full border-round-xl hover:shadow-2 c-scale h-full" style="max-height: 170px;">
      <div :style="`background: url(${project.banner}) no-repeat center center;`"
           class="card-background absolute left-0 top-0 w-full h-full border-round-xl hover:shadow-3"></div>
      <div class="relative flex flex-column align-items-center gap-1 p-2 pt-1 pl-3 cursor-pointer border-round"
           @click="router.push(`/projects/${project.id}`)">
        <div class="flex flex-row align-items-center justify-content-between w-full h-3rem">
          <BlockchainTag v-if="project.blockchain" :blockchain="project.blockchain"/>
          <div class="flex flex-row align-items-center justify-content-end w-full gap-2">
            <CardSocialButtons :discord="project.discord"
                               :is-discord-link="true"
                               :opensea="project.opensea"
                               :twitter="project.twitter"
                               :website="project.website"/>
            <i v-if="project.expiredAt"
               v-tooltip="`Unverified project. It will no longer be visible on ${$moment(project.expiredAt).format('lll')}.`"
               class="pi pi-exclamation-triangle text-orange-500 px-2"/>
          </div>
        </div>
        <div class="flex flex-row w-full gap-2 align-items-start mb-3">
          <img :src="project.logo" alt="Project picture" class="border-round h-5rem w-5rem"/>
          <div class="flex flex-column gap-2" style="width: 72%;">
            <div
                class="flex flex-row align-items-center justify-content-start gap-1 border-round blur-background p-2 max-w-full"
                style="width: fit-content; max-width: 84% !important;">
              <p class="m-0 text-200 text-lg font-semibold cropped"> {{ project.name }} </p>
              <VerifiedIcon :verified="project.verified"/>
            </div>
            <div class="flex flex-row flex-wrap align-items-center gap-1"
                 style="max-width: 90% !important;">
              <CardTag :value="getProjectTypeLabel(project.type)"/>
              <CardTag v-if="project.averageEntries > 0" :value="project.averageEntries" label="entries"/>
              <ListingTypeCard v-for="(offer, offerIdx) of project.offers" :key="`${project.name}Offer${offerIdx}`"
                               :type="offer.type"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import CardSocialButtons from "@/components/buttons/CardSocialButtons.vue";
import BlockchainTag from "@/components/tags/BlockchainTag.vue";
import CardTag from "@/components/tags/CardTag.vue";
import {defineProps} from 'vue';
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import ListingTypeCard from "@/components/tags/ListingTypeCard.vue";
import {projectTypeList} from "@/utils/dropdownItems";
import router from "@/router";

defineProps({
  project: {
    type: Object,
    required: true
  }
});

const getProjectTypeLabel = (type) => projectTypeList.value.filter(t => t.value === type)[0].label
</script>

<style scoped>
</style>