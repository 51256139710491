<template>
  <Button class="p-button-sm p-button-text" icon="pi pi-plus" label="Add" @click="addWalletModalVisible = true"/>

  <Dialog v-model:visible="addWalletModalVisible" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
          :style="{ width: '50rem', maxWidth: '500px' }"
          header="New wallet" modal>
    <div class="flex flex-column gap-2">

      <div class="field flex flex-column">
        <label>Blockchain</label>
        <Dropdown v-model="walletForm.blockchain"
                  :class="{'p-invalid': walletSubmitted && walletForm.blockchain === ''}"
                  :options="availableBlockchains"
                  class="w-full p-inputtext-sm"
                  option-label="name"
                  placeholder="Blockchain">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex gap-2 align-items-center">
              <img :alt="slotProps.value.name" :src="slotProps.value.icon" class="h-1rem"/>
              <p class="m-0 capitalize">{{ slotProps.value.name }}</p>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex gap-2 align-items-center">
              <img :alt="slotProps.option.name" :src="slotProps.option.icon" class="h-1rem"/>
              <p class="m-0 capitalize">{{ slotProps.option.name }}</p>
            </div>
          </template>
        </Dropdown>
        <small v-if="walletSubmitted && walletForm.blockchain === ''" id="blockchain-help" class="p-error">
          Blockchain is required.
        </small>
      </div>

      <div class="field flex flex-column">
        <label>Address</label>
        <InputText v-model="walletForm.address" placeholder="Enter wallet address"/>
        <small v-if="walletSubmitted && walletForm.address === ''" id="blockchain-help" class="p-error">
          Wallet address is required.
        </small>
      </div>
    </div>

    <template #footer>
      <Button class="p-button-sm p-button-plain p-button-text" label="Cancel" @click="addWalletModalVisible = false"/>
      <Button class="p-button-sm" icon="pi pi-check" label="Save wallet" :loading="loading" @click="handleSubmitWallet"/>
    </template>
  </Dialog>

</template>

<script setup>
import {defineProps, ref, watch} from "vue";
import {blockchainList} from "@/utils/dropdownItems";
import UserService from "@/services/user.service";
import {useToast} from "primevue/usetoast";
import store from "@/store";

const toast = useToast();
const addWalletModalVisible = ref(false);
const walletSubmitted = ref(false);
const loading = ref(false);
const walletForm = ref({blockchain: "", address: ""});
const availableBlockchains = ref([]);

const props = defineProps({
  userWallets: {type: Array, required: true}
})

watch(() => props.userWallets,
    (newUserWallets) => {
      availableBlockchains.value = blockchainList.value.filter(bc => !newUserWallets.includes(bc.name))
    }, {immediate: true})

const resetForm = () => {
  walletSubmitted.value = false;
  loading.value = false;
  walletForm.value = {blockchain: "", address: ""};
}

const handleSubmitWallet = () => {
  walletSubmitted.value = true;
  loading.value = true;

  if (walletForm.value.blockchain === "" || walletForm.value.address === "") {
    loading.value = false;
    return;
  }

  UserService.createUserWallet({
    address: walletForm.value.address,
    blockchain: walletForm.value.blockchain.name
  }).then(
      async response => {
        await store.dispatch('user/fetchUserWallets')
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        resetForm();
        addWalletModalVisible.value = false;
      },
      error => {
        addWalletModalVisible.value = false;
        toast.add({
          severity: 'error',
          summary: error.response.data.message,
          detail: error.response.data.error,
          life: 3000
        });
        resetForm();
      }
  );
}

</script>

<style scoped>

</style>