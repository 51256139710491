<template>
  <div class="grid p-2">
    <div class="col-12 flex flex-row justify-content-between align-items-center">
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project information</h2>
      <div class="flex flex-row align-items-center gap-2">
        <Button v-if="!edition" class="p-button-sm p-button-text" icon="pi pi-pencil" label="Edit"
                @click="edition = true"/>
        <template v-else>
          <Button :loading="saveInformationLoading" class="p-button-sm p-button-text p-button-success" icon="pi pi-save"
                  label="Save"
                  @click="handleSaveInformation"/>
          <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="cancelInformationEdition"/>
        </template>
      </div>
    </div>

    <template v-if="projectForm">

      <!-- INFORMATION -->
      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="name">
          <RequiredDot v-if="edition"/>
          Name
        </label>
        <InputText id="username"
                   v-model="projectForm.name"
                   :class="{'p-invalid': submitted && projectForm.name === ''}"
                   :disabled="!edition"
                   aria-describedby="name-help"
                   class="p-inputtext-sm"/>
        <small v-if="submitted && projectForm.name === ''" id="name-help" class="p-error">
          Name is required.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="description">
          <RequiredDot v-if="edition"/>
          Description</label>
        <div class="h-13rem">
          <Editor v-if="edition" id="description"
                  v-model="projectForm.description"
                  :class="{'p-invalid': submitted && projectForm.description === ''}"
                  class="h-10rem">
            <template v-slot:toolbar>
              <div class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"/>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"/>
                <button v-tooltip.bottom="'Underline'" class="ql-underline"/>
                <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"/>
                <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"/>
                <button class="ql-link" data-pc-section="link" type="button"/>
                <select v-tooltip.bottom="'Color'" class="ql-color ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
                <select v-tooltip.bottom="'Background'" class="ql-background ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
              </div>
            </template>
          </Editor>
          <Editor v-else id="description"
                  v-model="projectForm.description"
                  :class="{'p-invalid': submitted && projectForm.description === ''}"
                  class="h-10rem"
                  readonly>
            <template v-slot:toolbar>
              <div class="ql-formats">
              </div>
            </template>
          </Editor>
        </div>
        <small v-if="edition" :class="{'text-red-500': projectForm.description.length > 5000}"
               class="text-right text-600">
          {{ projectForm.description.length }} / 5000
        </small>
        <small v-if="submitted && projectForm.description === ''" id="description-help" class="p-error">
          Description is required.
        </small>
        <small v-if="submitted && projectForm.description.length > 5000" id="description-help" class="p-error">
          Description must be lower than 5000 characters.
        </small>
      </div>

      <div class="col-12 md:col-3 md:px-2 p-0 flex flex-column gap-2 align-items-start mt-2">
        <label class="white-space-nowrap" for="logo">
          <RequiredDot v-if="edition"/>
          Logo
        </label>
        <small class="text-500">Recommended size: 500 x 500</small>
        <div :class="{' border-red-500': submitted && projectForm.logo === ''}"
             class="border-1 border-round border-dashed border-400 flex align-items-center justify-content-center"
             style="aspect-ratio: 1 / 1; width: 165px; height: 165px;">
          <div :style="`background: url(${projectForm.logo}) no-repeat center;`"
               class="dragBox text-center border-round">
            <input id="uploadFile" :disabled="!edition"
                   accept="image/*"
                   type="file"
                   @change="onUploadPicture($event, 'logo')" @dragover="drag()" @drop="drop()"/>
            <small v-if="edition" class="dragBoxText text-200 border-round">
              Click or <br> Drag and Drop here <br> to upload
            </small>
          </div>
        </div>
        <small v-if="submitted && projectForm.logo === ''" id="logo-help"
               class="p-error">
          Project logo is required.
        </small>
      </div>

      <div class="col-12 md:col-9 md:px-2 p-0 flex flex-column gap-2 mt-2">
        <label for="banner">
          <RequiredDot v-if="edition"/>
          Banner
        </label>
        <small class="text-500">Recommended size: 1500 x 500</small>
        <div :class="{' border-red-500': submitted && projectForm.banner === ''}"
             class="border-1 border-round border-dashed border-400 flex align-items-center justify-content-center"
             style="max-width: 495px; width: 100%; height: 165px;">
          <div :style="`background: url(${projectForm.banner}) no-repeat center;`"
               class="dragBox text-center border-round">
            <input id="uploadFile" :disabled="!edition"
                   accept="image/*" type="file"
                   @change="onUploadPicture($event, 'banner')" @dragover="drag()" @drop="drop()"/>
            <small v-if="edition" class="dragBoxText text-200 border-round">
              Click or <br> Drag and Drop here <br> to upload
            </small>
          </div>
        </div>
        <small v-if="submitted && projectForm.banner === ''" id="banner-help" class="p-error">
          Banner picture is required.
        </small>
      </div>

      <!-- MINT -->
      <template v-if="projectForm.haveNft">
        <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Mint information</h2>

        <div class="col-12 md:px-2 p-0 flex flex-row gap-2 mt-2 align-items-center">
          <InputSwitch v-model="projectForm.minted" :disabled="!edition" style="transform: scale(0.7);"
                       @change="resetMintInformation"/>
          <span>Project already minted</span>
        </div>

        <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
          <label for="blockchain">
            <RequiredDot v-if="projectForm.haveNft"/>
            Blockchain
          </label>
          <Dropdown v-model="projectForm.blockchain"
                    :class="{'p-invalid': submitted && projectForm.haveNft && projectForm.blockchain === ''}"
                    :disabled="!edition"
                    :options="blockchainList"
                    class="w-full p-inputtext-sm"
                    option-label="name"
                    placeholder="Blockchain">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex gap-2 align-items-center">
                <img :alt="slotProps.value.name" :src="slotProps.value.icon" class="h-1rem"/>
                <p class="m-0 capitalize">{{ slotProps.value.name }}</p>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
            </span>
            </template>
            <template #option="slotProps">
              <div class="flex gap-2 align-items-center">
                <img :alt="slotProps.option.name" :src="slotProps.option.icon" class="h-1rem"/>
                <p class="m-0 capitalize">{{ slotProps.option.name }}</p>
              </div>
            </template>
          </Dropdown>
          <small v-if="submitted && projectForm.haveNft && projectForm.blockchain === ''" id="blockchain-help"
                 class="p-error">
            Blockchain is required.
          </small>
        </div>

        <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
          <label for="supply">Supply</label>
          <InputNumber id="supply" v-model="projectForm.supply"
                       :disabled="!edition"
                       aria-describedby="supply-help"
                       class="p-inputtext-sm"
                       placeholder="5 555"/>
        </div>

        <template v-if="!projectForm.minted">

          <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
            <label for="mintDate">Mint date UTC</label>
            <Calendar id="mintDate"
                      v-model="projectForm.mintDate"
                      :disabled="projectForm.minted || !edition"
                      class="p-inputtext-sm"
                      hourFormat="12"
                      placeholder="03/25/2023 03:03 pm"
                      showTime/>
          </div>

          <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
            <label for="mintPrice">Mint price</label>
            <InputNumber id="mintPrice"
                         v-model="projectForm.publicMintPrice"
                         :disabled="projectForm.minted || !edition"
                         :minFractionDigits="1"
                         aria-describedby="description-help"
                         class="p-inputtext-sm"
                         placeholder="0.03"/>
          </div>

          <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
            <label for="whitelistMintPrice">Whitelist mint price</label>
            <InputNumber id="whitelistMintPrice"
                         v-model="projectForm.whitelistMintPrice"
                         :disabled="projectForm.minted || edition"
                         :minFractionDigits="1"
                         aria-describedby="description-help"
                         class="p-inputtext-sm"
                         placeholder="0.01"/>
          </div>

        </template>
      </template>

      <!-- OPERATING -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project operating</h2>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="ama">
          <RequiredDot v-if="edition"/>
          Host AMA ?
        </label>
        <Dropdown id="ama"
                  v-model="projectForm.ama"
                  :class="{'p-invalid': submitted && projectForm.ama === ''}"
                  :options="amaTypeList"
                  class="p-inputtext-sm"
                  placeholder="AMA">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex gap-2 align-items-center h-full">
              <span v-if="!slotProps.value.label">{{ slotProps.placeholder }}</span>
              <XIcon v-if="slotProps.value.icon && slotProps.value.icon.includes('twitter')"
                     style="position: relative;"/>
              <i v-else
                 :class="slotProps.value.icon"
                 class="pi"/>
              <p class="m-0 capitalize">{{ slotProps.value.label }}</p>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex gap-2 align-items-center">
              <XIcon v-if="slotProps.option.icon && slotProps.option.icon.includes('twitter')"
                     style="position: relative;"/>
              <i v-else
                 :class="slotProps.option.icon"
                 class="pi"/>
              <p class="m-0 capitalize">{{ slotProps.option.label }}</p>
            </div>
          </template>
        </Dropdown>
        <small v-if="submitted && projectForm.ama === ''" id="description-help" class="p-error">
          Host AMA is required.
        </small>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="description">Giveaway entries</label>
        <InputNumber id="entries"
                     v-model="projectForm.averageEntries"
                     aria-describedby="entries-help"
                     class="p-inputtext-sm"
                     placeholder="100"/>
      </div>

      <div class="col-12 md:col-6 lg:col-4 md:px-2 p-0 flex flex-row gap-2 mt-2 align-items-center">
        <InputSwitch v-model="projectForm.twitterGiveaway" style="transform: scale(0.7);"/>
        <span>X giveaways</span>
      </div>

    </template>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref, watch} from "vue";
import store from "@/store";
import RequiredDot from "@/components/RequiredDot.vue";
import {useToast} from "primevue/usetoast";
import {amaTypeList, blockchainList} from "@/utils/dropdownItems";
import moment from "moment/moment";
import XIcon from "@/components/icons/XIcon.vue";
import ProjectService from "@/services/project.service";

const toast = useToast()
const project = computed(() => store.state.user.userProject)
const projectForm = ref(null)
const submitted = ref(false)
const saveInformationLoading = ref(false)
const edition = ref(false)

watch(
    () => project,
    (newProject) => {
      if (newProject.value && Object.keys(newProject.value).length > 0) {
        projectForm.value = Object.assign({}, {
          ...newProject.value,
          blockchain: blockchainList.value.find(b => b.name === newProject.value.blockchain),
          ama: amaTypeList.value.find(b => b.value === newProject.value.ama),
          mintDate: newProject.value.mintDate ? moment(newProject.value.mintDate).format('MM/DD/YYYY hh:mm a') : null,
        });
      }
    },
    {immediate: true, deep: true}
)

onBeforeUnmount(() => cancelInformationEdition());

const drag = () => document.getElementById('uploadFile').parentNode.className = 'dragStart dragBox';
const drop = () => document.getElementById('uploadFile').parentNode.className = 'dragBox';

const onUploadPicture = (e, type) => {
  const files = e.target.files || e.dataTransfer.files;
  let reader = new FileReader();

  if (files[0].size / 1024 / 1024 > 2) {
    toast.add({
      severity: 'warn',
      summary: 'Image too big',
      detail: "The size of the image must not exceed 2 MB",
      life: 3000
    });
    return;
  }

  reader.readAsDataURL(files[0]);
  reader.onload = (e) => {
    const image = new Image();
    image.src = e.target.result;
    image.onload = () => {
      projectForm.value[type] = e.target.result;
      return true;
    };
  }
}

const handleSaveInformation = () => {
  submitted.value = true;
  saveInformationLoading.value = true;

  if (projectForm.value.name === "" ||
      projectForm.value.description === "" ||
      projectForm.value.logo === "" ||
      projectForm.value.banner === "" ||
      projectForm.value.ama === "") {
    saveInformationLoading.value = false;
    return;
  }

  if (projectForm.value.haveNft && projectForm.value.blockchain === "") {
    saveInformationLoading.value = false;
    return;
  }

  ProjectService.updateProject(
      {
        ...projectForm.value,
        blockchain: projectForm.value.blockchain ? projectForm.value.blockchain.name : null,
        ama: projectForm.value.ama.value
      },
      project.value.id
  )
      .then(() => {
        saveInformationLoading.value = false;
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: "Project successfully updated",
          life: 3000
        });
        store.dispatch('user/fetchUserProject', project.value.id);
      })
      .catch(err => {
        saveInformationLoading.value = false;
        console.log(err)
      });
}

const cancelInformationEdition = () => {
  submitted.value = false;
  projectForm.value = Object.assign({}, {
    ...project.value,
    blockchain: blockchainList.value.find(b => b.name === project.value.blockchain),
    ama: amaTypeList.value.find(b => b.value === project.value.ama),
    mintDate: project.value.mintDate ? moment(project.value.mintDate).format('MM/DD/YYYY hh:mm a') : null,
  });
  edition.value = false;
}

</script>

<style scoped>

</style>