<template>

  <Loader v-if="loading"/>

  <EmptySection v-else-if="!loading && !error && notifications.length === 0" icon="pi-inbox" text="No notification"/>

  <ErrorSection v-else-if="!loading && error" icon="pi pi-times" text="Failed to retrieve notifications"/>

  <div v-else ref="scrollComponent" class="flex flex-column">
    <div v-for="notification of notifications"
         :key="`notification${notification.id}`"
         class="flex flex-column px-3 py-2 gap-1 cursor-pointer hover:surface-100 border-round-lg"
         @mouseleave="markNotificationAsRead(notification)"
         @click="openNotification($event, notification)">
      <div class="flex flex-row gap-1 align-items-center justify-content-between">
        <div class="flex flex-row align-items-center gap-2">
          <div class="flex flex-column">
            <div class="flex flex-row gap-2 align-items-center">
              <i :class="notification.icon" class="text-primary"/>
              <p class="m-0 font-semibold text-sm text-800" v-html="notification.title"></p>
              <i v-if="!notification.read" class="pi pi-circle-fill p-error" style="font-size: 0.5rem"/>
            </div>
            <p class="m-0 font-light text-sm text-500">{{ $moment(notification.createdAt).format('lll') }}</p>
          </div>
        </div>
        <Button v-if="deleting"
                class="p-button-icon-only p-button-text p-button-plain p-button-sm hover:text-red-500"
                icon="pi pi-trash"
                @click.stop="deleteNotification(notification.id)"/>
      </div>
      <p class="m-0 text-sm text-700" v-html="notification.message"></p>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, onUnmounted, ref} from 'vue';
import Loader from "@/components/sections/Loader.vue";
import EmptySection from "@/components/sections/EmptySection.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import store from "@/store";
import NotificationService from "@/services/notification.service";
import router from "@/router";
import {useToast} from "primevue/usetoast";

defineProps({
  deleting: {
    type: Boolean,
    required: false,
    default: false
  }
})

const toast = useToast();
const scrollComponent = ref(null);
const page = ref(0);
const notifications = computed(() => store.state.notification.notifications);
const loading = computed(() => store.state.notification.loading);
const error = computed(() => store.state.notification.error);
const totalPages = computed(() => store.state.notification.totalPages)

onMounted(async () => window.addEventListener("scroll", handleScroll))
onUnmounted(() => window.removeEventListener("scroll", handleScroll))

const handleScroll = async () => {
  let element = scrollComponent.value;
  if (element.getBoundingClientRect().bottom < window.innerHeight) {
    if (totalPages.value > page.value + 1) {
      page.value++;
      await store.dispatch('notification/fetchNotifications', {page: page.value});
    }
  }
}

const openNotification = async (event, notification) => {
  await NotificationService.updateNotificationsStatus([notification.id]);
  await router.push(notification.path);
}

const markNotificationAsRead = async (notification) => {
  if (!notification.read) {
    await NotificationService.updateNotificationsStatus([notification.id]);
    await store.dispatch('notification/fetchNotifications', {})
  }
}

const deleteNotification = (notificationId) => {
  NotificationService.deleteNotification(notificationId)
      .then(
          async () => {
            toast.add({severity: 'success', summary: 'Success', detail: "Notification deleted", life: 3000});
            await store.dispatch('notification/fetchNotifications', {});
          },
          error => {
            toast.add({
              severity: 'error',
              summary: 'Failed',
              detail: error.response.data.message || error.message,
              life: 3000
            });
          }
      )
}
</script>

<style scoped>

</style>