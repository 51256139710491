import {createApp} from 'vue'
import App from './App.vue'
import store from "./store";
import router from "@/router";
import PrimeVue from "primevue/config";
import setupInterceptors from "@/services/setupInterceptors";

import "@/styles/style.scss";

import moment from "moment";

import ConfirmationService from 'primevue/confirmationservice';
import ToastService from "primevue/toastservice";

import Button from "primevue/button";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import InlineMessage from "primevue/inlinemessage";
import Textarea from "primevue/textarea";
import ProgressSpinner from "primevue/progressspinner";
import Toast from "primevue/toast";
import Editor from "primevue/editor";
import Tooltip from 'primevue/tooltip';
import Tag from "primevue/tag";
import Paginator from "primevue/paginator";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import BadgeDirective from "primevue/badgedirective";
import Badge from "primevue/badge";
import OverlayPanel from "primevue/overlaypanel";
import Divider from "primevue/divider";
import RadioButton from "primevue/radiobutton";
import Message from "primevue/message";
import Password from "primevue/password";
import ToggleButton from "primevue/togglebutton";
import MultiSelect from "primevue/multiselect";
import TabMenu from "primevue/tabmenu";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputSwitch from "primevue/inputswitch";
import AvatarGroup from "primevue/avatargroup";
import Sidebar from "primevue/sidebar";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ConfirmDialog from "primevue/confirmdialog";
import {createMetaManager} from 'vue-meta';
import InputMask from "primevue/inputmask";
import ConfirmPopup from "primevue/confirmpopup";
import Image from 'primevue/image';

setupInterceptors(store);

const app = createApp(App)

app.use(PrimeVue)
app.use(ConfirmationService)
app.use(ToastService)
app.use(ConfirmationService)
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.use(router)
app.use(store)
app.use(createMetaManager())

app.config.globalProperties.$moment = moment;

app.component("Button", Button)
app.component("Avatar", Avatar)
app.component("Menu", Menu)
app.component("Dialog", Dialog)
app.component("InputText", InputText)
app.component("InputNumber", InputNumber)
app.component("Textarea", Textarea)
app.component("Calendar", Calendar)
app.component("Checkbox", Checkbox)
app.component("Dropdown", Dropdown)
app.component("InlineMessage", InlineMessage)
app.component("ProgressSpinner", ProgressSpinner)
app.component("Toast", Toast)
app.component("Editor", Editor)
app.component("Tag", Tag)
app.component("Paginator", Paginator)
app.component("TabView", TabView)
app.component("TabPanel", TabPanel)
app.component("Badge", Badge)
app.component("OverlayPanel", OverlayPanel)
app.component("Divider", Divider)
app.component("RadioButton", RadioButton)
app.component("Message", Message)
app.component("Password", Password)
app.component("ToggleButton", ToggleButton)
app.component("MultiSelect", MultiSelect)
app.component("TabMenu", TabMenu)
app.component("DataTable", DataTable)
app.component("Column", Column)
app.component("InputSwitch", InputSwitch)
app.component("AvatarGroup", AvatarGroup)
app.component("Sidebar", Sidebar)
app.component("Accordion", Accordion)
app.component("AccordionTab", AccordionTab)
app.component("ConfirmDialog", ConfirmDialog)
app.component("ConfirmPopup", ConfirmPopup)
app.component("Editor", Editor)
app.component("InputMask", InputMask)
app.component("OverlayPanel", OverlayPanel)
app.component("Image", Image)

router.isReady().then(() => {
    app.mount('#app')
})
