import moment from "moment/moment";

export const getRemainingTime = (endDate) => {
    const nowDatetime = moment().utc();
    const endDatetime = moment(endDate);
    const remainingDatetime = endDatetime.diff(nowDatetime);

    const days = moment.duration(remainingDatetime).days();
    const hours = moment.duration(remainingDatetime).hours();
    const minutes = moment.duration(remainingDatetime).minutes();
    const seconds = moment.duration(remainingDatetime).seconds();

    if (days > 0 || hours > 0 || minutes > 0) {
        return `${days !== 0 ? days + "d " : ''}${hours > 0 ? hours + "h " : ''}${minutes > 0 ? minutes + "m " : ''}${hours === 0 ? seconds + "s " : ''}remaining`
    } else {
        return "Ended";
    }
}