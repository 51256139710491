<template>
  <Button v-tooltip.left="'Add to outreach list'"
          :icon="alreadySaved ? 'pi pi-bookmark-fill' : 'pi pi-bookmark'"
          class="p-button-text p-button-sm p-button-rounded"
          @click="visibleAddToOutreachList = true"/>

  <Dialog v-model:visible="visibleAddToOutreachList"
          :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :draggable="false"
          :style="{ width: '50vw' }"
          header="Add to outreach list"
          modal>
    <div v-if="outreachList.length > 0" class="flex flex-column align-items-end gap-2 py-2">
      <MultiSelect v-model="selectedLists"
                   :options="outreachList"
                   class="w-full"
                   optionLabel="name"
                   placeholder="Select a list"/>
      <Button class="p-button-sm" icon="pi pi-check" label="Add to list" @click="addUserToOutreachList"/>
    </div>
    <div v-else class="flex flex-column align-items-center gap-2 py-2">
      <p class="m-0 text-600">You don't have any outreach list</p>
      <router-link to="/new-outreach-list">
        <Button class="p-button-link p-button-sm" label="Create one"/>
      </router-link>
    </div>
  </Dialog>
</template>

<script setup>
import store from "@/store";
import {defineProps, onMounted, ref} from "vue";
import {useToast} from "primevue/usetoast";
import OutreachListService from "@/services/outreachList.service";

const toast = useToast();

const props = defineProps({
  user: {
    type: Object,
    required: true,
  }
})

const visibleAddToOutreachList = ref(false);
const alreadySaved = ref(false);
const outreachList = ref(store.state.outreachList.userLists);
const selectedLists = ref([]);

onMounted(() => {
  for (let list of outreachList.value) {
    for (let member of list.listMembers) {
      if (member.userId === props.user.id) {
        selectedLists.value.push(list);
        alreadySaved.value = true;
      }
    }
  }
})

const addUserToOutreachList = () => {
  const body = {
    lists: outreachList.value.map(wl => wl.id),
    selectedLists: selectedLists.value.map((l) => l.id),
    userId: props.user.id
  }

  OutreachListService.addUserToLists(body)
      .then(response => {
        store.dispatch('outreachList/fetchOutreachLists');
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        visibleAddToOutreachList.value = false;
      })
      .catch(error => {
        toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message || error.message, life: 3000});
      })
}

</script>

<style scoped>

</style>