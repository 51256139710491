<template>
  <Button label="Template"
          class="p-button-xs hidden-mobile p-button-outlined p-button-secondary"
          icon="pi pi-book"
          @click="visibleResponseRequirementsModal = true"/>
  <Button v-tooltip.bottom="'Template'"
          class="p-button-xs hidden-desktop p-button-outlined p-button-icon-only p-button-secondary"
          icon="pi pi-book"
          @click="visibleResponseRequirementsModal = true"/>

  <Dialog v-model:visible="visibleResponseRequirementsModal"
          :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :style="{ width: '700px', maxWidth: '98vw'}"
          :draggable="false"
          header="Template"
          modal>
    <div class="grid">
      <div class="col-12 p-0 flex flex-column gap-1 mt-2 h-22rem">
        <Editor id="description"
                v-model="responseTemplateInput"
                :class="{'p-invalid': submitted && responseTemplateInput === ''}"
                class="h-19rem">
          <template v-slot:toolbar>
            <div class="ql-formats">
              <button v-tooltip.bottom="'Bold'" class="ql-bold"/>
              <button v-tooltip.bottom="'Italic'" class="ql-italic"/>
              <button v-tooltip.bottom="'Underline'" class="ql-underline"/>
              <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"/>
              <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"/>
              <button class="ql-link" data-pc-section="link" type="button"/>
              <select v-tooltip.bottom="'Color'" class="ql-color ql-picker ql-color-picker">
                <option value="#ffffff"/>
                <option value="#000000"/>
                <option value="#e60000"/>
                <option value="#ff9900"/>
                <option value="#ffff00"/>
                <option value="#008a00"/>
                <option value="#0066cc"/>
                <option value="#9933ff"/>
                <option value="#facccc"/>
              </select>
              <select v-tooltip.bottom="'Background'" class="ql-background ql-picker ql-color-picker">
                <option value="#ffffff"/>
                <option value="#000000"/>
                <option value="#e60000"/>
                <option value="#ff9900"/>
                <option value="#ffff00"/>
                <option value="#008a00"/>
                <option value="#0066cc"/>
                <option value="#9933ff"/>
                <option value="#facccc"/>
              </select>
            </div>
          </template>
        </Editor>

      </div>
      <small v-if="responseTemplateInput"
             :class="{'text-red-500': responseTemplateInput.length > 5000}"
             class="text-right w-full text-600">
        {{ responseTemplateInput.length }} / 5000
      </small>
      <small v-if="submitted && responseTemplateInput === ''" id="description-help" class="p-error">
        Description is required.
      </small>
      <small v-if="submitted && responseTemplateInput.length > 5000" id="description-help" class="p-error">
        Description must be lower than 5000 characters.
      </small>
    </div>

    <div class="flex flex-row justify-content-end align-items-center gap-2 w-full mt-3">
      <Button class="p-button-text p-button-plain p-button-sm" label="Cancel"
              @click="visibleResponseRequirementsModal = false"/>
      <Button class="p-button-sm" icon="pi pi-save" label="Save template" @click="updateOfferResponse"/>
    </div>
  </Dialog>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";
import {useToast} from "primevue/usetoast";
import OfferResponseService from "@/services/offerResponse.service";
import store from "@/store";

const toast = useToast()
let submitted = ref(false)
let visibleResponseRequirementsModal = ref(false)
let responseTemplateInput = ref("")

const props = defineProps({
  projectId: {
    type: String,
    required: false,
  },
  responseId: {
    type: Number,
    required: true,
  },
  responseTemplate: {
    type: String,
    required: true,
  },
});

watch(
    () => props.responseTemplate,
    (newTemplate) => {
      responseTemplateInput.value = newTemplate;
    },
    {immediate: true}
)

const updateOfferResponse = () => {
  submitted.value = true;

  if (responseTemplateInput.value.length > 5000) return;

  const body = {template: responseTemplateInput.value};

  OfferResponseService.updateOfferResponse(props.responseId, body)
      .then(async response => {
        await store.dispatch('offerResponse/fetchOfferResponses', props.projectId)
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message,
          life: 3000
        });
      });
}
</script>

<style scoped>

</style>