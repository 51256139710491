<template>
  <div v-if="offer" class="flex flex-column gap-2 flex-wrap px-1 md:px-4 align-items-center">

    <form class="w-full flex flex-row flex-wrap justify-content-between" style="max-width: 900px;"
          @submit.prevent="handleSubmitEditOffer">

      <BackButton/>

      <h1 class="m-0 md:my-3 text-700 w-full">Edit listing for : {{ offer.project.name }}</h1>

      <!-- OFFER TYPE -->
      <h2 class="m-0 text-700 mb-2 text-lg w-full">Listing type</h2>


      <div class="col-12 md:col-6 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <span class="p-buttonset flex flex-nowrap">
            <Button
                :class="{'text-400': editOfferForm.type !== 'offering', 'p-button-outlined': editOfferForm.type !== 'offering'}"
                class="p-button-sm capitalize w-full"
                disabled
                icon="pi pi-arrow-up-left"
                label="Offering whitelist"
                style="border-color: #ced4da;"
                @click="editOfferForm.type = 'offering'"/>
            <Button
                :class="{'text-400': editOfferForm.type !== 'requesting', 'p-button-outlined': editOfferForm.type !== 'requesting'}"
                class="p-button-sm capitalize w-full"
                disabled
                icon="pi pi-arrow-down-right"
                label="Receiving whitelist"
                style="border-color: #ced4da;"
                @click="editOfferForm.type = 'requesting'"/>
        </span>
      </div>

      <!-- OWNERSHIP -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project</h2>

      <div class="col-12 md:col-6 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <p class="m-0 text-500">Select the project for which you wish to post an offer</p>
        <Dropdown v-model="selectedProject"
                  :class="{'p-invalid': submitted && selectedProject === null}"
                  :options="userProjects"
                  class="w-full p-inputtext-sm"
                  disabled
                  option-label="name"
                  placeholder="Select a project">
          <template #empty>
            <div class="flex flex-column justify-content-center align-items-center gap-1">
              <p class="m-0 text-600">No project found</p>
              <router-link to="/new-project">
                <Button class="p-button-link p-button-sm" label="Create new project"/>
              </router-link>
            </div>
          </template>
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center gap-2">
              <img :alt="slotProps.value.name"
                   :src="slotProps.value.logo"
                   class="border-round"
                   height="20"/>
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center gap-2">
              <img :alt="slotProps.option.name"
                   :src="slotProps.option.logo"
                   class="border-round"
                   height="20"/>
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
        <small v-if="submitted && selectedProject === null" class="p-error">
          Project is required
        </small>
      </div>

      <!-- INFORMATION -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Information</h2>

      <template v-if="editOfferForm.type === 'offering'">
        <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
          <label for="whitelistType">
            <RequiredDot/>
            Whitelist type
          </label>
          <div class="grid">
            <div v-for="type of walletType"
                 :key="type.key"
                 class="col-12 md:col-6">
              <div
                  :class="{'bg-primary': editOfferForm.whitelistType === type.key, 'border-primary': editOfferForm.whitelistType === type.key}"
                  class="flex flex-row gap-2 border-round border-1 align-items-center p-2 text-400 border-400 cursor-pointer"
                  @click="editOfferForm.whitelistType = type.key">
                <i :class="`pi ${type.icon}`"/>
                <span class="capitalize">{{ type.label }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 md:col-6 md:px-2 p-0 flex flex-column gap-1 mt-2">
          <label for="mintDate">Wallet collection end date</label>
          <Calendar id="calendar-timeonly" v-model="editOfferForm.walletCollectionEndDate" class="p-inputtext-sm"
                    dateFormat="yy/mm/dd" placeholder="2023/03/12"/>
        </div>
      </template>

      <div v-if="editOfferForm.type === 'requesting'"
           class="col-12 md:col-6 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="spots">Spots requested</label>
        <InputNumber id="spots"
                     v-model="editOfferForm.spots"
                     :min="0"
                     aria-describedby="spots-help"
                     class="p-inputtext-sm"
                     placeholder="0"/>
      </div>

      <div class="col-12 md:col-6 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="projectName">
          <RequiredDot/>
          Post expiration date
        </label>
        <Dropdown v-model="selectedExpirationDuration"
                  :class="{'p-invalid': submitted && editOfferForm.expirationDate === ''}"
                  :options="expirationTimes"
                  class="w-full p-inputtext-sm"
                  optionLabel="label"
                  placeholder="Expiration duration"
                  @change="updateExpirationDate"/>
        <small class="mt-1 text-500">
          Post expires on:
          {{ editOfferForm.expirationDate ? $moment(editOfferForm.expirationDate).format('lll') : '-' }}
        </small>
        <small v-if="submitted && editOfferForm.expirationDate === ''" id="expirationDate" class="p-error">
          Expiration date is required
        </small>
      </div>


      <!-- GIVEAWAYS -->
      <div v-if="editOfferForm.type === 'offering'" class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="requiredGiveawayRequirements">
          <RequiredDot/>
          Giveaway requirements
        </label>
        <small v-if="submitted && !requiredGiveawayRequirementsValidate" class="p-error">
          Giveaway requirements malformed. All links must start with 'https://'.
        </small>
        <div class="grid">
          <div v-for="(giveawayRequirement, gaRIx) of requiredGiveawayRequirements"
               :key="gaRIx" class="flex flex-row align-items-center w-full flex-wrap">
            <div class="col-6 md:col-3">
              <Dropdown v-model="giveawayRequirement.type" :options="offeringRequirementTypes"
                        class="p-inputtext-sm p-button-sm w-full"
                        optionLabel="label"
                        placeholder="Select a type">
                <template #value="slotProps">
                  <div v-if="slotProps.value" class="capitalize">
                    <div>{{ slotProps.value.label }}</div>
                  </div>
                  <span v-else>
                {{ slotProps.placeholder }}
            </span>
                </template>
                <template #option="slotProps">
                  <div class="capitalize">
                    <div>{{ slotProps.option.label }}</div>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="col-4 md:col-8 p-2">
              <InputText v-model="giveawayRequirement.link"
                         class="p-inputtext-sm"
                         placeholder="Link"/>
            </div>
            <div class="col-2 md:col-1 p-2">
              <Button v-if="gaRIx > 0"
                      class="p-button-sm p-button-icon-only p-button-outlined p-button-danger"
                      icon="pi pi-minus"
                      @click="requiredGiveawayRequirements.splice(gaRIx, 1)"/>
            </div>
          </div>

          <div class="col-12 flex justify-content-start">
            <Button class="p-button-sm p-button-text" icon="pi pi-plus" label="Add"
                    @click="requiredGiveawayRequirements.push({type:'', link:''})"/>
          </div>
        </div>
      </div>

      <div v-else-if="editOfferForm.type === 'requesting'" class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="requiredGiveawayRequirements">
          <RequiredDot/>
          Accepted giveaway requirements
        </label>
        <small class="text-500">
          Select the requirements your project will accept for running the giveaway
        </small>
        <small v-if="submitted && acceptableGiveawayRequirements.length === 0" class="text-left p-error">
          Giveaways requirements required.
        </small>
        <div class="grid">
          <div v-for="gaType of receivingRequirementTypes"
               :key="gaType.key"
               class="col-12 md:col-4">
            <div
                :class="{'border-primary text-primary border-2 font-semibold': acceptableGiveawayRequirements.includes(gaType.key)}"
                class="flex flex-row gap-2 border-round border-1 align-items-center p-2 text-400 border-400 cursor-pointer"
                @click="addAcceptableGiveawayRequirement(gaType.key)">
              <span class="capitalize">{{ gaType.label }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- NOTIFY CMS -->
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Notify collab managers</h2>

      <div class="col-12">
        <p class="m-0 text-500">Select a list of collab managers to notify of your new listing</p>
      </div>

      <div class="col-12 md:col-6 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <Dropdown v-model="selectedOutreachList" :options="userOutreachList" class="w-full p-inputtext-sm"
                  optionLabel="name"
                  placeholder="Select outreach list to notify">
          <template #empty>
            <div class="flex flex-column justify-content-center align-items-center gap-1">
              <p class="m-0 text-600">No outreach list found</p>
              <router-link to="/new-outreach-list">
                <Button class="p-button-link p-button-sm" label="Create new outreach list"/>
              </router-link>
            </div>
          </template>
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
            {{ slotProps.placeholder }}
        </span>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ slotProps.option.name }}</div>
            </div>
          </template>
        </Dropdown>
      </div>

      <!-- ACTIONS -->
      <div class="col-12 md:px-2 p-0 flex flex-row gap-3 mt-4 justify-content-end">
        <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="$router.go(-1)"/>
        <Button class="p-button-sm" icon="pi pi-check" label="Save modification" type="submit"/>
      </div>

    </form>

  </div>
</template>

<script setup>
import {computed, defineProps, onMounted, ref, watch} from "vue";
import BackButton from "@/components/buttons/BackButton.vue";
import RequiredDot from "@/components/RequiredDot.vue";
import moment from "moment";
import store from "@/store";
import {
  expirationTimes,
  offeringRequirementTypes,
  receivingRequirementTypes,
  walletType
} from "@/utils/dropdownItems";
import OfferService from "@/services/offer.service";
import {useToast} from "primevue/usetoast";
import router from "@/router";

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const submitted = ref(false);
const selectedExpirationDuration = ref(null);
const selectedProject = ref(null);
const selectedOutreachList = ref(null);
const toast = useToast();
const acceptableGiveawayRequirements = ref([]);
const requiredGiveawayRequirementsValidate = ref(false);
const requiredGiveawayRequirements = ref([]);

const editOfferForm = ref({
  id: null,
  projectId: null,
  type: "offering",
  whitelistType: "dtc",
  walletCollectionEndDate: null,
  spots: null,
  giveawayRequirements: "",
  expirationDate: "",
});

const offer = ref(null);
watch(
    () => props.id,
    async (newId) => {
      if (newId) {
        offer.value = await store.dispatch("offer/fetchOffer", newId);
        editOfferForm.value = {
          ...editOfferForm.value,
          id: offer.value.id,
          projectId: offer.value.project.id,
          type: offer.value.type,
          whitelistType: offer.value.whitelistType,
          walletCollectionEndDate: offer.value.walletCollectionEndDate,
          spots: offer.value.spots,
        };
        if (offer.value.type === 'offering') {
          requiredGiveawayRequirements.value = await offer.value.giveawayRequirements.map(r => ({
            type: offeringRequirementTypes.value.filter(t => r.type === t.key)[0],
            link: r.link
          }))
        }
        if (offer.value.type === 'requesting') acceptableGiveawayRequirements.value = offer.value.acceptableGiveawayRequirements;
        selectedProject.value = userProjects.value.find(p => p.id === offer.value.project.id)
      }
    },
    {immediate: true}
)

onMounted(() => {
  store.dispatch('user/fetchUserProjects');
  store.dispatch('outreachList/fetchOutreachLists');
})

const userProjects = computed(() => store.state.user.userProjects)
const userOutreachList = computed(() => store.state.outreachList.userLists)

const updateExpirationDate = (event) => {
  editOfferForm.value.expirationDate = moment().add(event.value.value, event.value.unit);
}

const addAcceptableGiveawayRequirement = (gaType) => {
  const index = acceptableGiveawayRequirements.value.indexOf(gaType);

  if (index === -1) {
    acceptableGiveawayRequirements.value.push(gaType);
  } else {
    acceptableGiveawayRequirements.value.splice(index, 1);
  }
}

const handleSubmitEditOffer = () => {
  submitted.value = true;

  if (editOfferForm.value.type === 'requesting') {
    if (acceptableGiveawayRequirements.value.length === 0) return;
    editOfferForm.value.acceptableGiveawayRequirements = acceptableGiveawayRequirements.value;
  }

  if (editOfferForm.value.type === 'offering') {
    requiredGiveawayRequirementsValidate.value = true;
    for (const requirement of requiredGiveawayRequirements.value) {
      if (requirement.type === "" || requirement.link === "" || !requirement.link.startsWith("https://")) {
        requiredGiveawayRequirementsValidate.value = false;
        return;
      }
    }
    editOfferForm.value.requiredGiveawayRequirements = requiredGiveawayRequirements.value.map(r => ({
      type: r.type.key,
      link: r.link
    }));
  }

  if (selectedProject.value == null ||
      editOfferForm.value.expirationDate === "") return;

  editOfferForm.value.projectId = selectedProject.value.id;
  editOfferForm.value.expirationDate = moment(editOfferForm.value.expirationDate).format('MM/DD/YYYY hh:mm a')

  OfferService.updateOffer(editOfferForm.value)
      .then(response => {
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        router.push('/dashboard/listings');
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message || error.message,
          life: 3000
        });
      });
}
</script>

<style scoped>

</style>