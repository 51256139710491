<template>
  <div class="flex flex-column gap-3">

    <div
        class="flex flex-column px-2 h-10rem gap-2 justify-content-center align-items-center border-round-xl surface-100">
      <h1 class="m-0">Profile</h1>
      <p class="m-0 text-600 text-center">
        Update your information and your banner
      </p>
    </div>

    <div class="flex flex-row align-items-center justify-content-between mt-2 flex-wrap">
      <h2 class="m-0">Information</h2>
      <div v-if="!onEditProfile" class="flex flex-row gap-2 align-items-center justify-content-end">
        <router-link :to="`/collab-managers/${user.id}`">
          <Button class="p-button-sm p-button-outlined p-button-plain"
                  icon="pi pi-arrow-up-right"
                  icon-pos="right"
                  label="View public profile"/>
        </router-link>
        <Button class="p-button-sm p-button-outlined" icon="pi pi-pencil" label="Edit profile"
                @click="onEditProfile = true;"/>
      </div>
      <div v-else class="flex flex-row gap-2">
        <Button class="p-button-sm p-button-text p-button-secondary" label="Cancel"
                @click="onEditProfile = false;"/>
        <Button class="p-button-sm p-button-outlined p-button-success" icon="pi pi-check" label="Save profile"
                @click="updateProfile"/>
      </div>
    </div>

    <div class="flex flex-column gap-2">
      <label for="username">Username</label>
      <InputText v-model="user.username" class="capitalize" disabled/>
    </div>

    <div class="flex flex-column gap-2">
      <label for="description">Description</label>
      <Textarea id="description" v-model="profileForm.description"
                :class="{'p-invalid': profileForm.description.length > 500}"
                :disabled="!onEditProfile"
                aria-describedby="description-help"
                placeholder="User description" rows="6"/>
      <small v-if="onEditProfile" :class="{'text-red-600': profileForm.description.length > 500}"
             class="text-right text-600">
        {{ profileForm.description.length }} / 500
      </small>
      <small v-if="profileFormSubmitted && profileForm.description.length > 500" id="description"
             class="p-error">
        The description cannot exceed 500 characters.
      </small>
    </div>

    <div class="flex flex-column gap-2">
      <label class="white-space-nowrap" for="banner">
        Banner
      </label>
      <small v-if="onEditProfile" class="text-500">Recommended size: 1500 x 500</small>
      <div :class="{' border-red-500': profileFormSubmitted && profileForm.banner === ''}"
           class="border-1 border-round-lg border-300 flex align-items-center justify-content-center w-full"
           style="width: 100%; height: auto; aspect-ratio: 1500 / 500;">
        <div v-if="!onEditProfile"
             :style="`background: url(${profileForm.banner}) no-repeat center; `"
             class="h-full w-full border-round-lg"
             style="background-size: cover;">
        </div>
        <div v-else :style="`background: url(${profileForm.banner}) no-repeat center; `"
             class="dragBox text-center border-round">
          <input id="uploadFile" accept="image/*"
                 type="file"
                 @change="uploadPicture($event)" @dragover="drag()" @drop="drop()"/>
          <small class="dragBoxText text-200 border-round">
            Click here or <br> Drag and Drop here <br> to upload
          </small>
        </div>
      </div>
      <small v-if="profileFormSubmitted && profileForm.banner === ''" id="banner-help"
             class="p-error">
        Banner is required.
      </small>
    </div>

  </div>
</template>

<script setup>
import TokenService from "@/services/token.service";
import {ref, watch} from "vue";
import UserService from "@/services/user.service";
import {useToast} from "primevue/usetoast";
import store from "@/store";

const toast = useToast();

let onEditProfile = ref(false);
const profileFormSubmitted = ref(false);
const profileForm = ref({
  banner: "",
  description: "",
})

const user = ref(store.state.auth.user);
watch(
    () => store.state.auth.user,
    (newUser) => {
      profileForm.value.banner = newUser.banner || "";
      profileForm.value.description = newUser.description || "";
    },
    {immediate: true}
)

const drag = () => document.getElementById('uploadFile').parentNode.className = 'dragStart dragBox';
const drop = () => document.getElementById('uploadFile').parentNode.className = 'dragBox';

const uploadPicture = (e) => {
  const files = e.target.files || e.dataTransfer.files;
  let reader = new FileReader();

  if (files[0].size / 1024 / 1024 > 2) {
    toast.add({
      severity: 'warn',
      summary: 'Image too big',
      detail: "The size of the image must not exceed 2 MB",
      life: 3000
    });
    return;
  }

  reader.readAsDataURL(files[0]);
  reader.onload = (e) => {
    const image = new Image();
    image.src = e.target.result;
    image.onload = () => {
      profileForm.value.banner = e.target.result;
      return true;
    };
  }
}

const updateProfile = () => {
  profileFormSubmitted.value = true;

  if (profileForm.value.description.length > 500 || profileForm.value.banner === "") return;

  UserService.updateUser(profileForm.value)
      .then(response => {
        onEditProfile.value = false;
        profileFormSubmitted.value = false;
        TokenService.updateUser(response.user);
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.message,
          life: 3000
        });
      });
}
</script>

<style scoped>

</style>