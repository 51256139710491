<template>
  <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" fill="#63FF1E" aria-hidden="true"
    viewBox="0 0 30.062 35.585" data-testid="Alpabot.svgIcon">
    <g data-name="Group 1">
      <path data-name="Path 1"
        d="M0 29.78a2.063 2.063 0 0 1 1.2-1.8l7.387-3.738.307-.923-.594-.263C3.182 20.773 0 16.197 0 11.115 0 4.363 5.9 0 15.031 0c9.412 0 15.031 4.155 15.031 11.115 0 5.113-3.208 9.7-8.373 11.973l-.6.264.3.88.256.139 7.134 3.609a2.064 2.064 0 0 1 1.2 1.8v4.376l-14.991-7.565-8.28 4.181a2.256 2.256 0 0 0-1.253 2.018v2.795H0Zm24.522-18.665c0-4.18-3.193-6.3-9.49-6.3s-9.491 2.119-9.491 6.3c0 4.708 4.257 8.539 9.491 8.539s9.49-3.83 9.49-8.539">
      </path>
    </g>
  </svg>
</template>

<script setup>
</script>

<style scoped></style>