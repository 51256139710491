<template>
  <Button v-if="unreadNotifications > 0"
          :loading="markAllAsReadLoading"
          class="p-button-sm p-button-text p-button-plain"
          icon="pi pi-check"
          label="Mark all as read"
          @click="markAllAsRead"/>
</template>

<script setup>
import NotificationService from "@/services/notification.service";
import store from "@/store";
import {computed, ref} from "vue";

const unreadNotifications = computed(() => store.state.notification.unreadNotifications);
const notifications = computed(() => store.state.notification.notifications);
const markAllAsReadLoading = ref(false);

const markAllAsRead = async () => {
  markAllAsReadLoading.value = true;
  const notificationsIds = notifications.value.map(n => n.id);
  await NotificationService.allNotificationRead(notificationsIds);
  await store.dispatch('notification/fetchNotifications', {});
  markAllAsReadLoading.value = false;
}
</script>

<style scoped>

</style>