<template>
  <Dialog v-model:visible="saveDataModalVisible" :breakpoints="{ '1199px': '40vw', '800px': '60vw', '575px': '90vw' }"
          :closable="false" modal>
    <div class="flex flex-column align-items-center gap-2 text-orange-500 w-full justify-content-center">
      <i class="pi pi-exclamation-triangle text-4xl "/>
      <p class="m-0 text-lg font-semibold">Data not saved</p>
    </div>
    <p class="text-center">
      The data on Ind3x is different from the spreadsheet. By continuing to overwrite the data on Ind3x.
    </p>
    <div class="flex flex-row flex-nowrap justify-content-between gap-2">
      <Button class="p-button-sm p-button-outlined w-full" label="Overwrite data" @click="retrieveDataFromSpreadsheet"/>
      <Button class="p-button-sm w-full" label="Save data" @click="saveDataToSpreadsheet"/>
    </div>
  </Dialog>

  <ImportTeamWalletModal v-if="project" :project-id="project.id" :spread-sheet-id="project.winnersSpreadsheet"
                         :visible="importTeamWalletModalVisible"/>

  <div class="grid p-2 w-full">

    <div v-if="project" class="col-12 flex flex-row flex-wrap justify-content-between align-items-center">
      <h2 class="m-0 text-700 text-lg">All winners from outbound collaborations</h2>

      <div class="flex flex-row align-items-center gap-2 justify-content-end hidden-tablet">
        <Button class="p-button-text p-button-sm p-button-success p-button-icon-only"
                icon="pi pi-file-excel" @click="openSpreadsheet"/>
        <Button v-tooltip.bottom="'Retrieve spreadsheet data'"
                :disabled="!project.winnersSpreadsheet"
                :loading="retrieveLoading"
                class="p-button-text p-button-sm p-button-plain"
                icon="pi pi-cloud-download"
                label="Retrieve"
                @click="retrieveDataFromSpreadsheet"/>
        <Button v-tooltip.bottom="'Send data to spreadsheet'"
                :disabled="!project.winnersSpreadsheet"
                :loading="updateLoading"
                class="p-button-text p-button-sm p-button-plain"
                icon="pi pi-cloud-upload"
                label="Save"
                @click="saveDataToSpreadsheet"/>
        <Button v-tooltip.bottom="'Import team wallets to sheet'"
                :disabled="!project.winnersSpreadsheet"
                class="p-button-text p-button-sm p-button-plain"
                icon="pi pi-file-import"
                label="Team wallets"
                @click="importTeamWalletModalVisible = true"/>
      </div>

      <Button aria-controls="overlay_menu" aria-haspopup="true"
              class="p-button-text p-button-plain hidden-desktop p-button-sm"
              icon="pi pi-ellipsis-v"
              type="button" @click="toggleProjectMenu"/>
      <Menu id="overlay_menu" ref="projectMenu" :model="projectMenuItems" :popup="true"/>
    </div>

    <div class="col-12">
      <p class="m-0 text-500">Find all the winners from the spreadsheets of each outbound collaboration raffle.</p>
    </div>

    <div class="col-12 flex flex-row justify-content-between w-full align-items-center">
      <p class="m-0 text-sm text-600 font-light">{{ rows.length }} winners</p>
      <div class="flex flex-row gap-2 align-items-center">
        <div v-if="edited"
             v-tooltip.left="'The data has been modified on Ind3x, it is no longer equivalent to that present on the spreadsheet. Don\'t forget to save.'"
             class="flex flex-row gap-2 align-items-center text-orange-500">
          <i class="pi pi-exclamation-circle"/>
          <p class="text-sm m-0 font-light">Data not saved</p>
        </div>
        <Button class="p-button-sm p-button-text" icon="pi pi-plus" label="Add row" @click="handleAddRow"/>
      </div>
    </div>

    <div class="col-12">
      <DataTable
          :loading="retrieveLoading"
          :value="rows"
          class="p-datatable-sm w-full overflow-x-hidden"
          editMode="cell"
          scroll-height="54vh"
          scrollable
          showGridlines>
        <template #empty>
          <p class="text-sm text-200">No winners found.</p>
        </template>
        <template #loading>
          <p class="text-sm text-200">Loading winners data. Please wait.</p>
        </template>
        <Column v-for="(col, colIdx) of columns" :key="`col${colIdx}`" :field="col" :header="col"
                :style="{width: 100/ columns.length + '%' }">
          <template #body="{ index }">
            <p :style="{mawWidth: 100/ columns.length + '%' }" class="overflow-hidden text-overflow-ellipsis m-0">
              {{ rows[index][colIdx] }}</p>
          </template>
          <template #editor="{ index }">
            <InputText v-model="rows[index][colIdx]" autofocus class="p-0 border-none" @change="onCellEditComplete"/>
          </template>
        </Column>
      </DataTable>
    </div>

  </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import {openExternalLink} from "@/utils/openLink";
import store from "@/store";
import ProjectService from "@/services/project.service";
import {useToast} from "primevue/usetoast";
import ImportTeamWalletModal from "@/components/modals/ImportTeamWalletModal.vue";

const toast = useToast();
const project = computed(() => store.state.user.userProject);

const projectMenu = ref();
const projectMenuItems = ref([
  {
    label: 'Open spreadsheet',
    icon: 'pi pi-file-excel',
    command: () => {
      openSpreadsheet();
    }
  },
  {
    label: 'Retrieve',
    icon: 'pi pi-cloud-download',
    command: () => {
      if (!project.value.winnersSpreadsheet) {
        toast.add({severity: 'warn', summary: 'Spreadsheet', detail: "Spreadsheet not exist", life: 3000});
      } else {
        retrieveDataFromSpreadsheet();
      }
    }
  },
  {
    label: 'Save',
    icon: 'pi pi-cloud-upload',
    command: () => {
      if (!project.value.winnersSpreadsheet) {
        toast.add({severity: 'warn', summary: 'Spreadsheet', detail: "Spreadsheet not exist", life: 3000});
      } else {
        saveDataToSpreadsheet();
      }
    }
  },
  {
    label: 'Import team wallets',
    icon: 'pi pi-file-import',
    command: () => {
      if (!project.value.winnersSpreadsheet) {
        toast.add({severity: 'warn', summary: 'Spreadsheet', detail: "Spreadsheet not exist", life: 3000});
      } else {
        importTeamWalletModalVisible.value = true
      }
    }
  }
])
const edited = ref(false);
const saveDataModalVisible = ref(false);
const importTeamWalletModalVisible = ref(false);
const retrieveLoading = ref(false);
const updateLoading = ref(false);
const columns = ref([]);
const rows = ref([]);

const toggleProjectMenu = (event) => {
  projectMenu.value.toggle(event);
};

const onCellEditComplete = () => edited.value = true;

const handleAddRow = () => {
  edited.value = true;
  if (rows.value.length === 0 && columns.value.length === 0) {
    rows.value.push(['discordName', 'discordId', 'twitterHandle', 'mintAddress']);
    rows.value.push([' ', ' ', ' ', ' ']);
  } else {
    rows.value.push(Array(columns.value.length).fill(" "));
  }
}

const openSpreadsheet = async () => {
  if (project.value.winnersSpreadsheet != null) {
    openExternalLink(`https://docs.google.com/spreadsheets/d/${project.value.winnersSpreadsheet}`);
  } else {
    toast.add({
      severity: 'warn',
      summary: 'Google account missing',
      detail: "You must connect your Google account to be able to create a spreadsheet",
      life: 3000
    });
  }
}

const retrieveDataFromSpreadsheet = () => {
  saveDataModalVisible.value = false;

  if (edited.value) {
    saveDataModalVisible.value = true;
    edited.value = false;
    return;
  }

  retrieveLoading.value = true;
  ProjectService.getProjectSpreadsheet(project.value.id, project.value.winnersSpreadsheet).then(
      response => {
        if (response.data) {
          reloadTable(response.data);
        } else {
          retrieveLoading.value = false;
          toast.add({severity: 'info', summary: 'No data', detail: "Spreadsheet is empty", life: 3000});
        }
        retrieveLoading.value = false;
      },
      error => {
        retrieveLoading.value = false;
        toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      }
  )
}

const saveDataToSpreadsheet = () => {
  saveDataModalVisible.value = false;
  updateLoading.value = true;
  ProjectService.updateProjectSpreadsheet(project.value.id, project.value.winnersSpreadsheet, [columns.value, ...rows.value]).then(
      response => {
        edited.value = false;
        updateLoading.value = false;
        toast.add({severity: 'success', summary: 'Data saved', detail: response.message, life: 3000});
      },
      error => {
        updateLoading.value = false;
        toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      }
  )
}

const synchronizeSpreadsheetData = () => {
  retrieveLoading.value = true;

  if (project.value.winnersSpreadsheet) {
    ProjectService.synchronizeProjectSpreadsheet(project.value.id, project.value.winnersSpreadsheet).then(
        response => {
          if (response.data) {
            reloadTable(response.data);
            toast.add({severity: 'success', summary: 'Winners synchronized', detail: response.message, life: 3000});
          } else {
            toast.add({severity: 'error', summary: 'No data', detail: response.message, life: 3000});
          }
          retrieveLoading.value = false;
        },
        error => {
          retrieveLoading.value = false;
          toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
        }
    )
  }
}

const reloadTable = (data) => {
  columns.value = data[0];
  data.shift()
  rows.value = data;
}

watch(
    () => project,
    async (newProject) => {
      if (newProject.value && newProject.value.winnersSpreadsheet) {
        await synchronizeSpreadsheetData();
      }
    },
    {immediate: true, deep: true}
)
</script>

<style scoped>

</style>