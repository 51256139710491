<template>
  <div class="grid p-2">

    <div class="col-12 flex flex-row justify-content-between align-items-center">
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project members</h2>
      <div v-if="isOwner" class="flex flex-row align-items-center gap-1 flex-grow-1">
        <AddProjectMembersModal/>
      </div>
    </div>

    <EmptySection v-if="members.length <= 0" icon="pi pi-users" text="No members in this project"/>

    <div class="col-12 flex flex-column gap-3">
      <div v-for="member of members" :key="member.userId" class="grid surface-100 border-round-lg p-1">
        <div class="col-9 flex flex-row gap-2 align-items-center">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${member.user.discordId}/${member.user.discordAvatar}`"
                  shape="circle" style="width: 2rem; height: 2rem;"/>
          <span class="capitalize font-semibold">{{ member.user.username }}</span>
          <RoleTag :role="member.role"/>
          <p class="text-sm"></p>
        </div>
        <div v-if="isOwner" class="col-3 flex flex-row gap-1 align-items-stretch justify-content-end">
          <CopyWalletButton v-if="member.walletEthereum" :address="member.walletEthereum" icon="ethereum"/>
          <CopyWalletButton v-if="member.walletSolana" :address="member.walletSolana" icon="solana"/>
          <CopyWalletButton v-if="member.walletBitcoin" :address="member.walletBitcoin" icon="bitcoin"/>

          <UpdateProjectMemberModal :member="member" :project-id="project.id"/>
          <ConfirmPopup></ConfirmPopup>
          <Button v-if="member.role !== 'owner'" class="p-button-text p-button-icon-only p-button-xs p-button-danger"
                  icon="pi pi-trash"
                  @click="confirmMemberDeletion($event, member.userId)"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import store from "@/store";
import EmptySection from "@/components/sections/EmptySection.vue";
import RoleTag from "@/components/tags/RoleTag.vue";
import {useConfirm} from "primevue/useconfirm";
import AddProjectMembersModal from "@/components/modals/AddProjectMembersModal.vue";
import {useToast} from "primevue/usetoast";
import UpdateProjectMemberModal from "@/components/modals/UpdateProjectMemberModal.vue";
import CopyWalletButton from "@/components/buttons/CopyWalletButton.vue";

const confirm = useConfirm()
const toast = useToast()
const project = computed(() => store.state.user.userProject);
const members = computed(() => store.state.project.projectMembers);
const user = computed(() => store.state.auth.user);
const isOwner = ref(false);

watch(
    () => project,
    async (newProject) => {
      if (newProject.value) {
        await store.dispatch('project/fetchProjectMembers', newProject.value.id);
      }
    },
    {immediate: true, deep: true}
)

watch(
    () => [members, user],
    ([newMembers, newUser]) => {
      if (newMembers.value && newUser.value) {
        let memberIndex = newMembers.value.findIndex(m => m.userId === user.value.id);
        isOwner.value = memberIndex >= 0;
      }
    },
    {immediate: true, deep: true}
)

const confirmMemberDeletion = (event, memberId) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Do you want to remove this member from the project?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-danger p-button-sm',
    accept: () => {
      store.dispatch('project/removeProjectMember', {projectId: project.value.id, memberId}).then(
          async response => {
            await store.dispatch('project/fetchProjectMembers', project.value.id);
            toast.add({severity: 'success', summary: 'Remove member', detail: response.message, life: 3000})
          },
          error => toast.add({
            severity: 'error',
            summary: 'Failed remove member',
            detail: error.response.data.message,
            life: 3000
          })
      );
    },
  });
};
</script>


<style scoped>

</style>