<template>

  <div ref="scrollComponent" class="flex flex-row flex-wrap align-items-stretch">

    <template v-if="selectedVisualization === 'grid'">
      <OfferCard v-for="offer of offers" :key="offer.id" :offer="offer"/>
    </template>

    <template v-else>
      <OfferRowCard v-for="offer of offers" :key="offer.id" :offer="offer"/>
    </template>

  </div>

  <Loader v-if="loading"/>

  <ErrorSection v-else-if="!loading && error" icon="pi-times" text="Failed to retrieve listings"/>

  <EmptySection v-else-if="offers.length === 0" icon="pi-tag" text="No listings available yet"/>

</template>

<script setup>
import {computed, defineProps, onMounted, onUnmounted, ref} from 'vue';
import EmptySection from "@/components/sections/EmptySection.vue";
import Loader from "@/components/sections/Loader.vue";
import ErrorSection from "@/components/sections/ErrorSection.vue";
import OfferCard from "@/components/cards/OfferCard.vue";
import OfferRowCard from "@/components/cards/OfferRowCard.vue";
import store from "@/store";

const props = defineProps({
  selectedVisualization: {type: String, required: true},
  loading: {type: Boolean, required: true, default: true},
  error: {type: String, required: true, default: ""},
  offers: {type: Array, required: true},
  searchData: {type: Object, required: false},
  autoLoad: {type: Boolean, required: false, default: true},
})

const page = ref(0)
const scrollComponent = ref(null)
const totalPages = computed(() => store.state.offer.totalPages)

onMounted(async () => window.addEventListener("scroll", handleScroll))

onUnmounted(() => window.removeEventListener("scroll", handleScroll))

const handleScroll = async () => {
  if (props.autoLoad) {
    let element = scrollComponent.value
    if (element.getBoundingClientRect().bottom < window.innerHeight) {
      if (totalPages.value >= page.value + 1) {
        page.value++;
        await store.dispatch('offer/fetchOffers', {...props.searchData, page: page.value});
      }
    }
  }
}
</script>

<style scoped>

</style>