import api from "@/services/api";

class UserService {
    disconnectTwitter(user) {
        return api.put(process.env.VUE_APP_API_URL + '/user/twitter/disconnect', {user});
    }
    disconnectGoogle(user) {
        return api.put(process.env.VUE_APP_API_URL + '/user/google/disconnect', {user});
    }
    searchUsers(query) {
        return api.get(process.env.VUE_APP_API_URL + `/user/search?q=${query}`);
    }
    getUser(userId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/users/${userId}`)
            .then(response => response.data);
    }
    updateUser(user) {
        return api
            .put(process.env.VUE_APP_API_URL + "/user", user)
            .then(response => response.data);
    }
    getUsers(page, size, orderBy, orderSort, query) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/users?page=${page}&size=${size}&orderBy=${orderBy}&orderSort=${orderSort}&query=${query}`)
            .then(response => response.data);
    }
    getUserProjects() {
        return api
            .get(`${process.env.VUE_APP_API_URL}/users-projects`)
            .then(response => response.data);
    }
    getUserProject(projectId) {
        return api
            .get(`${process.env.VUE_APP_API_URL}/users-projects/${projectId}`)
            .then(response => response.data);
    }
    getUserWaitingCollabsNumber() {
        return api
            .get(`${process.env.VUE_APP_API_URL}/user-waiting-collab`)
            .then(response => response.data);
    }
    getUserOffers() {
        return api
            .get(`${process.env.VUE_APP_API_URL}/users-offers`)
            .then(response => response.data);
    }
    getUserWallets() {
        return api
            .get(`${process.env.VUE_APP_API_URL}/wallets`)
            .then(response => response.data);
    }
    createUserWallet(wallet) {
        return api
            .post(`${process.env.VUE_APP_API_URL}/wallets`, wallet)
            .then(response => response.data);
    }
    deleteUserWallet(walletId) {
        return api
            .delete(`${process.env.VUE_APP_API_URL}/wallets/${walletId}`)
            .then(response => response.data);
    }
}

export default new UserService();