<template>
  <div class="flex flex-column w-full border-round-xl surface-100 p-3 gap-3" style="max-width: 88vw;">
    <!--HEADER-->
    <div class="flex flex-row justify-content-end gap-2 flex-wrap mb-2">
      <Button v-tooltip.left="'Open winners sheet'"
              class="p-button-xs p-button-outlined p-button-success"
              icon="pi pi-file-excel"
              label="Sheet"
              @click="handleSynchronizeCollabRaffles(data)"/>
      <AddCollabRequirements v-if="data.manual || collabType === 'outbound'"
                             :giveaway-requirements="data.offer?.giveawayRequirements || []" :project-id="projectId"
                             :response-id="data.id"
                             :response-requirements="data.requirements"/>
      <CollabRequirements v-else :giveaway-requirements="data.offer?.giveawayRequirements || []"
                          :response-requirements="data.requirements"/>
      <AddCollabTemplate v-if="data.manual || collabType === 'outbound'" :project-id="projectId"
                         :response-id="data.id" :response-template="data.template"/>
      <CollabTemplate v-else :response-template="data.template"/>

      <Button aria-controls="response_overlay_menu" aria-haspopup="true"
              class="p-button-icon-only p-button-outlined p-button-xs p-button-secondary"
              icon="pi pi-ellipsis-v" type="button" @click="toggleResponseMenu($event)"/>
      <Menu id="response_overlay_menu" ref="responseMenu" :popup="true">
        <template #start>
          <Button v-if="!data.manual"
                  class="w-full p-button-text p-button-sm p-button-plain border-noround text-left" icon="pi pi-eye"
                  label="View offer" @click="viewOffer"/>
          <Button class="w-full p-button-text p-button-sm p-button-danger border-noround text-left" icon="pi pi-trash"
                  label="Delete collab" @click="deleteResponse(data.id)"/>
        </template>
      </Menu>
    </div>

    <!--INFORMATION-->
    <div class="flex flex-column gap-1">

      <div class="flex flex-row gap-2 align-items-center">
        <p class="m-0 text-md font-semibold">Proposal</p>
      </div>

      <p v-if="data.proposeThreads" class="m-0 text-sm">Threads</p>
      <p v-if="data.proposeRaids" class="m-0 text-sm">Raids</p>
      <p v-if="data.proposeAma" class="m-0 text-sm">Ama</p>
      <p v-if="data.proposeOther" class="m-0 text-sm">Other: {{ data.proposeOtherInput }}</p>

      <p class="m-0">Requested spots: {{ data.spots }}</p>

      <div class="flex flex-row gap-2 align-items-center">
        <p class="m-0 text-md font-semibold">Information</p>
        <Tag v-if="!data.manual && data.offer.project.minted"
             class="flex flex-row gap-2 align-items-center bg-transparent border-1 text-green-400"
             icon="pi pi-check-circle"
             value="Project already minted"/>
      </div>

      <template v-if="data.manual">
        <p class="m-0 text-500 text-sm">Information not available for manual collaboration</p>
      </template>

      <template
          v-else-if="!data.offer.project.blockchain && !data.offer.project.supply && !data.offer.project.averageEntries">
        <p class="m-0 text-500 text-sm">Information not available</p>
      </template>

      <div v-else class="flex flex-row gap-3 flex-wrap mt-1">

        <div v-if="data.offer.project.blockchain" class="flex flex-column justify-content-center text-sm">
          <p class="m-0 text-sm text-500 text-center">Blockchain</p>
          <div class="flex flex-row gap-2 align-items-center">
            <img :height="16" :src="getBlockchainIcon(data.offer.project.blockchain)" alt="Blockchain icon"/>
            <p class="m-0 capitalize">{{ data.offer.project.blockchain }}</p>
          </div>
        </div>

        <ProjectMintInformationText :data="data.offer.project.supply" name="supply"/>

        <ProjectMintInformationText :data="data.offer.project.averageEntries" name="GA entries"/>

        <template v-if="!data.offer.project.minted">

          <ProjectMintInformationText v-if="data.offer.project.mintDate"
                                      :data="data.offer.project.mintDate ? $moment(data.offer.project.mintDate).format('lll') : 'TBA'"
                                      name="mint date"/>
          <ProjectMintInformationText
              :data="data.offer.project.publicMintPrice === 0 ? 'Free' : data.offer.project.publicMintPrice"
              name="mint price"/>
          <ProjectMintInformationText
              :data="data.offer.project.whitelistMintPrice === 0 ? 'Free' : data.offer.project.whitelistMintPrice"
              name="whitelist mint price"/>
        </template>

      </div>
    </div>

    <!--RAFFLES-->
    <div class="flex flex-column gap-1">
      <div class="flex flex-row justify-content-between align-items-center my-2">
        <p class="m-0 text-md font-semibold">Raffles</p>
        <Button
            v-if="(collabType === 'inbound' && data.status !== 'archived' && data.status !== 'declined' && data.status !== 'created') || data.manual"
            class="p-button-xs p-button-text" icon="pi pi-plus" label="Add raffle"
            @click="addRaffleRow"/>
      </div>

      <p v-if="data.status === 'declined' || data.status === 'created'" class="m-0 text-sm text-500">
        Raffles not available
      </p>

      <p v-else-if="collabRaffles.length === 0 && raffleRow === null" class="m-0 text-sm text-500">No raffles</p>

      <div v-if="raffleRow" class="grid flex flex-row align-items-center surface-200 border-round-lg mb-2">
        <div class="col-12 lg:col-3">
          <InputText v-model="raffleRow.name"
                     :class="{'p-invalid': raffleSubmitted && raffleRow.name === ''}"
                     class="p-inputtext-sm surface-50"
                     placeholder="Name"/>
        </div>
        <div class="col-12 lg:col-3">
          <InputText v-model="raffleRow.link"
                     :class="{'p-invalid': raffleSubmitted && raffleRow.link === ''}"
                     class="p-inputtext-sm surface-50"
                     placeholder="Link"/>
        </div>
        <div class="col-12 lg:col-3 flex flex-row gap-1 align-items-center">
          <InputMask v-model="raffleRow.endDate"
                     :class="{'p-invalid': raffleSubmitted && raffleRow.endDate !== null && moment(raffleRow.endDate) < moment()}"
                     class="p-inputtext-sm"
                     mask="99/99/9999 99:99 aa"
                     placeholder="End date"/>
        </div>
        <div class="col-12 lg:col-3 flex flex-row gap-1">
          <Button :loading="saveRaffleLoading"
                  class="p-button-sm p-button-success p-button-text" icon="pi pi-check"
                  label="Save"
                  @click="saveCollabRaffles"/>
          <Button class="p-button-sm p-button-plain p-button-text"
                  label="Cancel"
                  @click="cancelCollabRaffleEdition"/>
        </div>
      </div>

      <div v-for="raffle in collabRaffles" :key="`gr${raffle.id}`" class="flex flex-column">
        <div class="grid flex flex-row align-items-center surface-200 border-round-lg my-1 flex-wrap">
          <div class="col-3 lg:col max-w-10rem">
            <Dropdown v-if="!raffle.endDate" :model-value="raffleStatusList.find(rs => rs.value === raffle.status)"
                      :options="raffleStatusList"
                      class="border-300 p-inputtext-sm bg-transparent w-full"
                      @change="handleRaffleStatusChange($event, raffle)">
              <template #value="slotProps">
                <Tag :class="`bg-${slotProps.value.color}`" :value="slotProps.value.label" class="capitalize"/>
              </template>
              <template #option="slotProps">
                <Tag :class="`bg-${slotProps.option.color}`" :value="slotProps.option.label" class="capitalize"/>
              </template>
            </Dropdown>
            <Tag v-else :class="`bg-${raffleStatusList.find(rs => rs.value === raffle.status).color}`"
                 :value="raffleStatusList.find(rs => rs.value === raffle.status).label"
                 class="capitalize white-space-nowrap"
                 style="margin-left: 0.65625rem;"/>
          </div>
          <div class="col-9 lg:col-2 flex align-items-center">
            <p v-tooltip.top="raffle.name" class="m-0 capitalize text-sm cropped">{{ raffle.name }}</p>
          </div>
          <div class="col-8 lg:col-3">
            <Button :label="raffle.link.replace('https://www.alphabot.app/', '').substring(0,18)"
                    class="p-button-xs p-button-link text-sm w-full" icon="pi pi-arrow-up-right"
                    icon-pos="right"
                    @click="openExternalLink(raffle.link)"/>
          </div>
          <div class="col-4 lg:col-2 flex flex-row align-items-center">
            <p v-if="raffle.endDate" class="text-center m-0 text-sm w-full">
              {{ getRemainingTime(raffle.endDate) }}
            </p>
          </div>
          <div v-if="user.id === data.userId"
               class="col-12 lg:col flex justify-content-end align-items-center gap-1">
            <Button
                v-if="raffle.winners === null && raffle.link.includes('alphabot.app/') && (moment(raffle.endDate) <= moment().utc() || raffle.status === 'ended')"
                class="p-button-xs p-button-outlined" icon="pi pi-download" icon-pos="left" label="Winners"
                @click="getCollabWinners(raffle)"/>
            <Button
                v-if="raffle.winners !== null"
                class="p-button-xs p-button-outlined" icon="pi pi-copy" icon-pos="left" label="Winners"
                @click="copyWinners(raffle.winners)"/>
            <Button
                v-tooltip.left="'Open winners sheet'"
                :loading="spreadsheetLoading"
                class="p-button-xs p-button-outlined p-button-success"
                icon="pi pi-file-excel"
                icon-pos="left"
                @click="openRaffleWinnersSpreadsheet(raffle)"/>
            <Button
                v-tooltip.left="collabType === 'inbound' || data.manual && data.status !== 'archived' ? 'Delete raffle': 'Disabled'"
                :disabled="!(collabType === 'inbound' || data.manual && data.status !== 'archived')"
                class="p-button-icon-only p-button-xs p-button-outlined p-button-danger"
                icon="pi pi-trash"
                @click="handleDeleteRaffle(raffle.id)"/>
          </div>
        </div>
      </div>
    </div>

    <!--NOTE-->
    <div class="flex flex-column gap-1">
      <div class="flex flex-row justify-content-between align-items-center my-2">
        <p class="m-0 text-md font-semibold">Note</p>
        <Button v-if="!editNote" class="p-button-xs p-button-text" icon="pi pi-pencil" label="Edit"
                @click="editNote = true"/>
      </div>
      <template v-if="editNote">
        <Textarea v-if="editNote" v-model="noteInput" class="surface-50" rows="3"/>
        <div class="flex flex-row justify-content-end gap-2">
          <Button class="p-button-xs p-button-text p-button-plain"
                  label="Cancel"
                  @click="cancelCollabNoteEdition"/>
          <Button
              class="p-button-xs"
              label="Save"
              @click="saveCollabNote"/>
        </div>
      </template>
      <p v-else-if="data.note" class="m-0 text-sm">{{ data.note }}</p>
      <p v-else class="m-0 text-sm text-500">No notes recorded</p>
    </div>
  </div>
</template>

<script setup>
import {computed, defineProps, ref, watch} from "vue";
import router from "@/router";
import OfferResponseService from "@/services/offerResponse.service";
import store from "@/store";
import {useToast} from "primevue/usetoast";
import {getBlockchainIcon} from "@/utils/blockchains";
import ProjectMintInformationText from "@/components/ProjectMintInformationText.vue";
import AddCollabRequirements from "@/components/modals/Collab/AddCollabRequirements.vue";
import CollabRequirements from "@/components/modals/Collab/CollabRequirements.vue";
import AddCollabTemplate from "@/components/modals/Collab/AddCollabTemplate.vue";
import CollabTemplate from "@/components/modals/Collab/CollabTemplate.vue";
import RaffleService from "@/services/raffle.service";
import {openExternalLink} from "@/utils/openLink";
import moment from "moment";
import {raffleStatusList} from "@/utils/dropdownItems";
import {getRemainingTime} from "@/utils/time";

const props = defineProps({
  data: {type: Object, required: true},
  projectId: {type: String, required: true},
  collabType: {type: String, required: true},
});

const user = computed(() => store.state.auth.user);

const toast = useToast();
const editNote = ref(false)
const editRaffles = ref(false);
const spreadsheetLoading = ref(false);
const collabSpreadsheetLoading = ref(false);
const saveRaffleLoading = ref(false);
const raffleSubmitted = ref(false);
const noteInput = ref("");
const raffleRow = ref(null);
const responseMenu = ref();
const collabRaffles = ref([]);

const toggleResponseMenu = (event) => responseMenu.value.toggle(event);

const addRaffleRow = () => {
  raffleSubmitted.value = false;
  raffleRow.value = {name: "", link: "", endDate: null};
}

const cancelCollabRaffleEdition = () => {
  editRaffles.value = false;
  raffleRow.value = null;
}

const loadRaffles = () => {
  RaffleService.getRaffleFromCollab(props.data.id).then(
      response => {
        collabRaffles.value = response.data
      }
  )
}

const handleDeleteRaffle = (index) => {
  RaffleService.deleteRaffle(index).then(
      response => {
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        loadRaffles();
      },
      error => {
        toast.add({severity: 'error', summary: 'Error', detail: error.message, life: 3000});
      }
  )
}

const saveCollabRaffles = async () => {
  saveRaffleLoading.value = true;
  raffleSubmitted.value = true;

  if (raffleRow.value.link === "" || raffleRow.value.name === "") {
    saveRaffleLoading.value = false;
    return;
  }
  if (raffleRow.value.endDate !== null && moment(raffleRow.value.endDate) < moment()) {
    saveRaffleLoading.value = false;
    return;
  }

  await RaffleService.createRaffle({
    ...raffleRow.value,
    endDate: moment(raffleRow.value.endDate).toISOString()
  }, props.data.id).then(
      response => {
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        raffleRow.value = null;
        loadRaffles();
      },
      error => {
        toast.add({severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000});
      }
  )

  saveRaffleLoading.value = false;
}

const saveCollabNote = () => {
  OfferResponseService.updateOfferResponse(props.data.id, {note: noteInput.value})
      .then(async response => {
        await store.dispatch('offerResponse/fetchOfferResponses', props.projectId);
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
        editNote.value = false;
      })
      .catch(error => {
        toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      });
}

const cancelCollabNoteEdition = () => {
  editNote.value = false;
  noteInput.value = "";
}

const handleRaffleStatusChange = (evt, raffle) => {
  RaffleService.updateRaffle(raffle.id, {status: evt.value.value})
      .then(async response => {
        await store.dispatch('offerResponse/fetchOfferResponses', props.projectId);
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      })
      .catch(error => {
        toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      });
}

const viewOffer = () => router.push(`/${props.data.type}/${props.data.offer.project.name.replaceAll(' ', '-').toLowerCase()}/${props.data.offer.id}`);

const deleteResponse = (id) => {
  OfferResponseService.deleteOfferResponse(id)
      .then(
          async () => {
            await store.dispatch('offerResponse/fetchOfferResponses', props.projectId)
            toast.add({
              severity: 'success',
              summary: 'Success',
              detail: "Listing response deleted",
              life: 3000
            })
          },
          error => toast.add({
            severity: 'error',
            summary: 'Failed',
            detail: error.response.data.message || error.message,
            life: 3000
          }),
      )
}

const copyWinners = async (winners) => {
  await navigator.clipboard.writeText(winners);
  toast.add({severity: 'info', summary: 'Copied', detail: "Winners copied to clipboard", life: 3000});
}

const getCollabWinners = async (raffle) => {
  if (raffle.winners) {
    await copyWinners(raffle.winners);
  } else {
    RaffleService.getRaffleWinners(raffle.id)
        .then(
            async (response) => {
              if (response.data) {
                await copyWinners(raffle.winners);
                loadRaffles()
              } else {
                toast.add({
                  severity: 'warn',
                  summary: 'Not available',
                  detail: "Winners not available for now",
                  life: 3000
                });

              }
            },
            error => {
              toast.add({
                severity: error.response.status === 429 ? 'warn' : 'error',
                summary: error.response.status === 429 ? 'Alphabot limit' : 'Failed to get winners',
                detail: error.response.data.message,
                life: 3000
              });
            }
        )
  }
}

const openRaffleWinnersSpreadsheet = (raffle) => {
  spreadsheetLoading.value = true;
  if (raffle.spreadSheetId) {
    openExternalLink(`https://docs.google.com/spreadsheets/d/${raffle.spreadSheetId}`);
  } else {
    RaffleService.createRaffleSpreadSheet(raffle).then(
        response => {
          loadRaffles();
          openExternalLink(`https://docs.google.com/spreadsheets/d/${response.raffle.spreadSheetId}`);
        },
        error => {
          toast.add({severity: 'error', summary: 'Error', detail: error.response.data.message, life: 3000});
        }
    )
  }
  spreadsheetLoading.value = false;
}

const handleSynchronizeCollabRaffles = (collab) => {
  collabSpreadsheetLoading.value = true;

  if (collab.raffles.length > 0 || collabRaffles.value.length > 0) {
    toast.add({
      severity: 'info',
      summary: 'Synchronization',
      detail: "Synchronization in progress, please wait. The spreadsheet will open once finished.",
      life: 3000
    });
    OfferResponseService.synchronizeCollabRafflesSpreadsheet(collab.id, collab.spreadSheetId).then(
        response => {
          if (response.data) {
            toast.add({
              severity: 'success',
              summary: 'Raffles winners synchronized',
              detail: response.message,
              life: 3000
            });
            openExternalLink(`https://docs.google.com/spreadsheets/d/${response.data}`);
          } else {
            toast.add({severity: 'error', summary: 'No data', detail: response.message, life: 3000});
          }
        },
        error => {
          toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
        }
    )
  } else {
    toast.add({
      severity: 'warn',
      summary: 'No raffles',
      detail: "No raffle has been recorded for this collaboration. Adds raffles to access the general sheet.",
      life: 3000
    });
  }
  collabSpreadsheetLoading.value = false;
}

watch(
    () => props.data,
    (newData) => {
      collabRaffles.value = newData.raffles;
      noteInput.value = newData.note;
    },
    {immediate: true}
)

</script>

<style scoped>

</style>