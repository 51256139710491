import NotificationService from "@/services/notification.service";
import store from "@/store/index";

Array.prototype.unique = function () {
    let a = this.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

const initialState = {
    notifications: [],
    unreadNotifications: 0,
    totalPages: 0,
    loading: false,
    error: null,
};
export const notification = {
    namespaced: true,
    state: initialState,
    actions: {
        async fetchNotifications({commit, state}, payload = {page: Number, size: Number}) {
            if (store.state.auth.status.loggedIn) {
                state.error = "";
                const {page = 0, size = 20} = payload;
                if (page === 0) state.loading = true;
                return await NotificationService.getNotifications(page, size).then(
                    response => {
                        commit('fetchNotificationsSuccess', response);
                        return Promise.resolve(response);
                    },
                    error => {
                        commit('fetchFailure', error);
                        return Promise.reject(error);
                    }
                );
            }
        },
    },
    mutations: {
        fetchNotificationsSuccess(state, notifications) {
            state.loading = false;
            state.unreadNotifications = notifications.unreadCount;
            state.totalPages = notifications.totalPages;
            state.notifications = notifications.currentPage === 0 ? notifications.items : [...state.notifications, ...notifications.items];
            /*state.notifications = [...state.notifications, ...notifications.items];
            state.notifications = state.notifications.filter((not, index) => {
                return index === state.notifications.findIndex(n => not.id === n.id);
            });*/
        },
        fetchFailure(state, error) {
            state.notifications = [];
            state.loading = false;
            state.error = error.response?.data?.message || error.message;
        },
    }
}