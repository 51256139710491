export const getProjectName = (collab, type) => {
    let value;

    if (collab.manual) {
        value = collab.projectXHandle.toUpperCase();
    } else if ((type === 'outbound' && collab.offer.type === 'requesting') || (type === 'inbound' && collab.offer.type === 'offering')) {
        value = collab.offer.project.name.toUpperCase();
    } else {
        value = collab.project.name.toUpperCase();
    }

    return value
}

export const getContactName = (collab) => {
    return collab.manual ? collab.contactXHandle.toUpperCase() : collab.creator.username.toUpperCase();
}

export const getCollabManagerName = (collab) => {
        return collab.manual ? collab.creator.username.toUpperCase() : collab.offer.user.username.toUpperCase();
}