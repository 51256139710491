<template>
  <Dialog :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :closable="false"
          :draggable="false"
          :style="{ width: '500px', maxWidth: '98vw'}"
          :visible="visibleAddExistingTeamMembers"
          header="Project members" modal>
    <div class="flex flex-column gap-2 pt-2" style="min-height: 30vh;">


      <p class="m-0">Collab managers on same project</p>

      <div v-for="(member, mIdx) of members" :key="`member${mIdx}`"
           class="flex flex-row justify-content-between align-items-center w-full">
        <div class="flex flex-row align-items-center gap-2 py-2">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${member.user.discordId}/${member.user.avatar}`" shape="circle"/>
          <p class="m-0 text-500 capitalize">{{ member.user.username }}</p>
        </div>
        <div v-if="member.user.id !== currentUser.id" class="flex flex-row gap-2 align-items-center">

          <template v-if="membersToAdd.indexOf(member.user) === -1">
            <Button class="p-button-sm p-button-text"
                    icon="pi pi-plus"
                    label="Add"
                    @click="addMember(member.user)"/>
          </template>

          <template v-else>
            <Dropdown :class="{'p-invalid': submitted && !member.role}"
                      :model-value="membersToAdd[membersToAdd.indexOf(member.user)].role"
                      :options="memberRoleList"
                      class="w-full md:w-14rem p-inputtext-sm" o
                      option-label="label"
                      option-value="value"
                      placeholder="Select a Role"
                      @change="updateMemberRole($event, mIdx)"/>
            <Button class="p-button-sm p-button-danger p-button-text p-button-icon-only"
                    icon="pi pi-times"
                    @click="removeProjectMember(mIdx)"/>
          </template>
        </div>
        <p v-else class="m-0 pr-4 font-semibold capitalize">{{ member.role.replaceAll("_", " ") }}</p>
      </div>
    </div>

    <div class="flex flex-row justify-content-end gap-2 flex-wrap mt-2">
      <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="cancelAddTeamMembers"/>
      <Button class="p-button-sm" label="Add members" @click="addTeamMembers"/>
    </div>
  </Dialog>
</template>

<script setup>
import {computed, defineProps, ref} from "vue";
import store from "@/store";
import router from "@/router";
import ProjectService from "@/services/project.service";
import {memberRoleList} from "@/utils/dropdownItems";
import {useToast} from "primevue/usetoast";

const toast = useToast();
const currentUser = computed(() => store.state.auth.user)
const membersToAdd = ref([]);
const submitted = ref(false);

const props = defineProps({
  visibleAddExistingTeamMembers: {type: Boolean, require: true},
  members: {type: Array, require: true},
  projectId: {type: String, require: true},
})

const updateMemberRole = (evt, index) => membersToAdd.value[index].role = evt.value;
const removeProjectMember = (index) => membersToAdd.value.splice(index, 1);
const addMember = (member) => membersToAdd.value.push(member);

const cancelAddTeamMembers = () => {
  store.dispatch('user/fetchUserProjects');
  router.push("/dashboard");
  toast.add({
    severity: 'success',
    summary: 'Project created',
    detail: "Project successfully created",
    life: 3000
  });
}

const addTeamMembers = () => {
  ProjectService.addProjectMembersOld(props.projectId, membersToAdd.value).then(
      response => {
        toast.add({
          severity: 'success',
          summary: 'Success',
          detail: response.message,
          life: 3000
        });
        store.dispatch('user/fetchUserProjects');
        router.push("/dashboard");
      },
      error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message,
          life: 3000
        });
      }
  )
}
</script>

<style scoped>

</style>