<template>
  <Button class="p-button-sm p-button-text" icon="pi pi-plus" label="Add members"
          @click="visibleSearchUserModal = true"/>

  <Dialog v-model:visible="visibleSearchUserModal" :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :draggable="false"
          :style="{ width: '500px', maxWidth: '98vw'}"
          header="Project members" modal>
    <div class="flex flex-column gap-2 pt-2" style="min-height: 30vh;">

      <div class="p-inputgroup flex align-items-center">
        <InputText v-model="query"
                   class="border-300 p-inputtext-sm flex flex-1"
                   placeholder="Find user"/>
      </div>

      <div v-if="searchedUsers.length <= 0"
           class="flex flex-column gap-2 py-6 align-items-center justify-content-center h-full">
        <i class="text-300 pi pi-users text-3xl"/>
        <small class="text-300">No user found</small>
      </div>

      <div v-else class="flex flex-column">
        <div v-for="user of searchedUsers" :key="user.id"
             class="flex flex-row justify-content-between align-items-center w-full">
          <div class="flex flex-row align-items-center gap-2 py-2">
            <Avatar :image="`https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}`" shape="circle"/>
            <p class="m-0 text-600 capitalize">{{ user.username }}</p>
          </div>
          <Button v-if="!listMembers.some(member => member['id'] === user.id)"
                  class="p-button-sm p-button-success p-button-text py-1 px-2"
                  icon="pi pi-plus"
                  label="Add"
                  @click="addUserToList(user)"/>
        </div>
      </div>

    </div>
  </Dialog>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import store from "@/store";

const visibleSearchUserModal = ref(false);

const searchedUsers = computed(() => store.state.outreachList.searchedUsers);
const listMembers = computed(() => store.state.outreachList.listMembers);

const query = ref("");
watch(
    () => query.value,
    (newQuery) => {
      store.dispatch('outreachList/fetchSearchUsers', newQuery);
    },
    {immediate: true}
)

const addUserToList = (user) => store.dispatch("outreachList/addUserToList", user);

</script>

<style scoped>

</style>