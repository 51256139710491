export const solanaIcon = require('@/assets/images/icons/blockchains/solana-icon.svg');
export const ethereumIcon = require('@/assets/images/icons/blockchains/ethereum-icon.svg');
export const multiverseXIcon = require('@/assets/images/icons/blockchains/multiversex-icon.svg');
export const bitcoinIcon = require('@/assets/images/icons/blockchains/bitcoin-icon.svg');
export const polygonIcon = require('@/assets/images/icons/blockchains/polygon-icon.svg');
export const suiIcon = require('@/assets/images/icons/blockchains/sui-icon.svg');
export const venomIcon = require('@/assets/images/icons/blockchains/venom-icon.svg');
export const cardanoIcon = require('@/assets/images/icons/blockchains/cardano-icon.svg');
export const aptosIcon = require('@/assets/images/icons/blockchains/aptos-icon.svg');

export const getBlockchainIcon = (blockchain) => {
    if (blockchain === 'ethereum') return ethereumIcon
    else if (blockchain === 'solana') return solanaIcon
    else if (blockchain === 'multiverseX') return multiverseXIcon
    else if (blockchain === 'bitcoin') return bitcoinIcon
    else if (blockchain === 'polygon') return polygonIcon
    else if (blockchain === 'sui') return suiIcon
    else if (blockchain === 'venom') return venomIcon
    else if (blockchain === 'cardano') return cardanoIcon
    else if (blockchain === 'aptos') return aptosIcon
    else return "";
}

