<template>
  <div class="flex flex-column container px-2 md:px-8">

    <div class="grid row-gap-1 py-8">
      <div class="col-12 md:col-6 flex flex-column gap-4">
        <h1 class="text-4xl md:text-5xl font-bold m-0">
          Search, list, and explore whitelist opportunities for your communities
        </h1>
        <p class="text-600 m-0 text-sm">
          IND3X aims to open the information that surrounds projects and make it publicly available for anyone to see.
          With our very own Discord bot, we plan to integrate it into many servers to increase the awareness of every
          listing made.
        </p>

        <div class="flex flex-row gap-2 flex-wrap">
          <router-link to="/dashboard">
            <Button class="c-scale" label="Dashboard"/>
          </router-link>
          <router-link to="/new-project">
            <Button class="p-button-outlined c-scale text-purple-400"
                    label="Create a Project"/>
          </router-link>
        </div>

      </div>
      <div class="col-12 md:col-6 flex align-items-center">
        <img alt="Collaborators picture" class="w-full h-auto"
             src="@/assets/images/illustrations/collaborators_old.svg"/>
      </div>
    </div>

    <div class="surface-section px-2 py-6">
      <div class="p-4 shadow-2 flex flex-column md:flex-row align-items-center justify-content-between border-round-xl"
           style="background-image: linear-gradient(to right top, #cd3bad, #bb48bc, #a654c9, #8d5ed3, #6f67da, #5773e1, #397ee6, #0088e8, #0098ea, #00a6e9, #00b3e6, #2fbfe1);"
      >
        <div class="pr-4">
          <p class="text-blue-100 font-medium text-lg m-0">Introducing: IND3X Connect</p>
          <p class="text-blue-200 m-0 mb-2 text-sm font-italic">
            We provide leads to you, you provide value to your community
          </p>
          <p class="text-white font-medium text-xl m-0">
            Our weekly newsletter where we provide the latest alpha on upcoming projects, immediate leads to these projects, and our latest giveaways on our Discord.
          </p>
        </div>
        <div class="mt-4 mr-auto md:mt-0 md:mr-0">
          <Button class="p-button-warning p-button-rounded p-button-raised white-space-nowrap"
                  label="Subscribe now"
                  @click="openExternalLink('https://ind3x.beehiiv.com/subscribe')"/>
        </div>
      </div>
    </div>

    <ListPreviewHeader path="/projects" title="Most popular projects"/>
    <ProjectsVisualization :error="projectState.error" :loading="projectState.loading" :projects="projectState.projects"
                           selected-visualization="grid"/>

    <ListPreviewHeader path="/listings" title="Most recent offers"/>
    <OffersVisualization :auto-load="false" :error="offerState.error" :loading="offerState.loading"
                         :offers="offerState.offers"
                         selected-visualization="grid"/>

    <ListPreviewHeader path="/collab-managers" title="Most recent collab managers"/>
    <CollabManagersVisualization :collab-managers="collabManagerState.users" :error="collabManagerState.error"
                                 :loading="collabManagerState.loading" selected-visualization="grid"/>

  </div>
</template>

<script>
import ListPreviewHeader from "@/components/ListVisualization/ListPreviewHeader.vue";
import TokenService from "@/services/token.service";
import store from "@/store";
import ProjectsVisualization from "@/components/ListVisualization/ProjectsVisualization.vue";
import OffersVisualization from "@/components/ListVisualization/OffersVisualization.vue";
import CollabManagersVisualization from "@/components/ListVisualization/CollabManagersVisualization.vue";
import {useMeta} from "vue-meta";
import {openExternalLink} from "@/utils/openLink";

export default {
  name: "HomePage",
  methods: {openExternalLink},
  setup() {
    useMeta({
      description: "By collab managers, for collab managers",
      meta: [
        {property: 'og:title', name: 'twitter:title', content: ""},
        {property: 'og:description', name: 'twitter:description', content: "By collab managers, for collab managers"},
        {
          property: 'og:image',
          name: 'twitter:image',
          content: "https://pbs.twimg.com/profile_banners/786058102516944897/1685087544/1500x500"
        },
      ]
    })
  },
  components: {
    CollabManagersVisualization,
    OffersVisualization, ProjectsVisualization, ListPreviewHeader
  },
  computed: {
    collabManagerState() {
      return this.$store.state.user;
    },
    projectState() {
      return this.$store.state.project;
    },
    offerState() {
      return this.$store.state.offer;
    },
  },
  async mounted() {
    await store.dispatch('project/fetchProjects', {
      size: 3,
      order: {label: "Most Popular", by: "popular", sort: "asc"}
    });
    await store.dispatch('offer/fetchOffers', {size: 3});
    await store.dispatch('user/fetchUsers', {size: 3});

    const queryParameters = this.$route["query"];

    if ('token' in queryParameters) {
      await TokenService.updateLocalAccessToken(queryParameters.token);
      this.$store.dispatch('auth/login')
          .then(() => {
            this.$router.push('/');
          })
          .catch(() => {
            alert("Failed to login.")
          });
    }

    if ('message' in queryParameters) {
      alert(queryParameters.message.toString().replaceAll('+', ' ').replaceAll('%20', ' '))
    }
  },
}
</script>

<style scoped>

</style>