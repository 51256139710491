import store from "@/store";

class TokenService {
    getLocalRefreshToken() {
        const user = this.getUser();
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = this.getUser();
        return user?.accessToken;
    }

    updateLocalAccessToken(token) {
        let user = this.getUser();
        user = user ? {...user, accessToken: token} : {accessToken: token}
        this.setUser(user);
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    async updateUser(updatedUser) {
        if (updatedUser) {
            const user = JSON.parse(localStorage.getItem('user'));
            localStorage.setItem('user', JSON.stringify({...user, ...updatedUser}));
        } else {
            await store.dispatch("auth/logout")
        }
    }

    async setUser(user) {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            await store.dispatch("auth/setConnectedUser", user);
        } else {
            await store.dispatch('auth/logout');
        }
    }

    removeUser() {
        localStorage.removeItem('user');
    }
}

export default new

TokenService();