<template>
  <div class="flex flex-row justify-content-between align-items-center w-full">
    <div class="flex flex-row align-items-center gap-2 py-2">
      <Avatar :image="`https://cdn.discordapp.com/avatars/${member['discordId']}/${member['avatar']}`" shape="circle"/>
      <p class="m-0 text-500 capitalize">{{ member['username'] }}</p>
    </div>
    <div v-if="member['id'] !== currentUser.id" class="flex flex-row gap-2 align-items-center">
      <Dropdown :class="{'p-invalid': submitted && !member['role']}"
                :model-value="member['role']"
                :options="memberRoleList"
                class="w-full md:w-14rem p-inputtext-sm" o
                option-label="label"
                option-value="value"
                placeholder="Select a Role"
                @change="updateMemberRole($event, index)"/>
      <Button class="p-button-sm p-button-danger p-button-outlined"
              icon="pi pi-times"
              label="Remove"
              @click="removeProjectMember(index)"/>
    </div>
    <p v-else class="m-0 pr-4 font-semibold capitalize">{{ member.role.replaceAll("_", " ") }}</p>
  </div>
</template>

<script setup>
import {computed, defineProps} from 'vue';
import {memberRoleList} from "@/utils/dropdownItems";
import store from "@/store";

defineProps({
  member: Object,
  index: Number,
  submitted: Boolean,
  removeProjectMember: Function,
  updateMemberRole: Function,
});

const currentUser = computed(() => store.state.auth.user)

</script>

<style scoped>

</style>