<template>
  <div class="flex flex-row justify-content-end align-items-center gap-1 px-1">
    <Button v-if="twitter"
            :class="{'text-white': !isRow}"
            class="p-button-sm p-button p-button-text p-button-rounded p-button-icon-only social-button bg-transparent hover:bg-primary"
            style="height: 2.5rem; width: 2.5rem;"
            @click.stop="openExternalLink(`https://x.com/${twitter}`)">
      <XIcon/>
    </Button>
    <Button v-if="opensea"
            :class="{'text-white': !isRow}"
            class="p-button-sm p-button p-button-text p-button-rounded p-button-icon-only social-button bg-transparent hover:bg-primary"
            style="height: 2.5rem; width: 2.5rem;"
            @click.stop="openExternalLink(`https://x.com/${twitter}`)">
      <OpenseaIcon/>
    </Button>
    <Button v-if="discord"
            :class="{'text-white': !isRow}"
            class="p-button-sm p-button p-button-text p-button-rounded p-button-icon-only social-button bg-transparent hover:bg-primary"
            icon="pi pi-discord"
            style="height: 2.5rem; width: 2.5rem;"
            @click.stop="isDiscordLink ? openExternalLink(discord) : copyToClipBoard(discord)"/>
    <Button v-if="website"
            :class="{'text-white': !isRow}"
            class="p-button-sm p-button p-button-text p-button-rounded p-button-icon-only social-button bg-transparent hover:bg-primary"
            icon="pi pi-globe"
            style="height: 2.5rem; width: 2.5rem;"
            @click.stop="openExternalLink(website)"/>
  </div>
</template>

<script setup>
import {openExternalLink} from "@/utils/openLink";
import {defineProps} from 'vue';
import {useToast} from "primevue/usetoast";
import XIcon from "@/components/icons/XIcon.vue";
import OpenseaIcon from "@/components/icons/OpenseaIcon.vue";

defineProps({
  twitter: {type: String, required: false},
  opensea: {type: String, required: false},
  discord: {type: String, required: false},
  website: {type: String, required: false},
  isRow: {type: Boolean, required: false, default: false},
  isDiscordLink: {type: Boolean, required: false, default: false},
})

const toast = useToast()
const copyToClipBoard = async (discord) => {
  await navigator.clipboard.writeText(discord);
  toast.add({severity: 'info', summary: 'Copied', detail: "Discord username copied to clipboard", life: 3000});
}
</script>

<style scoped>
.social-button:hover {
  backdrop-filter: blur(3px) !important;
}

</style>