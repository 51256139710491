<template>
  <BackButton to="/dashboard/outreach-list"/>

  <div class="flex flex-row justify-content-between align-items-center flex-nowrap">

    <div class="flex flex-column mt-2">
      <small class="text-400 uppercase">Outreach list</small>
      <h1 class="m-0 text-700 capitalize">{{ userList.name }}</h1>
      <p class="m-0 text-600" style="word-wrap: break-word">{{ userList.description }}</p>
    </div>

    <Button aria-controls="overlay_menu"
            aria-haspopup="true"
            class="p-button-sm p-button-plain p-button-text p-button-icon-only"
            icon="pi pi-ellipsis-v"
            @click="toggle"/>
    <Menu id="overlay_menu" ref="optionMenu" :model="optionMenuItems" :popup="true"/>
  </div>

  <DataTable :value="userList.listMembers"
             class="p-datatable-sm mt-3"
             tableStyle="min-width: 100%">
    <template #empty>
      <p class="text-center text-500"> No user in this list found. </p>
    </template>
    <Column field="name" header="Name">
      <template #body="{ data }">
        <div class="flex flex-row gap-2 align-items-center">
          <Avatar :image="`https://cdn.discordapp.com/avatars/${data.user.discordId}/${data.user.discordAvatar}`"
                  class="w-2rem h-2rem" shape="circle"/>
          <span class="capitalize">{{ data.user.username }}</span>
        </div>
      </template>
    </Column>

    <Column>
      <template #body="{ data }">
        <div class="flex flex-row justify-content-end">
          <a v-if="data.user.twitterUsername"
             :href="`https://x.com/${data.user.twitterUsername}`"
             class="p-button p-button-sm p-button-text gap-2"
             style="color: #2bc4ff;"
             target="_blank">
            <XIcon/>
            Go to X
          </a>
          <a class="p-button p-button-sm p-button-text gap-2"
             style="color: #7289DA;"
             @click="copyToClipBoard(data.user.discordUsername)">
            <i class="pi pi-discord"/>
            Copy Discord Name
          </a>
        </div>
      </template>
    </Column>
  </DataTable>

</template>

<script setup>
import {computed, defineProps, ref, watch} from "vue";
import BackButton from "@/components/buttons/BackButton.vue";
import store from "@/store";
import OutreachListService from "@/services/outreachList.service";
import {useToast} from "primevue/usetoast";
import router from "@/router";
import XIcon from "@/components/icons/XIcon.vue";

const toast = useToast();

const props = defineProps({
  id: {
    type: String,
    required: true,
  }
})

const copyToClipBoard = async (discord) => {
  await navigator.clipboard.writeText(discord);
  toast.add({severity: 'info', summary: 'Copied', detail: "Discord username copied to clipboard", life: 3000});
}

const optionMenu = ref();
const optionMenuItems = ref([
  {
    label: 'Edit',
    icon: "pi pi-pencil",
    command: () => router.push({name: 'edit-outreach-list', params: {id: userList.value.id}})
  },
  {label: 'Delete', icon: "pi pi-trash", command: () => deleteOutreachList()},
]);

const toggle = (event) => optionMenu.value.toggle(event);

const userList = computed(() => store.state.outreachList.userList);

watch(
    () => props.id,
    async (newId) => {
      if (newId) await store.dispatch("outreachList/fetchOutreachList", newId);
    },
    {immediate: true}
)

const deleteOutreachList = () => {
  OutreachListService.deleteUserList(userList.value.id)
      .then(response => {
        toast.add({severity: 'success', summary: 'Deleted', detail: response.message, life: 3000});
        store.dispatch("outreachList/fetchOutreachLists");
        router.push('/dashboard/outreach-list');
      })
      .catch(error => {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message,
          life: 3000
        });
      })
}

</script>

<style scoped>

</style>