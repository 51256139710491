<template>
  <div class="grid p-2">
    <div class="col-12 flex flex-row justify-content-between align-items-center">
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project links</h2>
      <div class="flex flex-row align-items-center gap-2">
        <Button v-if="!edition" class="p-button-sm p-button-text" icon="pi pi-pencil" label="Edit"
                @click="edition = true"/>
        <template v-else>
          <Button class="p-button-sm p-button-text p-button-success" icon="pi pi-save" label="Save"
                  @click="handleSaveLinks"/>
          <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="cancelLinksEdition"/>
        </template>
      </div>
    </div>

    <template v-if="projectForm">

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-3">
        <label for="discord">Discord invite link</label>
        <InputText id="discord" v-model="projectForm.discord"
                   :class="{'p-invalid': submitted && projectForm.discord !== '' && !projectForm.discord.startsWith('https://')}"
                   :disabled="!edition"
                   aria-describedby="discord-help"
                   class="p-inputtext-sm"
                   placeholder="https://discord.com/invite/DMzy3r9jR6"/>
        <small v-if="submitted && projectForm.discord !== '' && !projectForm.discord.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-3">
        <label for="website">Website</label>
        <InputText id="website" v-model="projectForm.website"
                   :class="{'p-invalid': submitted && projectForm.website !== '' && !projectForm.website.startsWith('https://')}"
                   :disabled="!edition"
                   aria-describedby="website-help"
                   class="p-inputtext-sm"
                   placeholder="https://ind3x.app"/>
        <small v-if="submitted && projectForm.website !== '' && !projectForm.website.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-3">
        <label for="opensea">Opensea</label>
        <InputText id="opensea" v-model="projectForm.opensea"
                   :class="{'p-invalid': submitted && projectForm.opensea !== '' && !projectForm.opensea.startsWith('https://')}"
                   :disabled="!edition"
                   aria-describedby="opensea-help"
                   class="p-inputtext-sm"
                   placeholder="https://opensea.io/collection/ind3x"/>
        <small v-if="submitted && projectForm.opensea !== '' && !projectForm.opensea.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-3">
        <label for="spreadsheet">Google spreadsheet link</label>
        <InputText id="spreadsheet" v-model="projectForm.spreadsheet"
                   :class="{'p-invalid': submitted && projectForm.spreadsheet !== '' && !projectForm.spreadsheet.startsWith('https://')}"
                   :disabled="!edition"
                   aria-describedby="spreadsheet-help"
                   class="p-inputtext-sm"
                   placeholder="https://docs.google.com/spreadsheets"/>
        <small v-if="submitted && projectForm.spreadsheet !== '' && !projectForm.spreadsheet.startsWith('https://')"
               class="p-error">
          The url must start with 'https://'
        </small>
      </div>

    </template>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref, watch} from "vue";
import store from "@/store";
import ProjectService from "@/services/project.service";
import {useToast} from "primevue/usetoast";

const toast = useToast();
const project = computed(() => store.state.user.userProject)
const projectForm = ref(null)
const submitted = ref(false)
const edition = ref(false)

watch(
    () => project,
    (newProject) => {
      if (newProject.value && Object.keys(newProject.value).length > 0) {
        projectForm.value = Object.assign({}, newProject.value);
      }
    },
    {immediate: true, deep: true}
)

onBeforeUnmount(() => cancelLinksEdition());

const handleSaveLinks = async () => {
  submitted.value = true;

  await ProjectService.updateProject(projectForm.value, project.value.id).then(
      async response => {
        await store.dispatch('user/fetchUserProject', project.value.id)
        await toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      },
      async error => {
        await toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      }
  )

  edition.value = false;
}

const cancelLinksEdition = () => {
  submitted.value = false;
  projectForm.value = Object.assign({}, project.value);
  edition.value = false;
}

</script>

<style scoped>

</style>