<template>
  <Button label="Requirements"
          class="p-button-xs hidden-mobile p-button-outlined p-button-secondary"
          icon="pi pi-tags"
          @click="visibleResponseRequirementsModal = true"/>
  <Button v-tooltip.bottom="'Requirements'"
          class="p-button-xs hidden-desktop p-button-outlined p-button-icon-only p-button-secondary"
          icon="pi pi-tags"
          @click="visibleResponseRequirementsModal = true"/>

  <Dialog v-model:visible="visibleResponseRequirementsModal"
          :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :style="{ width: '500px', maxWidth: '98vw'}"
          :draggable="false"
          header="Requirements"
          modal>

    <div v-for="(requirement, rIdx) of props.giveawayRequirements" :key="`r${rIdx}`" class="flex flex-column">
      <div class="flex flex-row align-items-center justify-content-between">
        <p class="m-0 capitalize">{{ requirement.type.replace('-', '/') }}</p>
        <Button :label="requirement.link"
                class="p-button-sm p-button-link"
                @click="openExternalLink(requirement.link)"/>
      </div>
    </div>

    <Textarea v-model="responseRequirements" class="w-full mt-1" placeholder="Collab requirements" rows="5"/>

    <div class="flex flex-row justify-content-end align-items-center gap-2 w-full mt-3">
      <Button class="p-button-text p-button-plain p-button-sm" label="Cancel"
              @click="visibleResponseRequirementsModal = false"/>
      <Button class="p-button-sm" icon="pi pi-save" label="Save requirements" @click="updateOfferResponse"/>
    </div>
  </Dialog>
</template>

<script setup>
import {defineProps, ref, watch} from "vue";
import {useToast} from "primevue/usetoast";
import OfferResponseService from "@/services/offerResponse.service";
import {openExternalLink} from "@/utils/openLink";
import store from "@/store";

const toast = useToast()
let visibleResponseRequirementsModal = ref(false)
const responseRequirements = ref("")

const props = defineProps({
  projectId: {
    type: String,
    required: false,
  },
  responseId: {
    type: Number,
    required: true,
  },
  responseRequirements: {
    type: String,
    required: true,
  },
  giveawayRequirements: {
    type: Array,
    required: false,
  },
});

watch(
    () => props.responseRequirements,
    (newRequirements) => {
      responseRequirements.value = newRequirements;
    },
    {immediate: true}
)

const updateOfferResponse = () => {
  const body = {
    requirements: responseRequirements.value
  };

  OfferResponseService.updateOfferResponse(props.responseId, body)
      .then(async response => {
        await store.dispatch('offerResponse/fetchOfferResponses', props.projectId)
        toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      })
      .catch(async error => {
        toast.add({
          severity: 'error',
          summary: 'Failed',
          detail: error.response.data.message,
          life: 3000
        });
      });
}
</script>

<style scoped>

</style>