<template>
  <div class="grid p-2">
    <div class="col-12 flex flex-row justify-content-between align-items-center">
      <h2 class="m-0 mt-4 text-700 mb-2 text-lg w-full">Project templates</h2>
      <div class="flex flex-row align-items-center gap-2">
        <Button v-if="!edition" class="p-button-sm p-button-text" icon="pi pi-pencil" label="Edit"
                @click="edition = true"/>
        <template v-else>
          <Button class="p-button-sm p-button-text p-button-success" icon="pi pi-save" label="Save"
                  @click="handleSaveTemplates"/>
          <Button class="p-button-sm p-button-text p-button-plain" label="Cancel" @click="cancelTemplatesEdition"/>
        </template>
      </div>
    </div>

    <template v-if="projectForm">

      <div class="col-12">
        <h3 class="m-0 font-semibold text-sm text-600">Giveaway template</h3>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="discordTemplate">Discord template</label>
        <div class="h-10rem">
          <Editor v-if="edition" id="discordTemplate"
                  v-model="projectForm.discordTemplate"
                  :class="{'p-invalid': submitted && projectForm.discordTemplate === ''}"
                  class="h-7rem">
            <template v-slot:toolbar>
              <div class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"/>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"/>
                <button v-tooltip.bottom="'Underline'" class="ql-underline"/>
                <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"/>
                <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"/>
                <button class="ql-link" data-pc-section="link" type="button"/>
                <select v-tooltip.bottom="'Color'" class="ql-color ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
                <select v-tooltip.bottom="'Background'" class="ql-background ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
              </div>
            </template>
          </Editor>
          <Editor v-else id="discordTemplate"
                  v-model="projectForm.discordTemplate"
                  class="h-7rem"
                  readonly>
            <template v-slot:toolbar>
              <div class="ql-formats">
              </div>
            </template>
          </Editor>
        </div>
        <small v-if="edition" :class="{'text-red-500': projectForm.discordTemplate?.length > 5000}"
               class="text-right text-600">
          {{ projectForm.discordTemplate?.length || 0 }} / 5000
        </small>
        <small v-if="submitted && projectForm.discordTemplate === ''" id="discordTemplate-help" class="p-error">
          Discord template is required.
        </small>
        <small v-if="submitted && projectForm.discordTemplate?.length > 5000" id="discordTemplate-help" class="p-error">
          Discord template must be lower than 5000 characters.
        </small>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="twitterTemplate">X template</label>
        <div class="h-10rem">
          <Editor v-if="edition" id="discordTemplate"
                  v-model="projectForm.twitterTemplate"
                  :class="{'p-invalid': submitted && projectForm.twitterTemplate === ''}"
                  class="h-7rem">
            <template v-slot:toolbar>
              <div class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"/>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"/>
                <button v-tooltip.bottom="'Underline'" class="ql-underline"/>
                <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"/>
                <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"/>
                <button class="ql-link" data-pc-section="link" type="button"/>
                <select v-tooltip.bottom="'Color'" class="ql-color ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
                <select v-tooltip.bottom="'Background'" class="ql-background ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
              </div>
            </template>
          </Editor>
          <Editor v-else id="discordTemplate"
                  v-model="projectForm.twitterTemplate"
                  class="h-7rem"
                  readonly>
            <template v-slot:toolbar>
              <div class="ql-formats">
              </div>
            </template>
          </Editor>
        </div>
        <small v-if="edition" :class="{'text-red-500': projectForm.twitterTemplate?.length > 5000}"
               class="text-right text-600">
          {{ projectForm.twitterTemplate?.length || 0 }} / 5000
        </small>
        <small v-if="submitted && projectForm.twitterTemplate === ''" id="discordTemplate-help" class="p-error">
          X Template is required.
        </small>
        <small v-if="submitted && projectForm.twitterTemplate?.length > 5000" id="discordTemplate-help" class="p-error">
          X template must be lower than 5000 characters.
        </small>
      </div>

      <div class="col-12">
        <h3 class="m-0 font-semibold text-sm text-600">Requirement template</h3>
      </div>

      <div class="col-12 md:px-2 p-0 flex flex-column gap-1 mt-2">
        <label for="requirementTemplate">Requirement template</label>
        <div class="h-10rem">
          <Editor v-if="edition" id="discordTemplate"
                  v-model="projectForm.requirementTemplate"
                  :class="{'p-invalid': submitted && projectForm.requirementTemplate === ''}"
                  class="h-7rem">
            <template v-slot:toolbar>
              <div class="ql-formats">
                <button v-tooltip.bottom="'Bold'" class="ql-bold"/>
                <button v-tooltip.bottom="'Italic'" class="ql-italic"/>
                <button v-tooltip.bottom="'Underline'" class="ql-underline"/>
                <button v-tooltip.bottom="'Ordered list'" class="ql-list" value="ordered"/>
                <button v-tooltip.bottom="'Bullet list'" class="ql-list" value="bullet"/>
                <button class="ql-link" data-pc-section="link" type="button"/>
                <select v-tooltip.bottom="'Color'" class="ql-color ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
                <select v-tooltip.bottom="'Background'" class="ql-background ql-picker ql-color-picker">
                  <option value="#ffffff"/>
                  <option value="#000000"/>
                  <option value="#e60000"/>
                  <option value="#ff9900"/>
                  <option value="#ffff00"/>
                  <option value="#008a00"/>
                  <option value="#0066cc"/>
                  <option value="#9933ff"/>
                  <option value="#facccc"/>
                </select>
              </div>
            </template>
          </Editor>
          <Editor v-else id="requirementTemplate"
                  v-model="projectForm.requirementTemplate"
                  class="h-7rem"
                  readonly>
            <template v-slot:toolbar>
              <div class="ql-formats">
              </div>
            </template>
          </Editor>
        </div>
        <small v-if="edition" :class="{'text-red-500': projectForm.requirementTemplate?.length > 5000}"
               class="text-right text-600">
          {{ projectForm.requirementTemplate?.length || 0 }} / 5000
        </small>
        <small v-if="submitted && projectForm.requirementTemplate === ''" id="discordTemplate-help" class="p-error">
          Requirement template is required.
        </small>
        <small v-if="submitted && projectForm.requirementTemplate?.length > 5000" id="discordTemplate-help"
               class="p-error">
          Requirement template must be lower than 5000 characters.
        </small>
      </div>

    </template>
  </div>
</template>

<script setup>
import {computed, onBeforeUnmount, ref, watch} from "vue";
import store from "@/store";
import ProjectService from "@/services/project.service";
import {useToast} from "primevue/usetoast";

const toast = useToast();
const project = computed(() => store.state.user.userProject)
const projectForm = ref(null)
const submitted = ref(false)
const edition = ref(false)

watch(
    () => project,
    (newProject) => {
      if (newProject.value && Object.keys(newProject.value).length > 0) {
        projectForm.value = Object.assign({}, newProject.value);
      }
    },
    {immediate: true, deep: true}
)

onBeforeUnmount(() => cancelTemplatesEdition());

const handleSaveTemplates = async () => {
  submitted.value = true;

  await ProjectService.updateProject(projectForm.value, project.value.id).then(
      async response => {
        await store.dispatch('user/fetchUserProject', project.value.id)
        await toast.add({severity: 'success', summary: 'Success', detail: response.message, life: 3000});
      },
      async error => {
        await toast.add({severity: 'error', summary: 'Failed', detail: error.response.data.message, life: 3000});
      }
  )

  edition.value = false;
}

const cancelTemplatesEdition = () => {
  submitted.value = false;
  projectForm.value = Object.assign({}, project.value);
  edition.value = false;
}

</script>

<style scoped>

</style>