<template>
  <Button class="p-button-sm p-button-text" icon="pi pi-plus" label="Add"
          @click="visibleAddMembersModal = true"/>

  <Dialog v-model:visible="visibleAddMembersModal" :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :draggable="false"
          :style="{ width: '500px', maxWidth: '98vw'}"
          header="Add members" modal>
    <div class="flex flex-column gap-2 pt-2" style="min-height: 30vh;">

      <div class="p-inputgroup flex align-items-center">
        <InputText v-model="query"
                   class="border-300 p-inputtext-sm flex flex-1"
                   placeholder="Find user"/>
      </div>

      <div v-if="searchedUsers.length <= members.length"
           class="flex flex-column gap-2 py-6 align-items-center justify-content-center h-full">
        <i class="text-300 pi pi-users text-3xl"/>
        <small class="text-300">No user found</small>
      </div>

      <div v-else class="flex flex-column h-full">
        <div v-for="user of searchedUsers" :key="user.id">
          <div v-if="isAlreadyMember(user.id)" class="flex flex-row justify-content-between align-items-center w-full">
            <div class="flex flex-row align-items-center gap-2 py-2">
              <Avatar :image="`https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}`" shape="circle"/>
              <p class="m-0 text-600 capitalize">{{ user.username }}</p>
            </div>
            <Button class="p-button-sm p-button-success p-button-text py-1 px-2"
                    icon="pi pi-plus" label="Add"
                    @click="addMember(user)"/>
          </div>
        </div>
      </div>

    </div>
  </Dialog>
</template>

<script setup>
import {computed, ref, watch} from "vue";
import store from "@/store";
import {useToast} from "primevue/usetoast";

const toast = useToast();
const visibleAddMembersModal = ref(false);
const searchedUsers = computed(() => store.state.project.usersFound);
const members = computed(() => store.state.project.projectMembers);
const project = computed(() => store.state.user.userProject);

const query = ref("");
watch(
    () => query.value,
    (newQuery) => {
      store.dispatch('project/fetchSearchUsers', newQuery);
    },
    {immediate: true}
)

const isAlreadyMember = (userId) => !members.value.some(member => member['userId'] === userId);

const addMember = (member) => {
  store.dispatch("project/addProjectMember", {projectId: project.value.id, member: member}).then(
      async response => {
        toast.add({severity: 'success', summary: 'Member added', detail: response.message, life: 3000})
        await store.dispatch('project/fetchProjectMembers', project.value.id);
        await store.dispatch('project/fetchSearchUsers', null);
      },
      error => toast.add({
        severity: 'error',
        summary: 'Failed add member',
        detail: error.response.data.message,
        life: 3000
      })
  )
}

</script>

<style scoped>

</style>