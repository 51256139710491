<template>

  <div class="flex flex-column gap-2 flex-wrap">

    <div class="flex flex-row justify-content-between align-items-center">
      <h1 class="title">Projects</h1>
      <router-link to="/new-project">
        <Button class="p-button-sm surface-50 hover:surface-200 p-button-text" icon="pi pi-plus" label="New"/>
      </router-link>
    </div>


    <div class="col-12 flex flex-row align-items-center justify-content-between gap-2">
      <div class="flex flex-row align-items-center gap-2 w-full">

            <span class="p-input-icon-left w-full lg:w-20rem">
              <i class="pi pi-search"/>
              <InputText v-model="searchData.query" class="border-300 p-inputtext-sm" placeholder="Search project"/>
            </span>

        <MultiSelect v-model="searchData.blockchains"
                     :options="blockchainList"
                     class="w-12rem p-inputtext-sm hidden-mobile"
                     option-label="name"
                     option-value="name"
                     placeholder="Blockchain">
          <template #option="slotProps">
            <div class="flex gap-1 align-items-center">
              <img :alt="slotProps.option.name" :src="slotProps.option.icon" class="h-1rem"/>
              <p class="m-0 capitalize">{{ slotProps.option.name }}</p>
            </div>
          </template>
          <template #footer>
            <div class="py-2 px-3">
              <b>{{ searchData.blockchains ? searchData.blockchains.length : 0 }}</b>
              item{{ (searchData.blockchains ? searchData.blockchains.length : 0) > 1 ? 's' : '' }} selected.
            </div>
          </template>
        </MultiSelect>

        <ToggleButton v-model="searchData.verified"
                      class="p-button-sm  hidden-mobile white-space-nowrap"
                      offLabel="Not verified" onLabel="Verified"/>

        <Button v-tooltip.bottom="'More filters'"
                class="p-button-icon-only p-button-sm p-button-outlined p-button-plain"
                icon="pi pi-filter" @click="visibleFilterSidebar = true"/>

      </div>

      <div class="flex flex-row gap-2 align-items-center hidden-tablet">

        <span class="p-buttonset flex flex-row flex-nowrap">
              <Button :class="{'surface-200': selectedVisualization === 'grid'}"
                      class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm"
                      icon="pi pi-table"
                      @click="switchVisualization('grid')"/>
              <Button :class="{'surface-200': selectedVisualization === 'row'}"
                      class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm"
                      icon="pi pi-list"
                      @click="switchVisualization('row')"/>
            </span>
      </div>


    </div>

    <Sidebar v-model:visible="visibleFilterSidebar" class="p-0" position="right">
      <div class="flex flex-row align-items-center justify-content-between pt-2">
        <h2 class="m-0">Filters</h2>
        <Button class="p-button-sm p-button-text" label="Clear" @click="clearSearchData"/>
      </div>

      <h4 class="text-600 m-0 mt-3 mb-2">Visualization</h4>

      <span class="p-buttonset flex flex-row flex-nowrap">
          <Button :class="{'surface-200': selectedVisualization === 'grid'}"
                  class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm w-full"
                  icon="pi pi-table"
                  label="Card"
                  @click="switchVisualization('grid')"/>
          <Button :class="{'surface-200': selectedVisualization === 'row'}"
                  class="p-button-outlined p-button-plain p-button-sm p-inputtext-sm w-full"
                  icon="pi pi-list"
                  label="List"
                  @click="switchVisualization('row')"/>
        </span>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Order by</h4>

      <Dropdown v-model="searchData.order"
                :options="projectsOrderByList"
                class="w-full p-inputtext-sm"
                option-label="label"
                placeholder="Order by"/>

      <Divider/>

      <div class="flex flex-row align-items-center gap-2 my-3">
        <Checkbox v-model="searchData.verified" binary inputId="verifiedInput"/>
        <h4 class="text-600 m-0">Verified</h4>
        <VerifiedIcon verified/>
      </div>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Project type</h4>

      <div class="flex flex-column gap-1">
        <div v-for="projectType of projectTypeList"
             :key="projectType.value" class="flex flex-row gap-2 p-1 cursor-pointer align-items-center">
          <Checkbox v-model="searchData.types" :inputId="projectType.label" :value="projectType.value"/>
          <p class="m-0 text-500 capitalize">{{ projectType.label }}</p>
        </div>
      </div>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Blockchain</h4>

      <div class="flex flex-column gap-1">
        <div v-for="blockchain of blockchainList"
             :key="blockchain.name" class="flex flex-row gap-2 p-1 cursor-pointer align-items-center">
          <Checkbox v-model="searchData.blockchains" :inputId="blockchain.name" :value="blockchain.name"/>
          <img :alt="`${blockchain.name} icon`" :src="blockchain.icon" class="h-1rem"/>
          <p class="m-0 text-500 capitalize">{{ blockchain.name }}</p>
        </div>
      </div>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Min. avg entries</h4>

      <InputNumber v-model="searchData.avgEntries" :min="0" class="w-full" placeholder="125"/>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Minted</h4>

      <div class="flex flex-column gap-1">
        <div v-for="status of mintedStateList"
             :key="status.name" class="flex flex-row gap-2 p-1 cursor-pointer align-items-center"
             @click="searchData.minted = status.value">
          <RadioButton v-model="searchData.minted" :inputId="status.name" :value="status.value"/>
          <p class="m-0 text-500 capitalize">{{ status.name }}</p>
        </div>
      </div>

      <Divider/>

      <h4 class="text-600 m-0 mt-3 mb-2">Host AMA</h4>

      <div class="flex flex-column gap-1">
        <div v-for="status of hostAmaStateList"
             :key="status.name" class="flex flex-row gap-2 p-1 cursor-pointer align-items-center">
          <RadioButton v-model="searchData.ama" :inputId="status.name" :value="status.value"/>
          <p class="m-0 text-500 capitalize">{{ status.name }}</p>
        </div>
      </div>

    </Sidebar>

    <ProjectsVisualization :error="error"
                           :loading="loading"
                           :projects="projects"
                           :selected-visualization="selectedVisualization"
    />

  </div>

</template>

<script setup>
import {computed, ref, watch} from "vue";
import {
  blockchainList,
  hostAmaStateList,
  mintedStateList,
  projectsOrderByList,
  projectTypeList
} from "@/utils/dropdownItems";
import store from "@/store";
import ProjectsVisualization from "@/components/ListVisualization/ProjectsVisualization.vue";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";

const visibleFilterSidebar = ref(false);
const searchData = ref({
  query: "",
  blockchains: [],
  types: [],
  minted: "null",
  ama: "null",
  avgEntries: null,
  verified: false,
  order: {label: "Most Recent", by: "createdAt", sort: "desc"},
})
const selectedVisualization = ref("grid");

const projects = computed(() => store.state.project.projects);
const loading = computed(() => store.state.project.loading);
const error = computed(() => store.state.project.error)

const switchVisualization = (type) => selectedVisualization.value = type;

const clearSearchData = () => {
  searchData.value = {
    query: "",
    blockchains: [],
    minted: "null",
    ama: "null",
    avgEntries: null,
    order: {label: "most recent", by: "createdAt", sort: "asc"},
  };
}

watch(
    () => searchData,
    async (newSearchData) => {
      await store.dispatch('project/fetchProjects', {...newSearchData.value, page: 0, size: 20});
    },
    {deep: true, immediate: true}
)
</script>

<style scoped>
</style>