<template>
  <Button label="Requirements"
          class="p-button-xs hidden-mobile p-button-outlined p-button-secondary"
          icon="pi pi-tags"
          @click="visibleResponseRequirementsModal = true"/>
  <Button v-tooltip.bottom="'Requirements'"
          class="p-button-xs hidden-desktop p-button-outlined p-button-icon-only p-button-secondary"
          icon="pi pi-tags"
          @click="visibleResponseRequirementsModal = true"/>

  <Dialog v-model:visible="visibleResponseRequirementsModal"
          :breakpoints="{ '960px': '75vw', '641px': '98vw' }"
          :style="{ width: '500px', maxWidth: '98vw'}"
          :draggable="false"
          header="Requirements"
          modal>

    <div class="flex flex-column" v-for="(requirement, rIdx) of giveawayRequirements" :key="`r${rIdx}`">
      <div class="flex flex-row align-items-center justify-content-between">
        <p class="m-0 capitalize">{{requirement.type.replace('-', '/')}}</p>
        <Button class="p-button-sm p-button-link"
                @click="openExternalLink(requirement.link)"
                :label="requirement.link"/>
      </div>
    </div>

    <p>{{ responseRequirements }}</p>

    <p v-if="responseRequirements === null && giveawayRequirements.length === 0" class="text-center text-500">No requirements yet</p>
  </Dialog>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {openExternalLink} from "@/utils/openLink";

let visibleResponseRequirementsModal = ref(false)

defineProps({
  responseRequirements: {
    type: String,
    required: true,
  },
  giveawayRequirements: {
    type: Array,
    required: false,
  },
});
</script>

<style scoped>

</style>