<template>

  <div class="col-12">
    <div
        class="flex flex-row surface-100 border-round cursor-pointer align-items-center hover:surface-200 relative c-scale hover:shadow-3"
        style="min-height: 6rem;"
        @click.stop="$router.push(`/${offer.type}/${offer.project.name.replaceAll(' ', '-').toLowerCase()}/${offer.id}`)">
      <div v-if="offer.type === 'offering'"
           class="flex flex-row absolute bg-cyan-400 border-round-left h-full align-items-center justify-content-center"
           style="width: 1.4rem;">
        <p class="m-0 -rotate-90 font-light text-cyan-50" style="font-size: 0.7rem;">Offering</p>
      </div>
      <div v-else
           class="flex flex-row absolute bg-purple-400 border-round-left h-full align-items-center justify-content-center"
           style="width: 1.4rem;">
        <p class="m-0 -rotate-90 font-light text-purple-50" style="font-size: 0.7rem;">Requesting</p>
      </div>
      <div class="flex flex-row w-full flex-wrap align-items-center justify-content-between pl-5">
        <div class="flex flex-row gap-2 align-items-center">
          <img :src="offer.project.logo" alt="Project logo"
               class="border-round"
               height="70"/>
          <div class="flex flex-column gap-1">
            <div class="flex flex-row align-items-center gap-2">
              <h2 class="m-0 text-2xl text-700">{{ offer.project.name }}</h2>
              <VerifiedIcon :verified="offer.project.verified"/>
              <BlockchainTag v-if="offer.project.blockchain" :blockchain="offer.project.blockchain"/>
              <CardTag :value="getProjectTypeLabel(offer.project.type)"/>
            </div>
            <div v-if="offer?.user"
                 class="flex flex-row align-items-center justify-content-start gap-2 w-full pb-2 text-600"
                 style="font-size: 0.8rem;">
              <span>Posted by</span>
              <div class="flex flex-row align-items-center gap-2">
                <Avatar :image="`https://cdn.discordapp.com/avatars/${offer.user.discordId}/${offer.user.avatar}`"
                        shape="circle"/>
                <span class="capitalize">{{ offer.user.username }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {defineProps} from "vue";
import BlockchainTag from "@/components/tags/BlockchainTag.vue";
import VerifiedIcon from "@/components/icons/VerifiedIcon.vue";
import CardTag from "@/components/tags/CardTag.vue";
import {projectTypeList} from "@/utils/dropdownItems";

defineProps({
  offer: {
    type: Object,
    required: true,
  }
});

const getProjectTypeLabel = (type) => projectTypeList.value.filter(t => t.value === type)[0].label
</script>

<style scoped>

</style>